import { Grid, useMediaQuery, useTheme } from '@mui/material'
import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import ConverterActiveViewerWrapper from 'components/molecules/ConverterActiveViewerWrapper'
import ConverterHaulerPanelWrapper from 'components/molecules/ConverterHaulerPanelWrapper'
import ConverterParameterEditorWrapper from 'components/molecules/ConverterParameterEditorWrapper'
import ConverterSelectionPanelWrapper from 'components/molecules/ConverterSelectionPanelWrapper'
import { useAtom } from 'jotai'
import { useResetAtom } from 'jotai/utils'
import { debounce } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { alertAtom } from 'stores'
import {
  accessTokenAtom,
  getDecodedAccessToken,
  isDrawerOpen,
} from 'stores/auth'
import {
  addHaulerSettings,
  getHaulerSettingByvendor,
  updateHaulerSettings,
} from 'stores/hauler'
import {
  activeViewActionAtom,
  getActiveView,
  getConversionParametersByVendor,
  getDefaultConverterParameters,
  parametersAtom,
  saveConversionParameters,
  uploadInvoice,
} from 'stores/invoice'
import {
  getVendorInvoiceFileList,
  getVendorList,
  upsertVendor,
} from 'stores/vendor'
import { HaulerSetting } from 'types/Hauler'
import { ActiveViewType, InvoiceParametersType } from 'types/InvoiceParamters'
import { VendorType } from 'types/Vendor'
import { postInvoiceParameters, updateEditorParams } from 'utils'

const ConverterConfig = (): JSX.Element => {
  // Shared ---------------------------------------------
  const [accessToken] = useAtom(accessTokenAtom)
  const permissions = getDecodedAccessToken()?.permissions
  const [, setAlert] = useAtom(alertAtom)
  const theme = useTheme()
  const elevation = 0
  const panelSpacing = 1
  const [drawerOpen] = useAtom(isDrawerOpen)
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const showErrorAlert = (
    message: string,
    type: 'error' | 'success' | 'info' | 'warning' = 'error'
  ): void => {
    setAlert({
      show: true,
      type: type,
      message: message,
      autoHideDuration: 2000,
    })
  }

  // Selection Panel ---------------------------------------------
  const [vendorsList, setVendorsList] = useState<VendorType[]>([])
  const [selectionPanelLoading, setSelectionPanelLoading] =
    useState<boolean>(false)
  const [haulerSettingsLoading, setHaulerSettingsLoading] =
    useState<boolean>(false)
  const [selectedVendor, setVendor] = useState<VendorType | null>(null)
  const [existingInvoiceFileList, setExistingInvoiceFileList] = useState<
    { label: string; value: string }[]
  >([])
  const [uploadedInvoiceFile, setUploadedInvoiceFile] = useState<
    File | undefined
  >(undefined)
  const [selectedExistingInvoiceFile, setSelectedExistingInvoiceFile] =
    useState<{ label: string; value: string } | null>(null)
  const selectedVendorRef = useRef(selectedVendor)
  const selectedExistingInvoiceFileRef = useRef(selectedExistingInvoiceFile)
  const [converterStatusSaving, setConverterStatusSaving] =
    useState<boolean>(false)

  const [haulerSettingsSaving, setHaulerSettingsSaving] =
    useState<boolean>(false)

  const [haulerSetting, setHaulerSetting] = useState<HaulerSetting>({
    vendorname: '',
    haulerId: null,
    haulerIdUsa2: null,
    haulerIdElytus: null,
    fnOEnabled: false,
    sharePointFolder: '',
    invoiceIdentifierKeywordsCsv: '',
    aiModelId: '',
    incomingEmail: '',
    duplicateCheckOverride: false,
  })

  // Load vendors list to start
  useEffect(() => {
    if (accessToken) {
      setSelectionPanelLoading(true)
      getVendorList()
        .then(response => {
          setVendorsList(response.data)
          setSelectionPanelLoading(false)
        })
        .catch(() => {
          showErrorAlert('Failed to get vendors')
          setSelectionPanelLoading(false)
        })
    }
  }, [accessToken])

  // Update ref for debounce to use else debounce wont work
  useEffect(() => {
    selectedVendorRef.current = selectedVendor
    selectedExistingInvoiceFileRef.current = selectedExistingInvoiceFile
  }, [selectedVendor, selectedExistingInvoiceFile])

  const handleVendorChange = (selectedValue: VendorType | null): void => {
    setIsDirty(false)
    setUploadedInvoiceFile(undefined)
    setSelectedExistingInvoiceFile(null)
    setExistingInvoiceFileList([])
    resetParameters()
    setVendor(selectedValue)
    updateVendorInvoiceFileList(selectedValue)
    getHaulerSettings(selectedValue)
  }

  const getHaulerSettings = (selectedVendor: VendorType | null): void => {
    if (selectedVendor) {
      setHaulerSettingsLoading(true)
      setHaulerSetting({
        vendorname: selectedVendor.name,
        haulerId: null,
        haulerIdUsa2: null,
        haulerIdElytus: null,
        fnOEnabled: false,
        sharePointFolder: '',
        invoiceIdentifierKeywordsCsv: '',
        aiModelId: '',
        incomingEmail: '',
        duplicateCheckOverride: false,
      })

      getHaulerSettingByvendor(selectedVendor.name)
        .then(response => {
          if (response.data) {
            setHaulerSetting(response.data)
          }
          setHaulerSettingsLoading(false)
        })
        .catch(() => {
          showErrorAlert('Failed to get haulerSettings')
          setHaulerSettingsLoading(false)
        })
    } else {
      setHaulerSetting({
        vendorname: '',
        haulerId: null,
        haulerIdUsa2: null,
        haulerIdElytus: null,
        fnOEnabled: false,
        sharePointFolder: '',
        invoiceIdentifierKeywordsCsv: '',
        aiModelId: '',
        incomingEmail: '',
        duplicateCheckOverride: false,
      })
    }
  }

  const updateVendorInvoiceFileList = (
    vendor: VendorType | null = selectedVendor
  ): void => {
    if (vendor) {
      setSelectionPanelLoading(true)
      getVendorInvoiceFileList(vendor.name)
        .then(response => {
          if (response.data && response.data.length) {
            const data = response.data.map(fileName => ({
              label: fileName.replace(/\.[^/.]+$/, ''),
              value: fileName,
            }))
            setExistingInvoiceFileList(data)
          }
          setSelectionPanelLoading(false)
        })
        .catch(() => {
          showErrorAlert('Failed to load vendor exisiting invoice list')
          setSelectionPanelLoading(false)
        })
    }
  }

  const handleExistingInvoiceFileChange = (
    vendor: VendorType | null,
    file: { label: string; value: string } | null
  ): void => {
    if (vendor && file) {
      // Reset params and active view on file change
      setActiveViewOutPut(null)
      resetParameters()
      setIsDirty(false)

      setSelectedExistingInvoiceFile(file)
      setEditorPanelLoading(true)

      // Get editor conversion parameters for the selected file
      getConversionParametersByVendor(vendor.name)
        .then(response => {
          if (response.data) {
            updateParameters(updateEditorParams(response.data))
            setEditorPanelLoading(false)
            // Get active view
            getActivePanelView(vendor, file)
          } else {
            // get default parameters if conversion parameters is null
            getDefaultConverterParameters(vendor.name)
              .then(response => {
                const data = {
                  ...response.data,
                  vendorName: vendor.name,
                }
                setEditorPanelLoading(false)
                updateParameters(updateEditorParams(data))
                // Save default parameters as initial data
                saveParameters(data, true)
              })
              .catch(() => {
                setEditorPanelLoading(false)
                showErrorAlert('Failed to get default parameters. Try again.')
              })
          }
        })
        .catch(() => {
          setEditorPanelLoading(false)
          showErrorAlert('Failed to get conversion parameters. Try again.')
        })
    } else {
      setSelectedExistingInvoiceFile(null)
      setActiveViewOutPut(null)
      resetParameters()
    }
  }

  const fileUploadSucessfully = (file: File | undefined): void => {
    if (selectedVendor && file) {
      setSelectionPanelLoading(true)
      let attempts = 0
      const interval = 40000 // 2 minutes total for 3 attempts
      const maxAttempts = 6

      const pollFileListApi = async (): Promise<void> => {
        const response = await getVendorInvoiceFileList(selectedVendor.name)
        attempts++

        // Find file exists in the response
        const foundFile = response.data.find(
          fileName =>
            fileName.replace(/\.[^/.]+$/, '') ===
            file.name.toLowerCase().replace(/\.[^/.]+$/, '')
        )
        if (foundFile) {
          // If file exists then update file list
          const data = response.data.map(fileName => ({
            label: fileName.replace(/\.[^/.]+$/, ''),
            value: fileName,
          }))

          setExistingInvoiceFileList(data)
          handleExistingInvoiceFileChange(selectedVendor, {
            label: foundFile.replace(/\.[^/.]+$/, ''),
            value: foundFile,
          })
          setSelectionPanelLoading(false)
        } else if (maxAttempts && attempts === maxAttempts) {
          handleSelectionCancel()
          showErrorAlert(
            'Failed to parse uploaded invoice. Try after sometime...'
          )
          setSelectionPanelLoading(false)
        } else {
          setTimeout(pollFileListApi, interval)
        }
      }

      new Promise(pollFileListApi)
    }
  }

  const handleSubmitInvoice = (): void => {
    if (uploadedInvoiceFile && selectedVendor) {
      setSelectionPanelLoading(true)
      const formData = new FormData()
      formData.append('File', uploadedInvoiceFile)

      uploadInvoice(selectedVendor.name, uploadedInvoiceFile.name, formData)
        .then(() => {
          showErrorAlert('Invoice uploaded successfully', 'success')

          setSelectionPanelLoading(false)
          fileUploadSucessfully(uploadedInvoiceFile)
        })
        .catch(() => {
          setSelectionPanelLoading(false)
          showErrorAlert('Failed to Upload Invoice. Try again.')
        })
    }
  }

  const handleHaulerSettingsSubmit = (formData: HaulerSetting): void => {
    setHaulerSettingsSaving(true)
    if (
      haulerSetting &&
      ((haulerSetting.haulerId && haulerSetting.haulerId.length) ||
        (haulerSetting.haulerIdUsa2 && haulerSetting.haulerIdUsa2.length))
    ) {
      updateHaulerSettings(formData)
        .then(response => {
          // Update local vendor state on success
          setHaulerSetting({ ...formData, timestamp: `${response.data?._ts}` })
          setAlert({
            show: true,
            type: 'success',
            message: 'Updated hauler settings',
            autoHideDuration: 2000,
          })
          setHaulerSettingsSaving(false)
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to update hauler settings',
            autoHideDuration: 2000,
          })
          setHaulerSettingsSaving(false)
        })
    } else {
      addHaulerSettings(formData)
        .then(() => {
          // Update local vendor state on success
          setHaulerSetting(formData)
          setAlert({
            show: true,
            type: 'success',
            message: 'Added hauler settings',
            autoHideDuration: 2000,
          })
          setHaulerSettingsSaving(false)
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to add hauler settings',
            autoHideDuration: 2000,
          })
          setHaulerSettingsSaving(false)
        })
    }
  }

  const handleSelectionCancel = (): void => {
    setVendor(null)
    setIsDirty(false)
    setExistingInvoiceFileList([])
    setUploadedInvoiceFile(undefined)
    setSelectedExistingInvoiceFile(null)
    setActiveViewOutPut(null)
    resetParameters()
    resetActiveViewParameters()
  }

  // Editor panel ---------------------------------------------
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
  const [editorPanelLoading, setEditorPanelLoading] = useState<boolean>(false)
  const [params, updateParameters] = useAtom(parametersAtom)
  const resetParameters = useResetAtom(parametersAtom)
  const [isDirty, setIsDirty] = useState(false)
  const saveParamsDebounceWait = 600 // debounce milliseconds to delay

  useEffect(() => {
    return () => {
      // On component unload reset paramters store
      resetParameters()
    }
  }, [])

  const debounceParametersOnSave = useMemo(
    () =>
      debounce(params => {
        saveParameters(params)
      }, saveParamsDebounceWait),
    []
  )

  useEffect(() => {
    if (params && params.id && isDirty) {
      debounceParametersOnSave(params)
    }
  }, [params])

  const handleFullscreenParamsView = (val: boolean): void => {
    setIsFullscreen(val)
  }

  const saveParameters = (
    parameters: InvoiceParametersType,
    isDefaultParams = false
  ): void => {
    saveConversionParameters(postInvoiceParameters(parameters), isDefaultParams)
      .then(response => {
        if (isDefaultParams) {
          // Update id after response
          updateParameters(
            updateEditorParams({ ...parameters, id: response.data })
          )
        } else {
          // Gets the entire object back
          // Need to update store ? the dynamic ids may update and it may trigger the store change again
        }

        // Re-render active view once parameters are updated
        getActivePanelView()
      })
      .catch(() => {
        setEditorPanelLoading(false)
        showErrorAlert('Failed to create conversion parameters.')
      })
  }

  // ActiveView Panel ---------------------------------------------
  const [activeViewPanelLoading, setActiveViewPanelLoading] =
    useState<boolean>(false)
  const [activeViewOutPut, setActiveViewOutPut] =
    useState<ActiveViewType | null>(null)
  const [activeViewActionStatus, updateActiveViewActionStatus] =
    useAtom(activeViewActionAtom)
  const resetActiveViewParameters = useResetAtom(activeViewActionAtom)

  const getActivePanelView = (
    vendor = selectedVendorRef.current,
    file = selectedExistingInvoiceFileRef.current
  ): void => {
    if (vendor && file) {
      setActiveViewPanelLoading(true)
      getActiveView(vendor.name, file.value)
        .then(response => {
          setActiveViewPanelLoading(false)
          setActiveViewOutPut(response.data)
        })
        .catch(() => {
          setActiveViewPanelLoading(false)
          showErrorAlert('Failed to get Active view. Try again.')
        })
        .finally(() => setIsDirty(true)) // Active view loads last so set isDirty here
    } else {
      showErrorAlert('Missing vendor/file to get active view.', 'warning')
    }
  }

  const handleActiveViewAccordionChange = ({
    key,
    status,
  }: {
    key: keyof ActiveViewType
    status: boolean
  }): void => {
    const updatedStatus = { ...activeViewActionStatus }
    updatedStatus[key] = status
    updateActiveViewActionStatus(updatedStatus)
  }

  const handleConverterStatusChange = (status: 0 | 1 | 2): void => {
    if (selectedVendor) {
      setConverterStatusSaving(true)
      upsertVendor({ ...selectedVendor, converterStatus: status })
        .then(() => {
          // Update local vendor state on success
          setVendor({ ...selectedVendor, converterStatus: status })
          setAlert({
            show: true,
            type: 'success',
            message: 'Updated converter status',
            autoHideDuration: 2000,
          })
          setConverterStatusSaving(false)
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to update converter status',
            autoHideDuration: 2000,
          })
          setConverterStatusSaving(false)
        })
    }
  }

  return (
    <AdminPageWrapper pageTitle="Invoice Converter Config">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={panelSpacing}
      >
        <Grid item xs={12}>
          <ConverterSelectionPanelWrapper
            elevation={elevation}
            vendors={vendorsList}
            selectedVendor={selectedVendor}
            existingInvoiceFileList={existingInvoiceFileList}
            selectedExistingInvoiceFile={selectedExistingInvoiceFile}
            uploadedInvoiceFile={uploadedInvoiceFile}
            loading={selectionPanelLoading || haulerSettingsLoading}
            onVendorChange={handleVendorChange}
            onExistingInvoiceFileChange={handleExistingInvoiceFileChange}
            onInvoiceFileUpload={setUploadedInvoiceFile}
            onSubmit={handleSubmitInvoice}
            onCancel={handleSelectionCancel}
          />
        </Grid>
        <Grid item xs={12}>
          {!haulerSettingsLoading && selectedVendor ? (
            <ConverterHaulerPanelWrapper
              canAddOrUpdate={permissions?.includes('update:hauler')}
              elevation={elevation}
              haulerSetting={haulerSetting}
              saving={haulerSettingsSaving}
              onSubmit={handleHaulerSettingsSubmit}
            />
          ) : null}
        </Grid>
        {selectedExistingInvoiceFile && selectedVendor ? (
          <>
            <Grid
              item
              xs={12}
              md={isFullscreen || (drawerOpen && isDownMd) ? 12 : 5}
            >
              <ConverterParameterEditorWrapper
                elevation={elevation}
                showFullscreen={isUpMd ? true : !drawerOpen}
                loading={editorPanelLoading}
                onFullscreenClick={handleFullscreenParamsView}
                isValid={params && params.vendorName ? true : false}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={isFullscreen || (drawerOpen && isDownMd) ? 12 : 7}
            >
              <ConverterActiveViewerWrapper
                activeViewOutPut={activeViewOutPut}
                elevation={elevation}
                spacing={panelSpacing}
                loading={activeViewPanelLoading}
                accordionStatuses={activeViewActionStatus}
                selectedVendor={selectedVendor}
                converterStatusSaving={converterStatusSaving}
                onAccordionChange={handleActiveViewAccordionChange}
                onConverterStatusChange={handleConverterStatusChange}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </AdminPageWrapper>
  )
}

export default ConverterConfig
