import { datadogRum } from '@datadog/browser-rum'
import '@datadog/browser-rum/bundle/datadog-rum'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import App from 'App'
import { Auth0ProviderWithNavigate } from 'auth0ProviderWithNavigate'
import { Provider } from 'jotai'
// import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from 'reportWebVitals'
import theme from 'theme'

import './styles/index.scss'

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: process.env.REACT_APP_DATADOG_SITE,
  service: 'invoice-automation',
  env: process.env.REACT_APP_ENV,
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
})

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  //<StrictMode>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <Provider>
          <App />
        </Provider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </ThemeProvider>
  //</StrictMode>
)

reportWebVitals()
