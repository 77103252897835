import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  CircularProgress,
  Collapse,
  Grid,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import MiniSwitch from 'components/atoms/MiniSwitch'
import { hiddenCols } from 'data/invoice'
import { isArray, isPlainObject, keys, startCase } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { ActiveViewActionType, ActiveViewType } from 'types/InvoiceParamters'
import { VendorType } from 'types/Vendor'
import { currencyFormatter } from 'utils'

const getColumnHeader = (column: string): string => {
  if (column === 'headerIndexesList' || column === 'footerIndexesList') {
    return 'Index'
  }

  if (column === 'errorList') {
    return 'Errors'
  }

  return startCase(column)
}

const isColumnHidden = (parent: string, column: string): boolean => {
  return hiddenCols[parent]?.includes(column) || false
}

const DynamicTable = ({
  data,
  refKey,
}: {
  data: ActiveViewType
  refKey: keyof typeof data
}): JSX.Element => {
  const dynamicTableData: typeof data[keyof typeof data] = data[refKey]
  const headers: typeof dynamicTableData = isArray(dynamicTableData)
    ? typeof dynamicTableData[0] === 'object'
      ? keys(dynamicTableData[0])
      : []
    : keys(dynamicTableData)

  return (
    <TableContainer sx={{ maxHeight: 365 }}>
      <Table stickyHeader size="small" aria-label="dynamicTable">
        <TableHead>
          <TableRow
            sx={{
              '& th': {
                backgroundColor: '#798996',
              },
              '&>th': { color: 'white' },
            }}
          >
            {headers && headers.length ? (
              headers.map((header, index) => {
                return !isColumnHidden(refKey, header) ? (
                  <TableCell key={`${refKey}_header_${index}`}>
                    {startCase(header)}
                  </TableCell>
                ) : (
                  ''
                )
              })
            ) : (
              <TableCell>{getColumnHeader(refKey)}</TableCell>
            )}
          </TableRow>
        </TableHead>
        {isArray(dynamicTableData) ? (
          <TableBody>
            {dynamicTableData.length ? (
              dynamicTableData.map((dataObj: any, i) => (
                <TableRow
                  key={`${refKey}_dt_arr_row_${i}`}
                  sx={{
                    '&:nth-of-type(odd)': {
                      backgroundColor: '#F5F7FA',
                    },
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  {headers && headers.length ? (
                    headers.map((header, j) => {
                      return !isArray(dataObj[header]) &&
                        !isPlainObject(dataObj[header]) ? (
                        !isColumnHidden(refKey, header) ? (
                          <TableCell
                            key={`${refKey}_dt_arr_row_cell_${i}_${j}`}
                          >
                            {dataObj[header]}
                          </TableCell>
                        ) : null
                      ) : null
                    })
                  ) : (
                    <TableCell>{dataObj}</TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow key={`${refKey}_empty_row}`}>
                <TableCell key={`${refKey}_empty_cell`}>
                  No data to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        ) : isPlainObject(dynamicTableData) ? (
          <TableBody>
            <TableRow>
              {Object.values(dynamicTableData).map((val, j) =>
                !isColumnHidden(refKey, Object.keys(dynamicTableData)[j]) ? (
                  <TableCell key={`${refKey}_dt_obj_row_cell_${j}`}>
                    {val}
                  </TableCell>
                ) : null
              )}
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            <TableCell>N/A</TableCell>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

const ActiveViewItem = ({
  data,
  refKey,
  accordionStatuses,
  setOpen,
}: {
  data: ActiveViewType
  refKey: keyof typeof data
  accordionStatuses: ActiveViewActionType
  setOpen: ({
    key,
    status,
  }: {
    key: keyof typeof data
    status: boolean
  }) => void
}): JSX.Element => {
  const isOpen = accordionStatuses[refKey]

  const getInvoiceTotal = (): number => {
    return data['invoiceAccountSummaryHeader'].invoiceTotalD || 0
  }

  const getInvoiceTotalFromLineItems = (): number => {
    if (refKey === 'lineItems') {
      let total = 0
      data[refKey].map(lineItem => {
        total += lineItem.linePriceD
      })

      return total
    }
    return 0
  }

  return (
    <Fragment>
      <TableRow
        sx={{
          cursor: 'pointer',
          '& > *': { borderBottom: 'unset' },
          backgroundColor: isOpen
            ? 'rgb(33 64 154 / 80%)'
            : 'rgb(33 64 154 / 100%)',
          '&:hover': { backgroundColor: 'rgb(33 64 154 / 80%)' },
          '&>th': { color: 'white' },
        }}
        onClick={(): void => setOpen({ key: refKey, status: !isOpen })}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            <Typography sx={{ ml: 1 }}>{startCase(refKey)}</Typography>
          </Box>
          {getInvoiceTotalFromLineItems() ? (
            <Typography
              style={{
                color:
                  getInvoiceTotal().toFixed(2) !==
                  getInvoiceTotalFromLineItems().toFixed(2)
                    ? '#EF314E'
                    : '#56c503',
              }}
            >
              {currencyFormatter(getInvoiceTotalFromLineItems())}
            </Typography>
          ) : null}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ p: 0 }} colSpan={2}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box sx={{ width: '100%', overflowX: 'auto', display: 'flex' }}>
              <DynamicTable refKey={refKey} data={data} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const ConverterActiveViewerWrapper = ({
  elevation = 0,
  spacing = 0,
  activeViewOutPut,
  loading,
  accordionStatuses,
  selectedVendor,
  converterStatusSaving,
  onAccordionChange,
  onConverterStatusChange,
}: {
  elevation?: number
  spacing?: number
  activeViewOutPut: ActiveViewType | null
  loading: boolean
  accordionStatuses: ActiveViewActionType
  selectedVendor: VendorType
  converterStatusSaving: boolean
  onAccordionChange: ({
    key,
    status,
  }: {
    key: keyof ActiveViewType
    status: boolean
  }) => void
  onConverterStatusChange: (status: 0 | 1 | 2) => void
}): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(true)
  // Update the status to inprogress is the status is not started
  useEffect(() => {
    if (selectedVendor && selectedVendor.converterStatus === 0) {
      onConverterStatusChange(1)
    }
  }, [selectedVendor])

  return (
    <>
      {loading ? <LinearProgress sx={{ height: '2px' }} /> : null}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={spacing}
      >
        <Grid item xs={12}>
          <Accordion
            elevation={elevation}
            defaultExpanded
            sx={{ height: expanded ? '100%' : 'auto' }}
            square
            disableGutters
            onChange={(e, isExpanded): void => setExpanded(isExpanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="converterActiveViewer-content"
              id="converterActiveViewer-header"
            >
              <Typography sx={{ flex: 1 }}>Active View</Typography>

              <Stack
                direction="row"
                spacing={0.5}
                alignItems="center"
                sx={{ mr: 1, color: 'rgba(0, 0, 0, 0.54)' }}
                onClick={(event): void => event.stopPropagation()}
              >
                <Typography>Mark Complete</Typography>
                <Tooltip
                  placement="top"
                  title={
                    selectedVendor.converterStatus === 2
                      ? 'Done'
                      : 'In progress.'
                  }
                  arrow
                >
                  {converterStatusSaving ? (
                    <CircularProgress
                      color="success"
                      size={14}
                      sx={{ mx: '.875rem !important' }}
                    />
                  ) : (
                    <MiniSwitch
                      checked={
                        selectedVendor.converterStatus === 2 ? true : false
                      }
                      size="small"
                      onChange={(event): void => {
                        // 0 ='NotStarted', 1 ='InProgress', 2 = 'Done'
                        onConverterStatusChange(event.target.checked ? 2 : 1)
                      }}
                    />
                  )}
                </Tooltip>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: theme => theme.zIndex.drawer + 1,
                  position: 'absolute',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                }}
                open={loading}
              >
                <CircularProgress color="inherit" size={20} />
              </Backdrop>
              {activeViewOutPut ? (
                <TableContainer>
                  <Table
                    size="small"
                    aria-label="collapsible converter config table"
                  >
                    <TableBody>
                      {Object.keys(activeViewOutPut).map((key: any) => (
                        <ActiveViewItem
                          key={key}
                          refKey={key}
                          data={activeViewOutPut}
                          accordionStatuses={accordionStatuses}
                          setOpen={onAccordionChange}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  )
}

export default ConverterActiveViewerWrapper
