import { useAuth0 } from '@auth0/auth0-react'
import { Alert, Box } from '@mui/material'
import { AuthenticationGuard } from 'components/atoms/authenticationGuard'
import Loading from 'components/molecules/Loading'
import AccessDenied from 'components/pages/401'
import Forbidden from 'components/pages/403'
import NotFound from 'components/pages/404'
// import Dashboard from 'components/pages/Dashboard'
import Hauler from 'components/pages/Haulers'
import Home from 'components/pages/Home'
import Invoice from 'components/pages/Invoice'
import ConverterConfig from 'components/pages/Invoice/ConverterConfig'
import InvoiceDetails from 'components/pages/Invoice/Details'
import Process from 'components/pages/Process'
import ProductMappings from 'components/pages/ProductMappings'
import Profile from 'components/pages/Profile'
import Vendor from 'components/pages/Vendor'
import VendorDetails from 'components/pages/Vendor/Details'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import {
  savedInvoiceGridFilterModelAtom,
  selectedVendorAtom,
} from 'stores/invoice'
import 'styles/App.scss'

const App = (): JSX.Element => {
  const { isLoading, error } = useAuth0()

  const location = useLocation()

  // Page filters
  const [filterModel, setFilterModel] = useAtom(savedInvoiceGridFilterModelAtom)
  const [selectedVendor, setVendor] = useAtom(selectedVendorAtom)

  useEffect(() => {
    // Clear if there are any filter models
    // Invoices
    if (
      !location.pathname.includes('/invoices') &&
      (filterModel !== null || selectedVendor !== null)
    ) {
      setFilterModel(null)
      setVendor(null)
    }
  }, [location])

  useEffect(() => {
    async function checkAuth(): Promise<JSX.Element | undefined> {
      if (error) {
        return <Alert severity="error">{error.message}</Alert>
      }

      if (isLoading) {
        return <Loading />
      }
    }

    checkAuth()
  }, [isLoading, error])

  return (
    <Box className="app" data-testid="app" component="main">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/dashboard"
          element={<AuthenticationGuard component={Process} />}
        />
        <Route
          path="/invoices"
          element={<AuthenticationGuard component={Invoice} />}
        />
        <Route
          path="/invoices/:id"
          element={<AuthenticationGuard component={InvoiceDetails} />}
        />
        <Route
          path="/converter"
          element={<AuthenticationGuard component={ConverterConfig} />}
        />
        <Route
          path="/vendors"
          element={<AuthenticationGuard component={Vendor} />}
        />
        <Route
          path="/productMappings"
          element={<AuthenticationGuard component={ProductMappings} />}
        />
        <Route
          path="/haulers"
          element={<AuthenticationGuard component={Hauler} />}
        />
        <Route
          path="/vendors/add"
          element={<AuthenticationGuard component={VendorDetails} />}
        />
        <Route
          path="/vendors/:name"
          element={<AuthenticationGuard component={VendorDetails} />}
        />
        <Route
          path="/profile"
          element={<AuthenticationGuard component={Profile} />}
        />
        {/* <Route
          path="/apDashboard"
          element={<AuthenticationGuard component={Process} />}
        /> */}
        <Route path="*" element={<NotFound />} />
        <Route path="/401" element={<AccessDenied />} />
        <Route path="/403" element={<Forbidden />} />
      </Routes>
    </Box>
  )
}

export default App
