import LookupKeywords from 'components/atoms/LookupKeywords'
import { PrimitiveAtom, useAtom } from 'jotai'
import { uniqueId } from 'lodash'
import { DropResult } from 'react-beautiful-dnd'
import {
  accountLookupAtom,
  accountLookupAtomsAtom,
  footerLookupAtom,
  footerLookupAtomsAtom,
  headerLookupAtom,
  headerLookupAtomsAtom,
  invoiceDateLookupAtom,
  invoiceDateLookupAtomsAtom,
  invoiceNumberLookupAtom,
  invoiceNumberLookupAtomsAtom,
  invoiceTotalLookupAtom,
  invoiceTotalLookupAtomsAtom,
} from 'stores/invoice'
import { LookupKeywordType } from 'types/InvoiceParamters'

const newLookUp = (lu: string): LookupKeywordType => ({
  id: uniqueId(`${lu}`),
  regExpression: '',
  startPosition: 0,
  offset: 0,
  followedByRegExpression: null,
})

const LookupKeywordsWrapper = (): JSX.Element => {
  // Account lookup
  const [accoutLookUpAtoms, setAccountLookupAtoms] = useAtom(
    accountLookupAtomsAtom
  )

  const [accoutLookUp, setAccountLookup] = useAtom(accountLookupAtom)

  const handleAddAccountLookup = (): void => {
    setAccountLookupAtoms({
      type: 'insert',
      value: newLookUp('ACLU_'),
    })
  }

  const handleRemoveAccountLookup = (
    lookupAtom: PrimitiveAtom<LookupKeywordType>
  ): void => {
    setAccountLookupAtoms({
      type: 'remove',
      atom: lookupAtom,
    })
  }

  const handleAccountLUDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const updateLookups: any = Array.from(accoutLookUp)
    const [removed] = updateLookups.splice(result.source.index, 1)
    updateLookups.splice(result.destination.index, 0, removed)
    setAccountLookup(updateLookups)
  }

  //Invoice Number Lookup --------------------------------------------------
  const [invoiceNumberLookupAtoms, setInvoiceNumberLookupAtoms] = useAtom(
    invoiceNumberLookupAtomsAtom
  )

  const [invoiceNumberLookup, setInvoiceNumberLookup] = useAtom(
    invoiceNumberLookupAtom
  )

  const handleAddInvoiceNumberLookup = (): void => {
    setInvoiceNumberLookupAtoms({
      type: 'insert',
      value: newLookUp('INLU_'),
    })
  }

  const handleRemoveInvoiceNumberLookup = (
    lookupAtom: PrimitiveAtom<LookupKeywordType>
  ): void => {
    setInvoiceNumberLookupAtoms({
      type: 'remove',
      atom: lookupAtom,
    })
  }

  const handleInvoiceNumberLUDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const updateLookups: any = Array.from(invoiceNumberLookup)
    const [removed] = updateLookups.splice(result.source.index, 1)
    updateLookups.splice(result.destination.index, 0, removed)
    setInvoiceNumberLookup(updateLookups)
  }

  //Invoice Date Lookup --------------------------------------------------
  const [invoiceDateLookupAtoms, setInvoiceDateLookupAtoms] = useAtom(
    invoiceDateLookupAtomsAtom
  )

  const [invoiceDateLookup, setInvoiceDateLookup] = useAtom(
    invoiceDateLookupAtom
  )

  const handleAddInvoiceDateLookup = (): void => {
    setInvoiceDateLookupAtoms({
      type: 'insert',
      value: newLookUp('IDLU_'),
    })
  }

  const handleRemoveInvoiceDateLookup = (
    lookupAtom: PrimitiveAtom<LookupKeywordType>
  ): void => {
    setInvoiceDateLookupAtoms({
      type: 'remove',
      atom: lookupAtom,
    })
  }

  const handleInvoiceDateLUDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const updateLookups: any = Array.from(invoiceDateLookup)
    const [removed] = updateLookups.splice(result.source.index, 1)
    updateLookups.splice(result.destination.index, 0, removed)
    setInvoiceDateLookup(updateLookups)
  }

  //Invoice Total Lookup --------------------------------------------------
  const [invoiceTotalLookupAtoms, setInvoiceTotalLookupAtoms] = useAtom(
    invoiceTotalLookupAtomsAtom
  )

  const [invoiceTotalLookup, setInvoiceTotalLookup] = useAtom(
    invoiceTotalLookupAtom
  )

  const handleAddInvoiceTotalLookup = (): void => {
    setInvoiceTotalLookupAtoms({
      type: 'insert',
      value: newLookUp('ITLU_'),
    })
  }

  const handleRemoveInvoiceTotalLookup = (
    lookupAtom: PrimitiveAtom<LookupKeywordType>
  ): void => {
    setInvoiceTotalLookupAtoms({
      type: 'remove',
      atom: lookupAtom,
    })
  }

  const handleInvoiceTotalLUDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const updateLookups: any = Array.from(invoiceTotalLookup)
    const [removed] = updateLookups.splice(result.source.index, 1)
    updateLookups.splice(result.destination.index, 0, removed)
    setInvoiceTotalLookup(updateLookups)
  }

  //Header Lookup --------------------------------------------------
  const [headerLookupAtoms, setHeaderLookupAtoms] = useAtom(
    headerLookupAtomsAtom
  )

  const [headerLookup, setHeaderLookup] = useAtom(headerLookupAtom)

  const handleAddHeaderLookup = (): void => {
    setHeaderLookupAtoms({
      type: 'insert',
      value: newLookUp('HLU_'),
    })
  }

  const handleRemoveHeaderLookup = (
    lookupAtom: PrimitiveAtom<LookupKeywordType>
  ): void => {
    setHeaderLookupAtoms({
      type: 'remove',
      atom: lookupAtom,
    })
  }

  const handleHeaderLUDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const updateLookups: any = Array.from(headerLookup)
    const [removed] = updateLookups.splice(result.source.index, 1)
    updateLookups.splice(result.destination.index, 0, removed)
    setHeaderLookup(updateLookups)
  }

  // Footer lookup --------------------------------------------------
  const [footerLookupAtoms, setFooterLookupAtoms] = useAtom(
    footerLookupAtomsAtom
  )

  const [footerLookup, setFooterLookup] = useAtom(footerLookupAtom)

  const handleAddFooterLookup = (): void => {
    setFooterLookupAtoms({
      type: 'insert',
      value: newLookUp('FLU_'),
    })
  }

  const handleRemovefooterLookup = (
    lookupAtom: PrimitiveAtom<LookupKeywordType>
  ): void => {
    setFooterLookupAtoms({
      type: 'remove',
      atom: lookupAtom,
    })
  }

  const handleFooterLUDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const updateLookups: any = Array.from(footerLookup)
    const [removed] = updateLookups.splice(result.source.index, 1)
    updateLookups.splice(result.destination.index, 0, removed)
    setFooterLookup(updateLookups)
  }

  return (
    <>
      <LookupKeywords
        title="Account Lookups"
        lookUpAtoms={accoutLookUpAtoms}
        onDragEnd={handleAccountLUDragEnd}
        onAddLookup={handleAddAccountLookup}
        onRowDelete={handleRemoveAccountLookup}
      />
      <LookupKeywords
        title="Invoice Number Lookups"
        lookUpAtoms={invoiceNumberLookupAtoms}
        onDragEnd={handleInvoiceNumberLUDragEnd}
        onAddLookup={handleAddInvoiceNumberLookup}
        onRowDelete={handleRemoveInvoiceNumberLookup}
      />
      <LookupKeywords
        title="Invoice Date Lookups"
        lookUpAtoms={invoiceDateLookupAtoms}
        onDragEnd={handleInvoiceDateLUDragEnd}
        onAddLookup={handleAddInvoiceDateLookup}
        onRowDelete={handleRemoveInvoiceDateLookup}
      />

      <LookupKeywords
        title="Invoice Total Lookups"
        lookUpAtoms={invoiceTotalLookupAtoms}
        onDragEnd={handleInvoiceTotalLUDragEnd}
        onAddLookup={handleAddInvoiceTotalLookup}
        onRowDelete={handleRemoveInvoiceTotalLookup}
      />

      <LookupKeywords
        title="Header Lookups"
        lookUpAtoms={headerLookupAtoms}
        onDragEnd={handleHeaderLUDragEnd}
        onAddLookup={handleAddHeaderLookup}
        onRowDelete={handleRemoveHeaderLookup}
      />

      <LookupKeywords
        title="Footer Lookups"
        lookUpAtoms={footerLookupAtoms}
        onDragEnd={handleFooterLUDragEnd}
        onAddLookup={handleAddFooterLookup}
        onRowDelete={handleRemovefooterLookup}
      />
    </>
  )
}

export default LookupKeywordsWrapper
