import { AxiosResponse } from 'axios'
import AppService from 'services'
import {
  InvoiceListFilterParamsType,
  InvoiceStatusDetailsType,
  InvoiceStatusListType,
} from 'types/apDashboard'

export const getInvoiceStatusList = async (
  params?: InvoiceListFilterParamsType
): Promise<AxiosResponse<InvoiceStatusListType[], any>> => {
  try {
    return await AppService.ApDashboard.getData(
      'invoiceStatusList',
      params,
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const invoiceStatusDetailsById = async (params?: {
  id: string
}): Promise<AxiosResponse<InvoiceStatusDetailsType, any>> => {
  try {
    return await AppService.ApDashboard.getData(
      'invoiceStatusDetailsById',
      params,
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
