import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material'
import GroupedInvoiceLines from 'components/molecules/GroupedInvoiceLines'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
} from 'components/pages/Invoice/Details'
import { PrimitiveAtom } from 'jotai'
import { useState } from 'react'
import { GroupedInvoiceLine, InvoiceDetailsType } from 'types/InvoiceDetails'
import { currencyFormatter } from 'utils'

const InvoiceDetailsInvoiceLines = ({
  invoiceDetails,
  groupedInvoiceLinesAtoms,
  setIsDirty,
}: {
  invoiceDetails: InvoiceDetailsType
  groupedInvoiceLinesAtoms: PrimitiveAtom<GroupedInvoiceLine>[]
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
  const [open, setOpen] = useState(true)
  const [expanded, setExpanded] = useState<string | false>('sites')

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const handleInvoiceFileClick = (event: React.MouseEvent): void => {
    event.stopPropagation()
    if (invoiceDetails.fileUri) {
      const newWindow = window.open(
        invoiceDetails.fileUri,
        '_blank',
        'noopener,noreferrer'
      )
      if (newWindow) {
        newWindow.opener = null
      }
    }
  }

  return (
    <Accordion expanded={expanded === 'sites'} onChange={handleChange('sites')}>
      <AccordionSummary aria-controls="sites" id="sites-header">
        <Box>
          <IconButton
            aria-label="expand rows"
            size="small"
            title={open ? 'Collapse All Sites' : 'Expand All Sites'}
            onClick={(event): void => {
              event.stopPropagation()
              setOpen(!open)
            }}
            sx={{
              mr: 1,
              color: '#fff',
              position: 'absolute',
              left: '10px',
              top: '8px',
            }}
          >
            {open ? (
              <UnfoldLessIcon fontSize="small" />
            ) : (
              <UnfoldMoreIcon fontSize="small" />
            )}
          </IconButton>
          <Typography sx={{ position: 'relative', top: '2px', ml: 2 }}>
            SITES
          </Typography>
        </Box>

        <Tooltip
          title={
            invoiceDetails.fileUri
              ? `Open ${invoiceDetails.fileName} Invoice`
              : 'No invoice available'
          }
          arrow
          placement="left"
        >
          <IconButton
            size="small"
            sx={{
              mr: 1,
              p: 0,
              color: invoiceDetails.fileUri ? '#fff' : '#ccc',
            }}
            onClick={handleInvoiceFileClick}
          >
            {invoiceDetails.fileUri ? (
              <LinkIcon fontSize="inherit" />
            ) : (
              <LinkOffIcon fontSize="inherit" />
            )}
          </IconButton>
        </Tooltip>
      </AccordionSummary>
      <AccordionDetails>
        {groupedInvoiceLinesAtoms && groupedInvoiceLinesAtoms.length ? (
          <TableContainer>
            <Table sx={{ width: '100%' }}>
              <TableBody>
                {groupedInvoiceLinesAtoms.map(groupedInvoiceLinesAtom => (
                  <GroupedInvoiceLines
                    groupedInvoiceLinesAtom={groupedInvoiceLinesAtom}
                    key={`${groupedInvoiceLinesAtom}`}
                    collapseAll={open}
                    isDirty={setIsDirty}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography sx={{ p: 2 }}>No Data Found</Typography>
        )}
      </AccordionDetails>
      {groupedInvoiceLinesAtoms && groupedInvoiceLinesAtoms.length ? (
        <AccordionActions>
          <Box sx={{ display: 'flex', alignItems: 'end' }}>
            <IconButton
              disableRipple
              aria-label="expand row"
              size="small"
              title={open ? 'Collapse All' : 'Expand All'}
              onClick={(): void => setOpen(!open)}
              sx={{
                mr: 0.5,
                px: 1,
                py: 0,
              }}
            >
              {open ? (
                <UnfoldLessIcon color="secondary" />
              ) : (
                <UnfoldMoreIcon color="disabled" />
              )}
            </IconButton>
            <Typography variant="body2" sx={{ fontWeight: 'inherit' }}>
              Total
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ fontWeight: 'inherit' }}>
            {currencyFormatter(invoiceDetails.invoiceTotalD)}
          </Typography>
        </AccordionActions>
      ) : null}
    </Accordion>
  )
}

export default InvoiceDetailsInvoiceLines
