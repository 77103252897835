import AddSharpIcon from '@mui/icons-material/AddSharp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp'
import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import { Fragment, useRef, useState } from 'react'

interface optionsType {
  name: string
  value: number
}

const SplitButton = ({
  options,
  selected = undefined,
  onOptionSelection,
  onRemove,
  onAdd,
}: {
  options: optionsType[]
  selected?: optionsType | undefined
  onOptionSelection?: (option: optionsType) => void
  onRemove?: (option: optionsType) => void
  onAdd?: (option: optionsType) => void
}): JSX.Element => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [selectedOption, setSelectedOption] = useState<optionsType | undefined>(
    selected
  )
  const [isHovering, setIsHovering] = useState(true)

  const handleMouseOver = (): void => {
    setIsHovering(true)
  }

  const handleMouseOut = (): void => {
    setIsHovering(false)
  }

  const handleRemove = (): void => {
    if (onRemove && selectedOption) onRemove(selectedOption)
  }

  const handleAdd = (): void => {
    if (onAdd && selectedOption) onAdd(selectedOption)
    setSelectedOption(undefined)
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: optionsType
  ): void => {
    setSelectedOption(option)
    if (onOptionSelection) onOptionSelection(option)
    setOpen(false)
  }

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <Fragment>
      <ButtonGroup
        color="primary"
        variant="text"
        ref={anchorRef}
        aria-label="split button"
        className={
          isHovering ? 'splitButton splitButton--hover' : 'splitButton'
        }
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        sx={{
          backgroundColor: onAdd ? 'rgba(0,164,118,0.15)' : 'white',
          border: '1px solid rgb(189, 189, 189)',
          borderRadius: '1rem',
          alignItems: 'center',
          p: 0.3,
          '&:hover': {},
        }}
      >
        {onRemove ? (
          <IconButton
            size="small"
            onClick={handleRemove}
            sx={{
              p: 0,
              '&:hover': {
                backgroundColor: 'rgba(0,164,118,0.25)',
              },
            }}
            color="primary"
          >
            <RemoveSharpIcon sx={{ fontSize: '1.125rem' }} />
          </IconButton>
        ) : null}

        {onAdd ? (
          <IconButton
            size="small"
            onClick={handleAdd}
            sx={{
              p: 0,
              '&:hover': {
                backgroundColor: 'rgba(0,164,118,0.25)',
              },
            }}
            color="primary"
            disabled={!selectedOption}
          >
            <AddSharpIcon sx={{ fontSize: '1.125rem' }} />
          </IconButton>
        ) : null}
        <Box
          sx={{
            px: 0.5,
            textTransform: 'capitalize',
            fontSize: '0.8rem',
            lineHeight: '1.25rem',
          }}
        >
          {selectedOption ? selectedOption.name : 'Select'}
        </Box>
        <IconButton
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select Options"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{
            p: 0,
            '&:hover': {
              backgroundColor: 'rgba(0,164,118,0.25)',
            },
          }}
          color="primary"
        >
          <ArrowDropDownIcon sx={{ fontSize: '1.125rem' }} />
        </IconButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }): JSX.Element => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map(option => (
                    <MenuItem
                      key={option.value}
                      selected={option === selectedOption}
                      onClick={(event): void =>
                        handleMenuItemClick(event, option)
                      }
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}

export default SplitButton
