import ErrorIcon from '@mui/icons-material/Error'
import { Box, TableCell, TableRow, Tooltip } from '@mui/material'
import { format, isValid, parse } from 'date-fns'
import { PrimitiveAtom, useAtom } from 'jotai'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import { InvoiceDetailsType } from 'types/InvoiceDetails'
import { currencyFormatter, thousandsFormatter } from 'utils'

const GroupedInvoiceLine = ({
  invoiceLineAtom,
  isDirty,
}: {
  invoiceLineAtom: PrimitiveAtom<
    InvoiceDetailsType['groupedInvoiceLines'][number]['invoiceLines'][number]
  >
  isDirty: (dirty: boolean) => void
}): JSX.Element | null => {
  const [invoiceLine, setInvoiceLine] = useAtom(invoiceLineAtom)

  const invoiceLineDate = (invLineDate: string | null): Date | undefined => {
    if (invLineDate) {
      const slashDelimitter = invLineDate.split('/')
      const hyphenDelimitter = invLineDate.split('-')
      let parsedDate = undefined

      if (slashDelimitter.length === 3) {
        if (slashDelimitter[2].length === 4) {
          parsedDate = parse(invLineDate, 'MM/dd/yyyy', new Date())
        } else if (slashDelimitter[2].length === 2) {
          parsedDate = parse(invLineDate, 'MM/dd/yy', new Date())
        }
      } else if (hyphenDelimitter.length === 3) {
        if (hyphenDelimitter[2].length === 4) {
          parsedDate = parse(invLineDate, 'MM-dd-yyyy', new Date())
        } else if (hyphenDelimitter[2].length === 2) {
          parsedDate = parse(invLineDate, 'MM-dd-yy', new Date())
        }
      }
      return parsedDate
    }

    return undefined
  }
  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        '&:nth-of-type(odd)': {
          backgroundColor: '#F5F7FA',
        },
      }}
    >
      <TableCell
        sx={{
          p: 0,
          '& input': {
            padding: '4px 4px 4px 6px',
            border: 'none',
            background: 'none',
            outlineColor: '#008F6B',
            height: '2.25rem',
          },
        }}
      >
        {invoiceLine.date &&
        !invoiceLine.subtotalSumD &&
        isValid(invoiceLineDate(invoiceLine.date)) ? (
          <DatePicker
            selected={invoiceLineDate(invoiceLine.date)}
            closeOnScroll={true}
            dateFormat="MM/dd/yyyy"
            maxDate={new Date()}
            onChange={(date: Date): void => {
              const formatedDate = format(date, 'MM/dd/yyyy')
              isDirty(true)
              setInvoiceLine(previousObj => ({
                ...previousObj,
                date: formatedDate,
              }))
            }}
          />
        ) : (
          <Box sx={{ mx: 1 }}>{invoiceLine.date}</Box>
        )}
      </TableCell>
      <TableCell
        contentEditable={!invoiceLine.subtotalSumD}
        suppressContentEditableWarning
        onBlur={(event): void => {
          if (event.currentTarget.textContent !== invoiceLine.refNumber) {
            isDirty(true)
            setInvoiceLine(previousObj => ({
              ...previousObj,
              refNumber: event.currentTarget.textContent || '',
            }))
          }
        }}
        onPaste={(event): void => {
          const data = event.clipboardData.getData('text/plain')
          document.execCommand('insertHTML', false, data)
          event.preventDefault()
        }}
        sx={{
          p: 1,
          '&:focus': {
            outlineColor: '#008F6B',
          },
        }}
      >
        {invoiceLine.refNumber}
      </TableCell>
      <TableCell
        contentEditable={!invoiceLine.subtotalSumD}
        suppressContentEditableWarning
        onBlur={(event): void => {
          if (event.currentTarget.textContent !== invoiceLine.po) {
            isDirty(true)
            setInvoiceLine(previousObj => ({
              ...previousObj,
              po: event.currentTarget.textContent || '',
            }))
          }
        }}
        onPaste={(event): void => {
          const data = event.clipboardData.getData('text/plain')
          document.execCommand('insertHTML', false, data)
          event.preventDefault()
        }}
        sx={{
          p: 1,
          '&:focus': {
            outlineColor: '#008F6B',
          },
        }}
      >
        {invoiceLine.po}
      </TableCell>

      <TableCell
        contentEditable={!invoiceLine.subtotalSumD}
        suppressContentEditableWarning
        onBlur={(event): void => {
          if (event.currentTarget.textContent !== invoiceLine.description) {
            isDirty(true)
            setInvoiceLine(previousObj => ({
              ...previousObj,
              description: event.currentTarget.textContent || '',
            }))
          }
        }}
        onPaste={(event): void => {
          const data = event.clipboardData.getData('text/plain')
          document.execCommand('insertHTML', false, data)
          event.preventDefault()
        }}
        sx={{
          p: 1,
          '&:focus': {
            outlineColor: '#008F6B',
          },
        }}
        colSpan={invoiceLine.subtotalSumD ? 3 : 1}
        align={invoiceLine.subtotalSumD ? 'right' : 'left'}
      >
        {invoiceLine.description}
      </TableCell>

      {!invoiceLine.subtotalSum ? (
        <TableCell
          sx={{
            p: 0,
            '& input': {
              padding: '4px',
              border: 'none',
              background: 'none',
              outlineColor: '#008F6B',
              height: '2.25rem',
              textAlign: 'right',
            },
          }}
        >
          <NumericFormat
            value={invoiceLine.unitsD}
            defaultValue={0}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator=","
            onValueChange={(values: NumberFormatValues): void => {
              isDirty(true)
              const value = values.floatValue
                ? values.floatValue.toFixed(2)
                : '0.00'

              setInvoiceLine(previousObj => ({
                ...previousObj,
                unitsD: parseFloat(value),
                units: value,
              }))
            }}
          />
        </TableCell>
      ) : null}
      {!invoiceLine.subtotalSum ? (
        <TableCell
          sx={{
            p: 0,
            '& input': {
              padding: '4px',
              border: 'none',
              background: 'none',
              outlineColor: '#008F6B',
              height: '2.25rem',
              textAlign: 'right',
            },
          }}
        >
          <NumericFormat
            value={invoiceLine.unitPriceD}
            defaultValue={0}
            prefix={'$'}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator=","
            onValueChange={(values: NumberFormatValues): void => {
              isDirty(true)
              const value = values.floatValue
                ? values.floatValue.toFixed(2)
                : '0.00'

              setInvoiceLine(previousObj => ({
                ...previousObj,
                unitPriceD: parseFloat(value),
                unitPrice: thousandsFormatter(parseFloat(value)),
              }))
            }}
          />
        </TableCell>
      ) : null}
      <TableCell
        sx={{
          p: 1,
          '& input': {
            padding: '4px',
            border: 'none',
            background: 'none',
            outlineColor: '#008F6B',
            height: '2.25rem',
            textAlign: 'right',
          },
          '& span': {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'end',
          },
        }}
      >
        {!invoiceLine.subtotalSumD ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <NumericFormat
              value={invoiceLine.linePriceD}
              defaultValue={0}
              prefix={'$'}
              decimalScale={2}
              allowNegative={false}
              thousandSeparator=","
              onValueChange={(values: NumberFormatValues): void => {
                isDirty(true)
                const value = values.floatValue
                  ? values.floatValue.toFixed(2)
                  : '0.00'

                setInvoiceLine(previousObj => ({
                  ...previousObj,
                  linePriceD: parseFloat(value),
                  linePrice: thousandsFormatter(parseFloat(value)),
                }))
              }}
            />
            {invoiceLine.linePriceD.toFixed(2) !==
            (invoiceLine.unitsD * invoiceLine.unitPriceD).toFixed(2) ? (
              <Tooltip
                title="Units or $/Unit miss matches with the line price"
                arrow
                placement="left"
              >
                <ErrorIcon sx={{ fontSize: '1rem' }} color="error" />
              </Tooltip>
            ) : null}
          </Box>
        ) : (
          <span>{currencyFormatter(invoiceLine.subtotalSumD)}</span>
        )}
      </TableCell>
    </TableRow>
  )
}

export default GroupedInvoiceLine
