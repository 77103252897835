import { AxiosResponse } from 'axios'
import { sortBy } from 'lodash'
import AppService from 'services'
import {
  MissingServiceAccLUType,
  VendorType,
  vendorNameListType,
} from 'types/Vendor'

export const getVendorList = async (): Promise<
  AxiosResponse<VendorType[], any>
> => {
  try {
    return await AppService.Vendor.getData('vendorsList', {}, true).then(
      (resp: AxiosResponse<VendorType[], any>) => {
        // Sort vendors by long name
        if (resp.data.length) {
          resp.data = sortBy(resp.data, 'longName')
        }
        return resp
      }
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getVendorNamesList = async (): Promise<
  AxiosResponse<vendorNameListType[], any>
> => {
  try {
    return await AppService.Vendor.getData('vendorNameList', {}, true).then(
      (resp: AxiosResponse<VendorType[], any>) => {
        // Sort vendors by long name
        if (resp.data.length) {
          resp.data = sortBy(resp.data, 'longName')
        }
        return resp
      }
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getVendorDetails = async (
  name: string
): Promise<AxiosResponse<VendorType, any>> => {
  try {
    return await AppService.Vendor.getData('vendorDetails', { name }, true)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getVendorInvoiceFileList = async (
  vendorName: string
): Promise<AxiosResponse<string[], any>> => {
  try {
    return await AppService.Vendor.getData(
      'vendorsInvoiceList',
      { vendorName },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getMissingServiceAccLUByvendor = async (
  vendorName: string
): Promise<AxiosResponse<MissingServiceAccLUType[], any>> => {
  try {
    return await AppService.Vendor.getData(
      'missingServiceAccountLookupsByVendor',
      { vendorName },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteMissingServiceAccLUById = async (
  id: string
): Promise<AxiosResponse<any, any>> => {
  try {
    return await AppService.Vendor.deleteData(
      'deleteMissingServiceAccountLookupById',
      id
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const upsertVendor = async (
  vendor: VendorType
): Promise<AxiosResponse<VendorType | string, any>> => {
  const updatedVendorDetails = { ...vendor, longName: vendor.longName.trim() }
  try {
    return await AppService.Vendor.saveData('vendor', updatedVendorDetails)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteVendor = async (
  vendorName: string,
  vendorId: string
): Promise<AxiosResponse<any, any>> => {
  try {
    return await AppService.Vendor.deleteData('vendor', {
      vendorName,
      vendorId,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteMissingServiceAccount = async (
  id: string
): Promise<AxiosResponse<any, any>> => {
  try {
    return await AppService.Vendor.deleteData('deleteMissingServiceAccount', id)
  } catch (error) {
    return Promise.reject(error)
  }
}
