import CloudOffIcon from '@mui/icons-material/CloudOff'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
// import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { VendorType } from 'types/Vendor'

const ConverterSelectionPanelWrapper = ({
  elevation = 0,
  vendors,
  selectedVendor,
  existingInvoiceFileList,
  selectedExistingInvoiceFile,
  uploadedInvoiceFile,
  loading,
  onVendorChange,
  onExistingInvoiceFileChange,
  onSubmit,
  onInvoiceFileUpload,
  onCancel,
}: {
  elevation?: number
  vendors: VendorType[]
  selectedVendor: VendorType | null
  existingInvoiceFileList: { label: string; value: string }[]
  selectedExistingInvoiceFile: { label: string; value: string } | null
  uploadedInvoiceFile: File | undefined
  loading: boolean
  onVendorChange: (selectedValue: VendorType | null) => void
  onExistingInvoiceFileChange: (
    vendor: VendorType | null,
    fileName: { label: string; value: string } | null
  ) => void
  onInvoiceFileUpload: (file: File | undefined) => void
  onSubmit: () => void
  onCancel: () => void
}): JSX.Element => {
  const [fileError, setFileError] = useState<string | null>(null)
  const [inputValue, setInputValue] = useState('')
  // Select invoice list
  const [selectedInvoicefile, setSelectedInvoiceFile] = useState<{
    label: string
    value: string
  } | null>(selectedExistingInvoiceFile)
  const [invoiceFileListInputVal, setInvoiceFileListInputVal] = useState('')

  // Invoice upload
  const handleInvoiceFileUpload = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    onInvoiceFileUpload(undefined)
    const { files } = event.currentTarget

    if (files && files[0] && files[0].type === 'application/pdf') {
      const fileNameRegex = /^([0-9a-zA-Z)(_\-. ])+\.(pdf)$/
      if (fileNameRegex.test(files[0].name)) {
        setFileError(null)
        onInvoiceFileUpload(files[0])
      } else {
        setFileError(
          'Only alphanumeric, dot, hyphen, underscore , ), (, and spaces are allowed.'
        )
      }
    } else {
      setFileError('Only PDF files are allowed.')
    }
  }

  const handleSubmit = (): void => {
    if (fileError === null && uploadedInvoiceFile) onSubmit()
  }

  useEffect(() => {
    setSelectedInvoiceFile(selectedExistingInvoiceFile)
  }, [selectedExistingInvoiceFile])

  return (
    <>
      {loading ? <LinearProgress sx={{ height: '2px' }} /> : null}
      <Box
        component={Paper}
        square
        elevation={elevation}
        sx={{
          p: 2,
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Grid item xs={12} md={4} xl={3}>
            <Autocomplete
              id="select-vendor"
              value={selectedVendor}
              inputValue={inputValue}
              options={vendors}
              getOptionLabel={(option): string => option.longName}
              renderOption={(props, option): JSX.Element => {
                return (
                  <li {...props} key={option.id + option.name}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ width: '100%', wordWrap: 'break-word' }}>
                        <Box component="span" sx={{ fontWeight: 'bold' }}>
                          {option.longName}
                        </Box>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ display: 'block' }}
                        >
                          {option.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                )
              }}
              renderInput={(params): JSX.Element => (
                <TextField {...params} required label="Select Vendor" />
              )}
              isOptionEqualToValue={(option, value): boolean =>
                option.id === value.id
              }
              onChange={(event: any, newValue: VendorType | null): void => {
                onVendorChange(newValue)
              }}
              onInputChange={(event, newInputValue): void => {
                setInputValue(newInputValue)
              }}
              size="small"
              disabled={loading}
            />
          </Grid>

          {(existingInvoiceFileList &&
            existingInvoiceFileList.length &&
            selectedVendor) ||
          uploadedInvoiceFile !== undefined ? (
            <Grid item xs={12} md={3} xl={2}>
              <Autocomplete
                id="select-existing-invoice-files"
                value={selectedInvoicefile}
                inputValue={invoiceFileListInputVal}
                options={existingInvoiceFileList}
                renderInput={(params): JSX.Element => (
                  <TextField {...params} label="Select invoice file" />
                )}
                onChange={(
                  event: any,
                  newValue: { label: string; value: string } | null
                ): void => {
                  setSelectedInvoiceFile(newValue)
                  onExistingInvoiceFileChange(selectedVendor, newValue)
                }}
                onInputChange={(event, newInputValue): void => {
                  setInvoiceFileListInputVal(newInputValue)
                }}
                size="small"
                disabled={!selectedVendor || loading}
              />
            </Grid>
          ) : null}
          {selectedVendor && !selectedInvoicefile ? (
            <Grid item xs={12} md="auto" sx={{ display: 'flex' }}>
              <ButtonGroup variant="outlined">
                <Button
                  aria-label="upload Invoice"
                  component="label"
                  sx={{
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                    borderRightColor: 'transparent',
                  }}
                  disabled={selectedVendor === null || loading}
                >
                  {uploadedInvoiceFile && uploadedInvoiceFile.name ? (
                    <Tooltip title={uploadedInvoiceFile.name} arrow>
                      <Typography
                        component="div"
                        variant="small"
                        sx={{
                          ml: 1,
                          maxWidth: '12.5rem',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {uploadedInvoiceFile.name}
                      </Typography>
                    </Tooltip>
                  ) : existingInvoiceFileList &&
                    existingInvoiceFileList.length ? (
                    'Select new invoice'
                  ) : (
                    'Select invoice'
                  )}
                  <input
                    hidden
                    onChange={handleInvoiceFileUpload}
                    accept="application/pdf"
                    type="file"
                  />
                </Button>
                <Tooltip title={fileError ? fileError : ''} arrow>
                  <Button
                    variant="contained"
                    disabled={selectedVendor === null || loading}
                    color={fileError ? 'error' : 'primary'}
                    onClick={handleSubmit}
                    sx={{
                      borderTopLeftRadius: '0',
                      borderBottomLeftRadius: '0',
                      marginLeft: '-0.0625rem',
                    }}
                  >
                    {fileError ? <CloudOffIcon /> : <CloudUploadIcon />}
                  </Button>
                </Tooltip>
              </ButtonGroup>
              {/* <Tooltip
                title="Want to direct upload bulk invoice files to azure blob storage ?"
                arrow
              >
                <Button
                  variant="contained"
                  disabled={selectedVendor === null || loading}
                  color="info"
                  sx={{
                    marginLeft: '0.5rem',
                    minWidth: '36px',
                    padding: '6px',
                  }}
                  target="_blank"
                  rel="noopener,noreferrer"
                  href="storageexplorer://v=1&accountid=%2Fsubscriptions%2F5eeab5f7-ed80-43c1-a54a-899ca7a79c16%2FresourceGroups%2Frg-invautodv-sandbox-eastus%2Fproviders%2FMicrosoft.Storage%2FstorageAccounts%2Fsainvautodvsandbox&subscriptionid=5eeab5f7-ed80-43c1-a54a-899ca7a79c16&resourcetype=Azure.BlobContainer&resourcename=admin-converter-configuration"
                >
                  <DriveFolderUploadIcon />
                </Button>
              </Tooltip> */}
            </Grid>
          ) : null}

          {selectedVendor ? (
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                size="small"
                onClick={onCancel}
                disabled={loading}
              >
                Cancel
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </>
  )
}

export default ConverterSelectionPanelWrapper
