import { Alert, Stack } from '@mui/material'

const AlertStack = ({
  data = [],
  severity,
}: {
  data: string[]
  severity: 'error' | 'info' | 'success' | 'warning'
}): JSX.Element => {
  return data && data.length ? (
    <Stack spacing={0.3}>
      {data.map((item, index) => (
        <Alert
          severity={severity}
          key={`${item}_${index}`}
          variant="standard"
          sx={{ boxShadow: 'none' }}
        >
          {item}
        </Alert>
      ))}
    </Stack>
  ) : (
    <></>
  )
}

export default AlertStack
