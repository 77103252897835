import { Box, Divider, Grid, Table, TableBody, Typography } from '@mui/material'
import AccountSummaryRow from 'components/atoms/AccountSummaryRow'
import { parseISO } from 'date-fns'
import { format } from 'date-fns-tz'
import { InvoiceDetailsType } from 'types/InvoiceDetails'

const InvoiceDetailsHeader = ({
  invoiceDetails,
}: {
  invoiceDetails: InvoiceDetailsType
}): JSX.Element => {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box>
            <Typography variant="h6" sx={{ mb: 1 }}>
              {invoiceDetails.companyName}
            </Typography>
            <Typography variant="body2">
              {invoiceDetails.companyEmail}
            </Typography>
            <Typography variant="body2">
              {invoiceDetails.companyPhone}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Table sx={{ maxWidth: '15rem' }}>
            <TableBody>
              <AccountSummaryRow
                label="Account Number"
                value={invoiceDetails.accountNumber}
                isRowBold
              />
              <AccountSummaryRow
                label="Invoice Date"
                value={invoiceDetails.invoiceDate}
              />
              <AccountSummaryRow
                label="Accrual Date"
                value={
                  invoiceDetails.accrualDate
                    ? format(parseISO(invoiceDetails.accrualDate), 'MM/dd/yyyy')
                    : 'N/A'
                }
              />
              <AccountSummaryRow
                label="Invoice Number"
                value={invoiceDetails.invoiceNumber}
              />
              <AccountSummaryRow
                label="invoice Total"
                value={invoiceDetails.invoiceTotalD}
                isValueBold
              />
              <AccountSummaryRow label="" value="" />
              <AccountSummaryRow
                label="Charges Credits"
                value={invoiceDetails.chargesCreditsD}
              />
              <AccountSummaryRow
                label="Acct Balance"
                value={invoiceDetails.acctBalanceD}
                isRowBold
              />
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  )
}

export default InvoiceDetailsHeader
