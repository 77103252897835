import { useAuth0 } from '@auth0/auth0-react'
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import logo from 'assets/img/RTS_logo.png'
import theta_logo from 'assets/img/Theta_logo.png'

// import { Navigate } from 'react-router-dom'

const Home = (): JSX.Element => {
  const theme = useTheme()
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  if (isAuthenticated) {
    // return <Navigate to="/dashboard" state={{ returnTo: location }} />
  }

  const handleLogin = async (): Promise<void> => {
    await loginWithRedirect({
      appState: { returnTo: '/dashboard' },
      authorizationParams: {
        prompt: 'login',
      },
    })
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        component="nav"
        position="fixed"
        elevation={0}
        sx={{ backgroundColor: theme.palette.primary.dark }}
      >
        <Toolbar sx={{ minHeight: '3rem!important' }}>
          <Box sx={{ display: 'flex', alignItems: 'end', flexGrow: 1 }}>
            <img src={logo} style={{ height: '1.875rem' }} />
            <Typography
              data-testid="app-title"
              variant="small"
              component="div"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 100,
                fontSize: '0.75rem',
                ml: 1,
              }}
            >
              THETA
            </Typography>
          </Box>
          <Box>
            <Button
              sx={{ color: '#fff' }}
              onClick={handleLogin}
              data-testid="login"
            >
              login
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{ p: 3, pt: 6, width: '100%', minHeight: '100vh' }}
      >
        <Box
          sx={{
            textAlign: 'center',
            height: 'calc(100vh - 72px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img src={theta_logo} />
        </Box>
      </Box>
    </Box>
  )
}
export default Home
