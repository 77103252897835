import { TableCell, TableRow, Typography } from '@mui/material'
import { currencyFormatter } from 'utils'

const AccountSummaryRow = ({
  label,
  value,
  isRowBold = false,
  isValueBold = false,
}: {
  label: string
  value: string | number
  isRowBold?: boolean
  isValueBold?: boolean
}): JSX.Element => {
  return (
    <TableRow>
      <TableCell
        sx={{
          py: '0.25rem',
          px: 0,
          border: '0',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
            fontWeight: isRowBold ? '600' : 'normal',
          }}
        >
          {label ? `${label}:` : ''}
        </Typography>
      </TableCell>
      <TableCell sx={{ py: '0.25rem', px: 0, border: '0' }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: isValueBold || isRowBold ? '600' : '500' }}
        >
          {typeof value === 'number' ? currencyFormatter(value) : value}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default AccountSummaryRow
