import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ControlPointDuplicateOutlinedIcon from '@mui/icons-material/ControlPointDuplicateOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import ReorderSharpIcon from '@mui/icons-material/ReorderSharp'
import {
  Box,
  Button,
  IconButton,
  Switch,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { PrimitiveAtom, SetStateAction, WritableAtom, useAtom } from 'jotai'
import { focusAtom } from 'jotai/optics'
import { uniqueId } from 'lodash'
import { useMemo } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'
import {
  callMappingOptions,
  customInvoiceLinesAtom,
  customInvoiceLinesAtomsAtom,
} from 'stores/invoice'
import {
  InvoiceLineType,
  cellColumnMappingOptionsType,
} from 'types/InvoiceParamters'

import SplitButton from './SplitButton'

const useColumnMappingsAtom = (
  invoiceLineAtom: PrimitiveAtom<InvoiceLineType>
): WritableAtom<number[], SetStateAction<number[]>, void> => {
  return useMemo(
    () => focusAtom(invoiceLineAtom, optic => optic.prop('cellColumnMappings')),
    [invoiceLineAtom]
  )
}

const useColumnMappingAtom = ({
  cellColumnMappingsAtom,
  index,
}: {
  cellColumnMappingsAtom: PrimitiveAtom<number[]>
  index: number
}): WritableAtom<number | undefined, SetStateAction<number>, void> => {
  return useMemo(() => {
    return focusAtom(cellColumnMappingsAtom, optic => optic.at(index))
  }, [cellColumnMappingsAtom, index])
}

const CellColumnMapping = ({
  cellColumnMappingsAtom,
  index,
  onRemove,
}: {
  cellColumnMappingsAtom: PrimitiveAtom<number[]>
  index: number
  onRemove: (option: cellColumnMappingOptionsType, position: number) => void
}): JSX.Element => {
  const cellColumnMappingAtom = useColumnMappingAtom({
    cellColumnMappingsAtom,
    index,
  })
  const [cellColumnMapping, setCellColumnMapping] = useAtom(
    cellColumnMappingAtom
  )

  const handleColumnMappingUpdate = (
    option: cellColumnMappingOptionsType
  ): void => {
    setCellColumnMapping(option.value)
  }

  const selectedColumnMapping: cellColumnMappingOptionsType | undefined =
    callMappingOptions.find(
      callMappingOption => callMappingOption.value === cellColumnMapping
    )

  return (
    <SplitButton
      options={callMappingOptions}
      onOptionSelection={(option): void => handleColumnMappingUpdate(option)}
      onRemove={(option): void => onRemove(option, index)}
      selected={selectedColumnMapping}
    />
  )
}

const InvoiceLine = ({
  invoiceLineAtom,
  index,
  deleteDisabled,
  onRowDuplicate,
  onRowDelete,
}: {
  invoiceLineAtom: PrimitiveAtom<InvoiceLineType>
  index: number
  deleteDisabled: boolean
  onRowDuplicate: (
    invoiceLine: InvoiceLineType,
    invoiceLineAtom: PrimitiveAtom<InvoiceLineType>,
    index: number
  ) => void
  onRowDelete: (invoiceLineAtom: PrimitiveAtom<InvoiceLineType>) => void
}): JSX.Element => {
  const [invoiceLine, setInvoiceLine] = useAtom(invoiceLineAtom)

  // Cell coulmn mapping
  const cellColumnMappingsAtom = useColumnMappingsAtom(invoiceLineAtom)
  const [cellColumnMappings, setCellColumnMappings] = useAtom(
    cellColumnMappingsAtom
  )

  const handleRowDuplicate = (): void => {
    onRowDuplicate(invoiceLine, invoiceLineAtom, index)
  }

  const handleRowDelete = (): void => {
    onRowDelete(invoiceLineAtom)
  }

  const handleColumnMappingAdd = (
    option: cellColumnMappingOptionsType
  ): void => {
    setInvoiceLine(previousObj => ({
      ...previousObj,
      cellColumnMappings: [...previousObj.cellColumnMappings, option.value],
    }))
  }

  const handleColumnMappingRemove = (
    option: cellColumnMappingOptionsType,
    position: number
  ): void => {
    if (cellColumnMappings[position] === option.value) {
      const removedMappings =
        position >= 0
          ? [
              ...cellColumnMappings.slice(0, position),
              ...cellColumnMappings.slice(position + 1),
            ]
          : cellColumnMappings

      setCellColumnMappings(removedMappings)
    } else {
      console.error('Invalid position')
    }
  }

  return (
    <Draggable key={invoiceLine.id} draggableId={invoiceLine.id} index={index}>
      {(
        draggableProvided: DraggableProvided,
        snapshot: DraggableStateSnapshot
      ): JSX.Element => {
        return (
          <TableRow
            hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            style={{
              ...draggableProvided.draggableProps.style,
              background: snapshot.isDragging ? 'rgba(0,164,118,0.25)' : 'none',
            }}
          >
            <TableCell
              align="left"
              width="20px"
              sx={{
                px: 0,
                verticalAlign: 'middle',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip title="Drag Row" placement="right">
                  <Box {...draggableProvided.dragHandleProps}>
                    <ReorderSharpIcon
                      sx={{ fontSize: '1.25rem', color: '#798996' }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title="Duplicate Current Row" placement="right">
                  <ControlPointDuplicateOutlinedIcon
                    color="primary"
                    sx={{ fontSize: '1.1rem', ml: 1, cursor: 'pointer' }}
                    onClick={handleRowDuplicate}
                  />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ px: 0 }} width="20px">
              {deleteDisabled ? (
                <Tooltip title="Click To Delete" placement="right">
                  <DeleteOutlineIcon
                    color="error"
                    sx={{ fontSize: '1.1rem', ml: 1, cursor: 'pointer' }}
                    onClick={handleRowDelete}
                  />
                </Tooltip>
              ) : null}
            </TableCell>
            <TableCell
              width="30%"
              contentEditable
              suppressContentEditableWarning
              onBlur={(event): void => {
                if (
                  event.currentTarget.textContent !== invoiceLine.regExpression
                )
                  setInvoiceLine(previousObj => ({
                    ...previousObj,
                    regExpression: event.currentTarget.textContent || '',
                  }))
              }}
              onPaste={(event): void => {
                const data = event.clipboardData.getData('text/plain')
                document.execCommand('insertHTML', false, data)
                event.preventDefault()
              }}
              sx={{
                '&:focus': {
                  outlineColor: '#008F6B',
                },
              }}
            >
              {invoiceLine.regExpression}
            </TableCell>
            <TableCell
              sx={{
                '&:focus': {
                  outlineColor: '#008F6B',
                },
              }}
              contentEditable
              suppressContentEditableWarning
              onBlur={(event): void => {
                if (event.currentTarget.textContent !== invoiceLine.description)
                  setInvoiceLine(previousObj => ({
                    ...previousObj,
                    description: event.currentTarget.textContent || '',
                  }))
              }}
              onPaste={(event): void => {
                const data = event.clipboardData.getData('text/plain')
                document.execCommand('insertHTML', false, data)
                event.preventDefault()
              }}
            >
              {invoiceLine.description}
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  aria-label="minus"
                  color="primary"
                  size="small"
                  disabled={invoiceLine.matchCellOffset <= 0}
                  onClick={(): void =>
                    setInvoiceLine(previousObj => ({
                      ...previousObj,
                      matchCellOffset: previousObj.matchCellOffset - 1,
                    }))
                  }
                >
                  <RemoveCircleOutlineIcon fontSize="inherit" />
                </IconButton>
                <Typography sx={{ mx: 1 }}>
                  {invoiceLine.matchCellOffset}
                </Typography>
                <IconButton
                  aria-label="add"
                  color="primary"
                  size="small"
                  onClick={(): void =>
                    setInvoiceLine(previousObj => ({
                      ...previousObj,
                      matchCellOffset: previousObj.matchCellOffset + 1,
                    }))
                  }
                >
                  <AddCircleOutlineIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& .splitButton': { marginRight: 1 },
                }}
              >
                {cellColumnMappings.map((cellColumnMapping, index) => (
                  <CellColumnMapping
                    key={`${cellColumnMapping}_${index}`}
                    cellColumnMappingsAtom={cellColumnMappingsAtom}
                    index={index}
                    onRemove={(option, position): void =>
                      handleColumnMappingRemove(option, position)
                    }
                  />
                ))}
                <SplitButton
                  options={callMappingOptions}
                  onAdd={(option): void => handleColumnMappingAdd(option)}
                />
              </Box>
            </TableCell>
            <TableCell>
              <Switch
                checked={invoiceLine.continueToNextLineIfNull}
                size="small"
                onChange={(event): void =>
                  setInvoiceLine(previousObj => ({
                    ...previousObj,
                    continueToNextLineIfNull: event.target.checked,
                  }))
                }
              />
            </TableCell>
          </TableRow>
        )
      }}
    </Draggable>
  )
}

const InvoiceLines = (): JSX.Element => {
  const [invoiceLineAtoms, setInvoiceLineAtoms] = useAtom(
    customInvoiceLinesAtomsAtom
  )

  const [invoiceLines, setInvoiceLines] = useAtom(customInvoiceLinesAtom)

  const handleDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const updatesInvoiceLines: any = Array.from(invoiceLines)
    const [removed] = updatesInvoiceLines.splice(result.source.index, 1)
    updatesInvoiceLines.splice(result.destination.index, 0, removed)
    setInvoiceLines(updatesInvoiceLines)
  }

  const handleInvoiceLineDuplicate = (
    invoiceLine: InvoiceLineType,
    invoiceLineAtom: PrimitiveAtom<InvoiceLineType>,
    index: number
  ): void => {
    const updatesInvoiceLines: InvoiceLineType[] = Array.from(invoiceLines)
    updatesInvoiceLines.splice(index + 1, 0, {
      ...invoiceLine,
      id: uniqueId('IL_'),
      description: invoiceLine.description
        ? `${invoiceLine.description} (COPY)`
        : '(COPY)',
    })

    setInvoiceLines(updatesInvoiceLines)
  }

  const handleInvoiceLineDelete = (
    invoiceLineAtom: PrimitiveAtom<InvoiceLineType>
  ): void => {
    setInvoiceLineAtoms({
      type: 'remove',
      atom: invoiceLineAtom,
    })
  }

  const onAddInvoiceLine = (): void => {
    setInvoiceLineAtoms({
      type: 'insert',
      value: {
        id: uniqueId('IL_'), // need to be unique
        regExpression: '',
        description: '',
        cellColumnMappings: [],
        matchCellOffset: 0,
        continueToNextLineIfNull: false,
      },
    })
  }

  return (
    <>
      <TableHead>
        <TableRow
          sx={{ backgroundColor: '#21409A', '&>th': { color: 'white' } }}
        >
          <TableCell align="left" colSpan={7}>
            Invoice Lines
          </TableCell>
        </TableRow>
        <TableRow
          sx={{ backgroundColor: '#798996', '&>th': { color: 'white' } }}
        >
          <TableCell sx={{ px: 0 }} width="20px"></TableCell>
          <TableCell sx={{ px: 0 }} width="20px"></TableCell>
          <TableCell width="30%">
            <Tooltip
              title="Regular expression/exact text match"
              placement="right"
              arrow
            >
              <Typography component="span">RegEx</Typography>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip
              title="Replaces any entered text with RegEx match found."
              placement="right"
              arrow
            >
              <Typography component="span">Description</Typography>
            </Tooltip>
          </TableCell>
          <TableCell>Offset</TableCell>
          <TableCell>Column Mappings</TableCell>
          <TableCell width="250px">
            <Tooltip
              title="Continue to next line if not found"
              placement="left"
              arrow
            >
              <Typography component="span">Continue?</Typography>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(droppableProvided: DroppableProvided): JSX.Element => (
            <TableBody
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {invoiceLineAtoms.map((invoiceLineAtom, index) => (
                <InvoiceLine
                  key={`${invoiceLineAtom}`}
                  invoiceLineAtom={invoiceLineAtom}
                  index={index}
                  deleteDisabled={invoiceLineAtoms.length > 1}
                  onRowDuplicate={handleInvoiceLineDuplicate}
                  onRowDelete={handleInvoiceLineDelete}
                />
              ))}
              {droppableProvided.placeholder}
              <TableRow>
                <TableCell
                  align="left"
                  colSpan={7}
                  sx={{
                    px: 0.8,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={(): void => onAddInvoiceLine()}
                    size="small"
                    endIcon={<PlaylistAddIcon />}
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

export default InvoiceLines
