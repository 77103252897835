import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { HaulerSetting } from 'types/Hauler'

// const regex = {
//   email:
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
// }

const ConverterHaulerPanelWrapper = ({
  elevation = 0,
  haulerSetting,
  loading = false,
  saving = false,
  canAddOrUpdate = false,
  onSubmit,
  onCancel,
}: {
  elevation?: number
  haulerSetting: HaulerSetting | null
  loading?: boolean
  saving?: boolean
  canAddOrUpdate: boolean
  onSubmit: (data: HaulerSetting) => void
  onCancel?: () => void
}): JSX.Element => {
  const [haulerSettingValues, setHaulerSettingValues] = useState<HaulerSetting>(
    {
      vendorname: '',
      haulerId: null,
      haulerIdUsa2: null,
      haulerIdElytus: null,
      fnOEnabled: false,
      sharePointFolder: '',
      invoiceIdentifierKeywordsCsv: '',
      aiModelId: '',
      incomingEmail: '',
      duplicateCheckOverride: false,
    }
  )

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { errors, isDirty },
  } = useForm<HaulerSetting>({
    defaultValues: {
      vendorname: '',
      haulerId: null,
      haulerIdUsa2: null,
      haulerIdElytus: null,
      fnOEnabled: false,
      sharePointFolder: '',
      invoiceIdentifierKeywordsCsv: '',
      aiModelId: '',
      incomingEmail: '',
      duplicateCheckOverride: false,
    },
  })

  useEffect(() => {
    if (haulerSetting) {
      setHaulerSettingValues(haulerSetting)
      reset(haulerSetting)
    } else {
      reset({
        vendorname: '',
        haulerId: null,
        haulerIdUsa2: null,
        haulerIdElytus: null,
        fnOEnabled: false,
        sharePointFolder: '',
        invoiceIdentifierKeywordsCsv: '',
        aiModelId: '',
        incomingEmail: '',
        duplicateCheckOverride: false,
      })
    }
  }, [haulerSetting])

  const hasHaulerIds = (): boolean => {
    const haulerIdUSA1 = getValues('haulerId')
    const haulerIdUSA2 = getValues('haulerIdUsa2')
    const haulerIdElytus = getValues('haulerIdElytus')

    return (
      (haulerIdUSA1 !== null && !!haulerIdUSA1.trim().length) ||
      (haulerIdUSA2 !== null && !!haulerIdUSA2.trim().length) ||
      (haulerIdElytus !== null && !!haulerIdElytus.trim().length)
    )
  }

  return (
    <>
      {loading ? <LinearProgress sx={{ height: '.125rem' }} /> : null}
      <Box
        component={Paper}
        square
        elevation={elevation}
        sx={{
          p: 2,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid container item columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12}>
                <Typography variant="body1">Hauler Settings</Typography>
              </Grid>
            </Grid>
            <Grid container item columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                  fullWidth
                  label="USA 1 Hauler Id"
                  size="small"
                  inputProps={{
                    readOnly: !canAddOrUpdate,
                  }}
                  InputProps={{
                    className: !canAddOrUpdate ? 'Mui-disabled' : undefined,
                  }}
                  {...register('haulerId', {
                    validate:
                      hasHaulerIds ||
                      'Either of hauler ID/IDs or Elytus Id is required',
                  })}
                  defaultValue={haulerSettingValues.haulerId ?? null}
                  placeholder="USA 1 Hauler Id"
                  error={errors?.haulerId ? true : false}
                  helperText={
                    errors.haulerId && errors.haulerId.type === 'validate'
                      ? 'Either or both of hauler ids are required'
                      : errors?.haulerId
                      ? String(errors.haulerId.message)
                      : ' '
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                  fullWidth
                  label="USA 2 Hauler Id"
                  size="small"
                  inputProps={{
                    readOnly: !canAddOrUpdate,
                  }}
                  InputProps={{
                    className: !canAddOrUpdate ? 'Mui-disabled' : undefined,
                  }}
                  {...register('haulerIdUsa2', {
                    validate:
                      hasHaulerIds ||
                      'Either of hauler ID/IDs or Elytus Id is required',
                  })}
                  defaultValue={haulerSettingValues.haulerIdUsa2 ?? null}
                  placeholder="USA 2 Hauler Id"
                  error={errors?.haulerIdUsa2 ? true : false}
                  helperText={
                    errors?.haulerIdUsa2 &&
                    errors.haulerIdUsa2.type === 'validate'
                      ? 'Either or both of hauler ids are required'
                      : errors?.haulerIdUsa2
                      ? String(errors.haulerIdUsa2.message)
                      : ' '
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                  fullWidth
                  label="Elytus Id"
                  size="small"
                  inputProps={{
                    readOnly: !canAddOrUpdate,
                  }}
                  InputProps={{
                    className: !canAddOrUpdate ? 'Mui-disabled' : undefined,
                  }}
                  {...register('haulerIdElytus', {
                    validate:
                      hasHaulerIds ||
                      'Either of hauler ID/IDs or Elytus Id is required',
                  })}
                  defaultValue={haulerSettingValues.haulerIdElytus ?? null}
                  placeholder="Elytus Id"
                  error={errors?.haulerIdElytus ? true : false}
                  helperText={
                    errors?.haulerIdElytus &&
                    errors.haulerIdElytus.type === 'validate'
                      ? 'Either hauler or Elytus Id is required'
                      : errors?.haulerIdElytus
                      ? String(errors.haulerIdElytus.message)
                      : ' '
                  }
                />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={3} xl={2.5}>
                <TextField
                  fullWidth
                  required
                  label="SharePoint Folder Path"
                  size="small"
                  inputProps={{
                    readOnly: !canAddOrUpdate,
                  }}
                  InputProps={{
                    className: !canAddOrUpdate ? 'Mui-disabled' : undefined,
                  }}
                  {...register('sharePointFolder', {
                    required: 'Share Point Folder Path is Required ',
                  })}
                  defaultValue={haulerSettingValues.sharePointFolder ?? ''}
                  placeholder="Share Point Folder Path"
                  error={errors?.sharePointFolder ? true : false}
                  helperText={
                    errors?.sharePointFolder
                      ? String(errors.sharePointFolder.message)
                      : ' '
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                  fullWidth
                  label="AI Model Id"
                  size="small"
                  inputProps={{
                    readOnly: !canAddOrUpdate,
                  }}
                  InputProps={{
                    className: !canAddOrUpdate ? 'Mui-disabled' : undefined,
                  }}
                  {...register('aiModelId')}
                  defaultValue={haulerSettingValues.aiModelId ?? ''}
                  placeholder="AI Model Id"
                  error={errors?.aiModelId ? true : false}
                  helperText={
                    errors?.aiModelId ? String(errors.aiModelId.message) : ' '
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                {/* <MulltipleEmailInput /> */}
                <TextField
                  fullWidth
                  label="Incoming Email"
                  size="small"
                  inputProps={{
                    readOnly: !canAddOrUpdate,
                  }}
                  InputProps={{
                    className: !canAddOrUpdate ? 'Mui-disabled' : undefined,
                  }}
                  {...register(
                    'incomingEmail'
                    // {
                    //   pattern: {
                    //     value: regex.email,
                    //     message: 'Invalid Email Id',
                    //   },
                    // }
                  )}
                  defaultValue={haulerSettingValues.incomingEmail ?? ''}
                  placeholder="Incoming Email"
                  error={errors?.incomingEmail ? true : false}
                  helperText={
                    errors?.incomingEmail
                      ? String(errors.incomingEmail.message)
                      : "To add multiple email ids use ' or ' as seperator"
                  }
                />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={3} xl={2.5}>
                <TextField
                  fullWidth
                  required
                  label="Invoice Identifier Keywords"
                  size="small"
                  inputProps={{
                    readOnly: !canAddOrUpdate,
                  }}
                  InputProps={{
                    className: !canAddOrUpdate ? 'Mui-disabled' : undefined,
                  }}
                  {...register('invoiceIdentifierKeywordsCsv', {
                    required: 'Invoice Identifier Keywords is Required ',
                  })}
                  defaultValue={
                    haulerSettingValues.invoiceIdentifierKeywordsCsv ?? ''
                  }
                  placeholder="Invoice Identifier Keywords"
                  error={errors?.invoiceIdentifierKeywordsCsv ? true : false}
                  helperText={
                    errors?.invoiceIdentifierKeywordsCsv
                      ? String(errors.invoiceIdentifierKeywordsCsv.message)
                      : 'To add multiple keywords use comma as seperator'
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <FormControlLabel
                  control={
                    <Controller
                      name="fnOEnabled"
                      control={control}
                      render={({ field }): JSX.Element => (
                        <Switch
                          {...field}
                          value={field.value || false}
                          checked={!!field.value}
                          disabled={!canAddOrUpdate}
                          size="small"
                        />
                      )}
                    />
                  }
                  label="FNO Enabled ?"
                  sx={{ ml: 0 }}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <FormControlLabel
                  control={
                    <Controller
                      name="duplicateCheckOverride"
                      control={control}
                      render={({ field }): JSX.Element => (
                        <Switch
                          {...field}
                          value={field.value || false}
                          checked={!!field.value}
                          size="small"
                          color="warning"
                        />
                      )}
                    />
                  }
                  label="Duplicate Check Override ?"
                  sx={{ ml: 0 }}
                />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12}>
                <Typography color="error" variant="small">
                  {!canAddOrUpdate
                    ? '*You can only update "Duplicate Check Override" setting. Contact the administrator to obtain permissions to update all settings.'
                    : ''}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <LoadingButton
                  loading={saving}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                  disabled={saving || !isDirty}
                  loadingIndicator="Saving..."
                  size="small"
                  sx={{
                    width: saving ? '5.625rem' : '4.375rem',
                    mr: 2,
                    mt: 0.5,
                  }}
                >
                  Save
                </LoadingButton>
                <Button
                  variant="contained"
                  disabled={saving || !isDirty}
                  onClick={(): void => {
                    reset(haulerSettingValues)
                    if (onCancel) onCancel()
                  }}
                  size="small"
                  sx={{ mt: 0.5 }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default ConverterHaulerPanelWrapper
