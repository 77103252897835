import {
  every,
  forIn,
  has,
  isArray,
  map,
  omit,
  partial,
  uniqueId,
} from 'lodash'
import {
  FilterStartStopKeywordType,
  InvoiceLineType,
  InvoiceParametersType,
  LookupKeywordType,
  PostInvoiceParametersType,
} from 'types/InvoiceParamters'

const requiredKeys = [
  'vendorName',
  'accountLookupKeywords',
  'invoiceNumberLookupKeywords',
  'invoiceDateLookupKeywords',
  'invoiceTotalLookupKeywords',
  'headerLookupKeywords',
  'footerLookupKeywords',
  'customInvoiceLines',
]

export const updateEditorParams = (
  parameters: InvoiceParametersType
): InvoiceParametersType => {
  if (every(requiredKeys, partial(has, parameters))) {
    return {
      ...parameters,
      accountLookupKeywords: parameters.accountLookupKeywords.map(
        (lookup: LookupKeywordType) => ({
          ...lookup,
          id: uniqueId('ACLU_'),
        })
      ),
      invoiceNumberLookupKeywords: parameters.invoiceNumberLookupKeywords.map(
        (lookup: LookupKeywordType) => ({
          ...lookup,
          id: uniqueId('INLU_'),
        })
      ),
      invoiceDateLookupKeywords: parameters.invoiceDateLookupKeywords.map(
        (lookup: LookupKeywordType) => ({
          ...lookup,
          id: uniqueId('IDLU_'),
        })
      ),
      invoiceTotalLookupKeywords: parameters.invoiceTotalLookupKeywords.map(
        (lookup: LookupKeywordType) => ({
          ...lookup,
          id: uniqueId('ITLU_'),
        })
      ),
      headerLookupKeywords: parameters.headerLookupKeywords.map(
        (lookup: LookupKeywordType) => ({
          ...lookup,
          id: uniqueId('HLU_'),
        })
      ),
      footerLookupKeywords: parameters.footerLookupKeywords.map(
        (lookup: LookupKeywordType) => ({
          ...lookup,
          id: uniqueId('FLU_'),
        })
      ),
      filterAddCellStartStopKeywords:
        parameters.filterAddCellStartStopKeywords.map(
          (filter: FilterStartStopKeywordType) => ({
            ...filter,
            id: uniqueId('FSS_'),
          })
        ),
      customInvoiceLines: parameters.customInvoiceLines.map(
        (invoiceLine: InvoiceLineType) => ({
          ...invoiceLine,
          id: uniqueId('IL_'),
        })
      ),
    }
  } else {
    throw 'Missing paramter object keys'
  }
}

export const postInvoiceParameters = (
  parameters: InvoiceParametersType
): PostInvoiceParametersType => {
  const obj: PostInvoiceParametersType | any = {}
  forIn(parameters, (value, key) => {
    if (isArray(value)) {
      obj[key] = value.length
        ? map(value, function (obj: any) {
            return omit(obj, 'id')
          })
        : []
    } else {
      obj[key] = value
    }
  })

  return obj
}

export const thousandsFormatter = (num: number): string => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const currencyFormatter = (num: number): string => {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
