import { TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useAtom } from 'jotai'
import {
  companyEmaileAtom,
  companyNameAtom,
  companyPhoneAtom,
} from 'stores/invoice'

const Company = (): JSX.Element => {
  const [companyName, setCompanyName] = useAtom(companyNameAtom)
  const [companyEmail, setCompanyEmail] = useAtom(companyEmaileAtom)
  const [companyPhone, setCompanyPhone] = useAtom(companyPhoneAtom)

  return (
    <>
      <TableHead>
        <TableRow
          sx={{ backgroundColor: '#21409A', '&>th': { color: 'white' } }}
        >
          <TableCell align="left" colSpan={7}>
            Company
          </TableCell>
        </TableRow>
        <TableRow
          sx={{ backgroundColor: '#798996', '&>th': { color: 'white' } }}
        >
          <TableCell colSpan={3}>Name</TableCell>
          <TableCell colSpan={2}>Email</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell
            contentEditable
            suppressContentEditableWarning
            onBlur={(event): void => {
              if (event.currentTarget.textContent !== companyName)
                setCompanyName(event.currentTarget.textContent || '')
            }}
            onPaste={(event): void => {
              const data = event.clipboardData.getData('text/plain')
              document.execCommand('insertHTML', false, data)
              event.preventDefault()
            }}
            sx={{
              '&:focus': {
                outlineColor: '#008F6B',
              },
            }}
            colSpan={3}
          >
            {companyName}
          </TableCell>
          <TableCell
            contentEditable
            suppressContentEditableWarning
            onBlur={(event): void => {
              if (event.currentTarget.textContent !== companyEmail)
                setCompanyEmail(event.currentTarget.textContent || '')
            }}
            onPaste={(event): void => {
              const data = event.clipboardData.getData('text/plain')
              document.execCommand('insertHTML', false, data)
              event.preventDefault()
            }}
            sx={{
              '&:focus': {
                outlineColor: '#008F6B',
              },
            }}
            colSpan={2}
          >
            {companyEmail}
          </TableCell>
          <TableCell
            contentEditable
            suppressContentEditableWarning
            onBlur={(event): void => {
              if (event.currentTarget.textContent !== companyPhone)
                setCompanyPhone(event.currentTarget.textContent || '')
            }}
            onPaste={(event): void => {
              const data = event.clipboardData.getData('text/plain')
              document.execCommand('insertHTML', false, data)
              event.preventDefault()
            }}
            sx={{
              '&:focus': {
                outlineColor: '#008F6B',
              },
            }}
          >
            {companyPhone}
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    </>
  )
}

export default Company
