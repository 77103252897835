import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined'
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  LinearProgress,
  Menu,
  Table,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material'
import InvoiceLines from 'components/atoms/InvoiceLines'
import ReCalculateSubTotals from 'components/atoms/ReCalculateSubTotals'
import StartStopFilters from 'components/atoms/StartStopFilters'
import { useAtom } from 'jotai'
import { MouseEvent, useState } from 'react'
import { filterAddCellStartStopAtomsAtom } from 'stores/invoice'

import Company from '../atoms/Company'
import LookupKeywordsWrapper from './LookupKeywordsWrapper'

const ConverterParameterEditorWrapper = ({
  elevation = 0,
  showFullscreen = true,
  loading,
  isValid,
  onFullscreenClick,
}: {
  elevation?: number
  showFullscreen: boolean
  loading: boolean
  isValid: boolean
  onFullscreenClick: (isFullscreen: boolean) => void
}): JSX.Element => {
  const [startStopFilterAtoms] = useAtom(filterAddCellStartStopAtomsAtom)
  const [expanded, setExpanded] = useState<boolean>(true)
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleMenuClick = (event: MouseEvent<HTMLElement>): void => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      {loading ? <LinearProgress sx={{ height: '2px' }} /> : null}
      <Accordion
        elevation={elevation}
        defaultExpanded
        sx={{ height: expanded ? '100%' : 'auto' }}
        square
        disableGutters
        onChange={(e, isExpanded): void => setExpanded(isExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="converterParameterEditor-content"
          id="converterParameterEditor-header"
        >
          <Typography sx={{ flex: 1, alignItems: 'center', display: 'flex' }}>
            Edit Parameters
          </Typography>
          <Tooltip
            title={
              startStopFilterAtoms.length
                ? `Set Stop/Start Filters ( ${startStopFilterAtoms.length} )`
                : 'Set Stop/Start Filters'
            }
            placement="left"
            arrow
          >
            <IconButton
              onClick={handleMenuClick}
              size="small"
              aria-controls={open ? 'start-stop-filters-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{ p: 0, mr: 1 }}
            >
              <FilterAltIcon
                color={startStopFilterAtoms.length ? 'primary' : 'warning'}
              />
            </IconButton>
          </Tooltip>

          {showFullscreen ? (
            <Box sx={{ display: 'flex', mr: 1, color: 'rgba(0, 0, 0, 0.54)' }}>
              {isFullscreen ? (
                <Tooltip
                  title="Click to view split view"
                  placement="left"
                  arrow
                >
                  <FullscreenExitOutlinedIcon
                    onClick={(event): void => {
                      event.stopPropagation()
                      setIsFullscreen(false)
                      onFullscreenClick(false)
                    }}
                    sx={{ color: 'inherit' }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Click to view full view" placement="left" arrow>
                  <FullscreenOutlinedIcon
                    onClick={(event): void => {
                      event.stopPropagation()
                      setIsFullscreen(true)
                      onFullscreenClick(true)
                    }}
                    sx={{ color: 'inherit' }}
                  />
                </Tooltip>
              )}
            </Box>
          ) : null}
        </AccordionSummary>
        <AccordionDetails>
          {!loading && isValid ? (
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
              <Menu
                anchorEl={anchorEl}
                id="start-stop-filters-menu"
                open={open}
                onClose={handleMenuClose}
                onClick={(event): void => event.stopPropagation()}
                MenuListProps={{
                  sx: {
                    m: 1,
                    p: 0,
                  },
                }}
                PaperProps={{
                  elevation: 5,
                  sx: {
                    maxWidth: '650px',
                    overflow: 'visible',
                    mt: 1,
                    boxShadow: '0 2px 7px 3px rgb(133 133 133 / 50%)',
                    borderRadius: 0,
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <TableContainer
                  sx={{ width: '100%' }}
                  onKeyDown={(event): void => event.stopPropagation()}
                >
                  <Table size="small">
                    <StartStopFilters />
                  </Table>
                </TableContainer>
              </Menu>

              <TableContainer sx={{ width: '100%', height: '71vh' }}>
                <Table size="small">
                  <Company />
                  <LookupKeywordsWrapper />
                  <InvoiceLines />
                  <ReCalculateSubTotals />
                </Table>
              </TableContainer>
            </Box>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default ConverterParameterEditorWrapper
