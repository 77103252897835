import { AxiosResponse } from 'axios'
import { parametersInit } from 'data/invoice'
import { atom } from 'jotai'
import { focusAtom } from 'jotai/optics'
import { atomWithDefault, splitAtom } from 'jotai/utils'
import { OpticFor } from 'optics-ts'
import AppService from 'services'
import { InvoiceDetailsType, InvoiceType } from 'types/InvoiceDetails'
import {
  ActiveViewActionType,
  ActiveViewType,
  InvoiceParametersType,
  PostInvoiceParametersType,
} from 'types/InvoiceParamters'
import { VendorType } from 'types/Vendor'
import { updateEditorParams } from 'utils'

export const selectedVendorAtom = atom<VendorType | null>(null)
export const savedInvoiceGridFilterModelAtom = atom<any>(null)

export const getInvoiceList = async (): Promise<
  AxiosResponse<InvoiceType[], any>
> => {
  try {
    return await AppService.Invoice.getData('invoiceList', {}, true)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getInvoiceListByVendorName = async (
  vendorName: string
): Promise<AxiosResponse<InvoiceType[], any>> => {
  try {
    return await AppService.Invoice.getData(
      'invoiceListByVendorName',
      { vendorName },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getInvoiceDetails = async (
  id: string
): Promise<AxiosResponse<InvoiceDetailsType, any>> => {
  try {
    return await AppService.Invoice.getData('invoiceDetails', { id }, true)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateInvoiceDetails = async (
  invoiceDetails: Omit<InvoiceDetailsType, 'groupedInvoiceLines'>
): Promise<AxiosResponse<any, any>> => {
  try {
    return await AppService.Invoice.saveData('invoiceDetails', invoiceDetails)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const uploadInvoice = async (
  vendorName: string,
  fileName: string,
  formData: FormData
): Promise<AxiosResponse<any, any>> => {
  try {
    return await AppService.Vendor.saveData('uploadInvoice', {
      vendorName,
      fileName,
      formData,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

// ------------------ Invoice parameter editor ----------------------
export const getConversionParametersByVendor = async (
  vendorName: string
): Promise<AxiosResponse<InvoiceParametersType | null, any>> => {
  try {
    return await AppService.Invoice.getData(
      'conversionParametersByVendor',
      { vendorName },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getDefaultConverterParameters = async (
  vendorName: string
): Promise<AxiosResponse<InvoiceParametersType, any>> => {
  try {
    return await AppService.Invoice.getData(
      'defaultConverterParameters',
      { vendorName },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const saveConversionParameters = async (
  parameters: PostInvoiceParametersType,
  isDefaultParams = false
): Promise<AxiosResponse<string, any>> => {
  if ((isDefaultParams && parameters.vendorName) || parameters.id) {
    try {
      return await AppService.Invoice.saveData(
        'saveConversionParameters',
        parameters
      )
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return Promise.reject(new Error('Vendor name is required.'))
}

export const callMappingOptions = [
  { name: 'Unknown', value: 0 },
  { name: 'Date', value: 1 },
  { name: 'RefNumber', value: 2 },
  { name: 'Po', value: 3 },
  { name: 'Description', value: 4 },
  { name: 'Units', value: 5 },
  { name: 'UnitPrice', value: 6 },
  { name: 'LinePrice', value: 7 },
]

export const parametersAtom = atomWithDefault<InvoiceParametersType>(() =>
  updateEditorParams(parametersInit)
)

// Company
export const companyAtom = focusAtom(parametersAtom, optic =>
  optic.prop('company')
)
export const companyNameAtom = focusAtom(companyAtom, optic =>
  optic.prop('companyName')
)
export const companyEmaileAtom = focusAtom(companyAtom, optic =>
  optic.prop('companyEmail')
)
export const companyPhoneAtom = focusAtom(companyAtom, optic =>
  optic.prop('companyPhone')
)

// Lookups
export const accountLookupAtom = focusAtom(parametersAtom, optic =>
  optic.prop('accountLookupKeywords')
)
export const accountLookupAtomsAtom = splitAtom(accountLookupAtom)

export const invoiceNumberLookupAtom = focusAtom(parametersAtom, optic =>
  optic.prop('invoiceNumberLookupKeywords')
)
export const invoiceNumberLookupAtomsAtom = splitAtom(invoiceNumberLookupAtom)

export const invoiceDateLookupAtom = focusAtom(parametersAtom, optic =>
  optic.prop('invoiceDateLookupKeywords')
)
export const invoiceDateLookupAtomsAtom = splitAtom(invoiceDateLookupAtom)

export const invoiceTotalLookupAtom = focusAtom(parametersAtom, optic =>
  optic.prop('invoiceTotalLookupKeywords')
)
export const invoiceTotalLookupAtomsAtom = splitAtom(invoiceTotalLookupAtom)

export const headerLookupAtom = focusAtom(parametersAtom, optic =>
  optic.prop('headerLookupKeywords')
)
export const headerLookupAtomsAtom = splitAtom(headerLookupAtom)

export const footerLookupAtom = focusAtom(parametersAtom, optic =>
  optic.prop('footerLookupKeywords')
)
export const footerLookupAtomsAtom = splitAtom(footerLookupAtom)

// Filter
export const filterAddCellStartStopAtom = focusAtom(parametersAtom, optic =>
  optic.prop('filterAddCellStartStopKeywords')
)
export const filterAddCellStartStopAtomsAtom = splitAtom(
  filterAddCellStartStopAtom
)

// Invoice lines
export const customInvoiceLinesAtom = focusAtom(parametersAtom, optic =>
  optic.prop('customInvoiceLines')
)
export const customInvoiceLinesAtomsAtom = splitAtom(customInvoiceLinesAtom)

//Sub total
export const recalCulateSubTotalsAtom = focusAtom(parametersAtom, optic =>
  optic.prop('recalculateSubTotals')
)

// ------------------ Invoice active view ----------------------
export const activeViewActionAtom = atomWithDefault<ActiveViewActionType>(
  () => ({
    companyInfo: false,
    invoiceAccountSummaryHeader: false,
    unfilteredInvoicePageCells: false,
    filteredInvoicePageCells: false,
    headerIndexesList: false,
    footerIndexesList: false,
    siteHeaders: false,
    siteFooters: false,
    lineItems: false,
    errorList: false,
  })
)

export const getActiveView = async (
  vendorName: string,
  invoiceFileName: string
): Promise<AxiosResponse<ActiveViewType, any>> => {
  try {
    return await AppService.Invoice.getData(
      'invoiceConverterActiveView',
      { vendorName, invoiceFileName },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

// --------------------- Invoice details --------------
export const invoiceDetailsAtom = atomWithDefault<InvoiceDetailsType>(() => ({
  id: '',
  invoiceVendorName: '',
  companyName: '',
  companyEmail: '',
  companyPhone: '',
  accountNumber: '',
  invoiceDate: '',
  invoiceNumber: '',
  invoiceTotal: '',
  chargesCredits: '',
  acctBalance: '',
  invoiceTotalD: 0,
  chargesCreditsD: 0,
  acctBalanceD: 0,
  createdAt: '',
  createdBy: '',
  updatedAt: new Date(),
  updatedBy: '',
  invoiceLines: [],
  groupedInvoiceLines: [],
  debugInfoList: [],
  rawInvoiceRows: [],
  fileName: '',
  fileUri: '',
  haulerId: '',
}))

export const groupedInvoiceLinesAtom = focusAtom(invoiceDetailsAtom, optic =>
  optic.prop('groupedInvoiceLines')
)

export const groupedInvoiceLinesAtomsAtom = splitAtom(groupedInvoiceLinesAtom)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const focusInvoiceLines = (
  optic: OpticFor<InvoiceDetailsType['groupedInvoiceLines'][number]>
) => optic.prop('invoiceLines')

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const focusInvoiceSubTotalLines = (
  optic: OpticFor<InvoiceDetailsType['groupedInvoiceLines'][number]>
) => optic.prop('subTotalLine')
