import { useAuth0 } from '@auth0/auth0-react'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import EmailIcon from '@mui/icons-material/Email'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import TimelineIcon from '@mui/icons-material/Timeline'
import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material'
import {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { ReactComponent as SharepointIcon } from 'assets/img/sharepoint.svg'
import BarChart from 'components/atoms/BarChart'
import DatePresetPicker from 'components/atoms/DatePresetPicker'
import SlideDialog from 'components/atoms/SlideDialog'
import TimeLine from 'components/atoms/TimeLine'
import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import Loading from 'components/molecules/Loading'
import { format, toDate } from 'date-fns-tz'
import { useAtom } from 'jotai'
import { isEmpty, uniqBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller, useForm } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import { alertAtom } from 'stores'
import {
  getInvoiceStatusList,
  invoiceStatusDetailsById,
} from 'stores/apDashboard'
import { accessTokenAtom } from 'stores/auth'
import { getVendorNamesList } from 'stores/vendor'
import 'styles/datepicker.scss'
import theme from 'theme'
import { vendorNameListType } from 'types/Vendor'
import {
  ApDashboardFilters,
  InvoiceListFilterParamsType,
  InvoiceStatusDetailsType,
  InvoiceStatusListType,
} from 'types/apDashboard'
import {
  booleanCell,
  dateFilterParams,
  dateFormatter,
  ternaryCell,
} from 'utils/ag-grid'
import boolFilter from 'utils/boolFilter'

const Process = (): JSX.Element => {
  const [accessToken] = useAtom(accessTokenAtom)
  const gridRef = useRef<AgGridReact<InvoiceStatusListType>>(null)
  const { user } = useAuth0()
  const [, setAlert] = useAtom(alertAtom)
  const [loadingInvoiceList, setLoadingInvoiceList] = useState(false)
  const [invoiceListData, setInvoiceListData] = useState<
    InvoiceStatusListType[]
  >([])
  const [hasGridFilter, setHasGridFilter] = useState(false)
  const [loadingVendors, setLoadingVendors] = useState(false)
  const [selectedVendor, setVendor] = useState<vendorNameListType | null>(null)

  const [vendorsList, setVendorsList] = useState<vendorNameListType[]>([])
  const [inputValue, setInputValue] = useState('')
  const [chartData, setChartData] = useState<any[]>([])

  const [qualityScore, setQualityScore] = useState<number>(0)

  const [loadingRowDetails, setLoadingRowDetails] = useState(false)
  const [selectedInvoiceRowDetails, setSelectedInvoiceRowDetails] =
    useState<InvoiceStatusDetailsType | null>(null)

  const [selectedDateOption, setSelectedDateOption] = useState<{
    value: string
    label: string
  } | null>(null)

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 15, 20, 25]
  }, [])

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<ApDashboardFilters>({
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
      fileName: '',
      invoiceNumber: '',
      vendor: '',
      // fromToDate: [dateRange[0], dateRange[1]],
    },
  })

  const propertiesToChart: {
    property: string
    displayValue: string
  }[] = [
    { property: 'noDuplicate', displayValue: 'Duplicates' },
    { property: 'invoiceConverted', displayValue: 'Converted' },
    { property: 'varianceChecked', displayValue: 'Variance Checked' },
    { property: 'fnOHeaderCreated', displayValue: 'FNO Header Created' },
    { property: 'fnOAttachmentSent', displayValue: 'FNO Attachment Sent' },
    { property: 'fnOLineItemsSent', displayValue: 'FNO Line Items Sent' },
    { property: 'invoiceFinalSuccess', displayValue: 'Result' },
  ]

  const getInvoiceListFilters = (
    data: ApDashboardFilters = getValues()
  ): InvoiceListFilterParamsType => {
    return {
      fileName: data.fileName || undefined,
      invoiceNumber: data.invoiceNumber || undefined,
      vendor: data.vendor || undefined,
      fromDate:
        data.fromDate && data.toDate
          ? format(toDate(data.fromDate, { timeZone: 'Etc/UTC' }), 'LL/dd/yyyy')
          : undefined,
      toDate: data.toDate
        ? format(toDate(data.toDate, { timeZone: 'Etc/UTC' }), 'LL/dd/yyyy')
        : undefined,
    }
  }

  const onSubmit = handleSubmit(data => {
    getInvoiceList(getInvoiceListFilters(data))
  })

  const getVendorList = (): void => {
    setLoadingVendors(true)
    getVendorNamesList()
      .then(response => {
        if (response.data) {
          const filteredResponse = uniqBy(response.data, function (obj) {
            return obj.longName
          })
          setVendorsList(filteredResponse)
        }
        setLoadingVendors(false)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to load vendors list',
          autoHideDuration: 2000,
        })
        setLoadingVendors(false)
      })
  }

  const getFnoPostStatus = (
    statusValue: '' | 'InvoicePending' | 'InvoicePosted' | 'InvoicePaid',
    invoiceFinalSuccess: boolean
  ): 'Pending' | 'Paid' | 'Posted' | 'In Progress' | 'Failed' => {
    switch (statusValue) {
      case 'InvoicePending':
        return 'Pending'
        break
      case 'InvoicePosted':
        return 'Posted'
        break
      case 'InvoicePaid':
        return 'Paid'
      default:
        return invoiceFinalSuccess ? 'In Progress' : 'Failed'
    }
  }

  const getInvoiceList = (params?: InvoiceListFilterParamsType): void => {
    setLoadingInvoiceList(true)
    getInvoiceStatusList(params)
      .then(response => {
        if (response.data) {
          const result = response.data.map(obj => ({
            ...obj,
            noDuplicate: !obj.duplicateFound,
            sentToKofaxYN: obj.sentToKofax ? 'Yes' : 'No',
            fnoPostStatusUpdated: getFnoPostStatus(
              obj.fnoPostStatus,
              obj.invoiceFinalSuccess
            ),
          }))

          setInvoiceListData(result)

          const noDuplicateResult = result.filter(
            invoice => !invoice.duplicateFound
          )

          const countedProperties = propertiesToChart.reduce(
            (
              counts: {
                id: string
                label: string
                passed: number
                failed: number
                pattern: string
              }[],
              propertyItem
            ) => {
              const resultCounts = noDuplicateResult.reduce(
                (acc: any, item) => {
                  if (
                    item[propertyItem.property as keyof InvoiceStatusListType]
                  ) {
                    acc.passed = (acc.passed || 0) + 1
                  } else {
                    acc.failed = (acc.failed || 0) + 1
                  }
                  return acc
                },
                {}
              )

              counts.push({
                id: propertyItem.property,
                label: propertyItem.displayValue,
                pattern: propertyItem.property,
                passed: resultCounts.passed || 0,
                failed: resultCounts.failed || 0,
              })

              return counts
            },
            []
          )

          setChartData(countedProperties)

          const resultCounts = countedProperties.find(
            property => property.id === 'invoiceFinalSuccess'
          )

          const total = resultCounts
            ? resultCounts.passed
              ? (resultCounts.passed / noDuplicateResult.length) * 100
              : 0
            : 0

          setQualityScore(Math.round((total + Number.EPSILON) * 100) / 100)
        }

        setLoadingInvoiceList(false)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to load Invoice list',
          autoHideDuration: 2000,
        })
        setLoadingInvoiceList(false)
      })
  }

  useEffect(() => {
    if (accessToken) {
      // load data for filters and default invoice list
      getVendorList()
      getInvoiceList(getInvoiceListFilters())

      const getInvoiceListTimer = setInterval(
        () => getInvoiceList(getInvoiceListFilters()),
        120000
      )
      return () => clearInterval(getInvoiceListTimer)
    }
  }, [accessToken])

  const cellStyle = (params: CellClassParams): any => {
    return {
      backgroundColor: params.value ? '#0080001a' : '#ff00001a',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }

  const fnoStatusCellStyle = (params: CellClassParams): any => {
    let backgroundColor = undefined
    switch (params.value) {
      case 'Pending':
        backgroundColor = '#FBA3001a'
        break
      case 'Posted':
        backgroundColor = '#00fff23d'
        break
      case 'Paid':
        backgroundColor = '#0080001a'
        break
      default:
      case 'In Progress':
        backgroundColor = '#0094fb1a'
        break
      case 'Failed':
        backgroundColor = '#ff00001a'
        break
    }

    return {
      backgroundColor: backgroundColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }

  const resultCellStyle = (params: CellClassParams): any => {
    return {
      backgroundColor: params.value
        ? params.data.isWithNotes
          ? '#FBA3001a'
          : '#0080001a'
        : '#ff00001a',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'createdDateTime',
      headerName: 'Date/Time Received',
      valueFormatter: dateFormatter,
      filter: 'date',
      width: 175,
      filterParams: dateFilterParams,
      sort: 'desc',
    },
    {
      field: 'invoiceNumber',
      width: 150,
    },
    {
      field: 'invoiceTotal',
      headerName: 'Invoice Amount',
      width: 150,
      valueFormatter: (params: ValueFormatterParams): string => {
        return '$' + Math.floor(params.value).toLocaleString()
      },
    },
    {
      field: 'originalFileName',
      headerName: 'File Name',
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <Box sx={{ display: 'flex' }}>
            <Tooltip title={params.value} placement="right" arrow>
              {params.data.blobUrl ? (
                <NavLink
                  aria-label="invoice Number"
                  target="_blank"
                  to={params.data.blobUrl}
                  style={{
                    width: 'calc(100% + 1rem)',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'block',
                  }}
                >
                  {params.value}
                </NavLink>
              ) : (
                <span
                  style={{
                    width: 'calc(100% + 1rem)',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'block',
                  }}
                >
                  {params.data.fileName ? params.data.fileName : params.value}
                </span>
              )}
            </Tooltip>
          </Box>
        )
      },
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'sourceType',
      headerName: 'Source',
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        const sourceType = params.value?.toLowerCase()

        return (
          <Box sx={{ display: 'flex' }}>
            {sourceType === 'sharepoint' ? (
              <SvgIcon fontSize="large" color="primary">
                <SharepointIcon />
              </SvgIcon>
            ) : sourceType === 'email' ? (
              <EmailIcon fontSize="small" color="primary" />
            ) : (
              ''
            )}
          </Box>
        )
      },
      width: 120,
      sortable: false,
    },
    {
      field: 'haulerId',
      width: 120,
    },
    {
      field: 'haulerName',
      width: 120,
    },
    {
      field: 'sourceValue',
      headerName: 'Source Value',
      width: 120,
    },
    {
      field: 'sourceValue',
      headerName: 'Source Value',
      width: 120,
    },
    {
      field: 'noDuplicate',
      width: 132,
      cellRenderer: booleanCell,
      cellStyle: cellStyle,
      filter: boolFilter,
      tooltipValueGetter: params =>
        dateTimeFormat(params.data.duplicateCheckedDateTime),
    },
    {
      field: 'invoiceConverted',
      headerName: 'Converter',
      cellStyle: cellStyle,
      width: 114,
      cellRenderer: booleanCell,
      filter: boolFilter,
      tooltipValueGetter: params =>
        dateTimeFormat(params.data.invoiceConvertedDateTime),
    },
    {
      field: 'varianceChecked',
      headerName: 'Variance Check',
      cellStyle: cellStyle,
      width: 132,
      cellRenderer: booleanCell,
      filter: boolFilter,
      tooltipValueGetter: params =>
        dateTimeFormat(params.data.varianceCheckedDateTime),
    },
    {
      field: 'sentToKofaxYN',
      headerName: 'Sent To Kofax',
      cellStyle: cellStyle,
      width: 130,
      tooltipValueGetter: params =>
        dateTimeFormat(params.data.sentKofaxDateTime),
    },
    {
      field: 'fnOHeaderCreated',
      headerName: 'F+O Header',
      cellStyle: cellStyle,
      width: 130,
      cellRenderer: booleanCell,
      filter: boolFilter,
      tooltipValueGetter: params =>
        dateTimeFormat(params.data.fnOHeaderCreatedDateTime),
    },
    {
      field: 'fnOAttachmentSent',
      headerName: 'F+O Attachments',
      cellStyle: cellStyle,
      width: 130,
      cellRenderer: booleanCell,
      filter: boolFilter,
      tooltipValueGetter: params =>
        dateTimeFormat(params.data.fnOAttachmentSentDateTime),
    },
    // {
    //   field: 'fnOIsOnHold',
    //   headerName: 'F+O is on Hold',
    //   cellStyle: cellStyle,
    //   width: 130,
    //   cellRenderer: booleanCell,
    //   filter: boolFilter,
    // },
    {
      field: 'fnODataAreaId',
      headerName: 'F+O Data Area Id',
      width: 130,
      pinned: 'right',
    },
    {
      field: 'fnoPostStatusUpdated',
      headerName: 'F+O Status',
      cellStyle: fnoStatusCellStyle,
      width: 130,
      tooltipValueGetter: params =>
        dateTimeFormat(params.data.fnoPostStatusDateTime),
      pinned: 'right',
    },
    {
      field: 'invoiceFinalSuccess',
      headerName: 'Result',
      cellStyle: resultCellStyle,
      width: 100,
      cellRenderer: ternaryCell,
      filter: boolFilter,
      pinned: 'right',
    },
    // {
    //   field: 'ticketNumber',
    //   headerName: 'Ticket Number',
    // },
  ])

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
    }
  }, [])

  const filterChanged = (): void => {
    const filterModel = gridRef.current?.api.getFilterModel()
    if (!isEmpty(filterModel)) {
      setHasGridFilter(true)
    }

    // console.log('### api.forEachNodeAfterFilterAndSort() ###')
    // gridRef.current?.api.forEachNodeAfterFilterAndSort(updateRow)
  }

  const onBtnExport = useCallback(() => {
    gridRef.current?.api.exportDataAsCsv({
      fileName: `Theta_invoices_${format(new Date(), 'MM-dd-yyyy_hh-mm')}`,
    })
  }, [])

  const handleDatePickerChange = (
    startDate: Date | null,
    endDate: Date | null,
    selectedDateOption: {
      value: string
      label: string
    } | null
  ): void => {
    setSelectedDateOption(selectedDateOption)
    setValue('fromDate', startDate, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    })

    setValue('toDate', endDate, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    })
  }

  const handleVendorChange = (
    selectedValue: vendorNameListType | null
  ): void => {
    setVendor(selectedValue)
  }

  const handleRowClick = (params: CellClickedEvent): void => {
    if (params.data.id) {
      setLoadingRowDetails(true)
      invoiceStatusDetailsById({ id: params.data.id })
        .then(response => {
          if (response.data) {
            setSelectedInvoiceRowDetails(response.data)
            setLoadingRowDetails(false)
          }
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to load invoice list',
            autoHideDuration: 2000,
          })
          setLoadingRowDetails(false)
        })
    }
  }

  const handleTimeLineDialogClose = (): void => {
    setSelectedInvoiceRowDetails(null)
  }

  const dateTimeFormat = (value: string | undefined): string => {
    if (value) {
      return format(toDate(value, { timeZone: 'Etc/UTC' }), 'Pp')
    } else {
      return ''
    }
  }

  const clearFilters = useCallback(() => {
    gridRef?.current?.api.resetColumnState()
    gridRef?.current?.api.setFilterModel(null)
    setHasGridFilter(false)
  }, [])

  return user ? (
    <AdminPageWrapper pageTitle="Dashboard">
      <SlideDialog
        open={selectedInvoiceRowDetails ? true : false}
        onClose={handleTimeLineDialogClose}
        title={
          selectedInvoiceRowDetails?.invoiceNumber
            ? `Invoice Details for ${selectedInvoiceRowDetails?.invoiceNumber}`
            : 'Invoice Details'
        }
        maxWidth="sm"
      >
        {selectedInvoiceRowDetails ? (
          <TimeLine data={selectedInvoiceRowDetails} />
        ) : (
          'No data'
        )}
      </SlideDialog>

      <Box id="dashboard-container">
        <Accordion defaultExpanded square id="filter-wrapper">
          {loadingVendors ? <LinearProgress sx={{ height: '2px' }} /> : null}
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="filter-content"
            id="filter-header"
          >
            <FilterAltIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography>Filters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md="auto">
                <DatePresetPicker
                  onChange={handleDatePickerChange}
                  selectedDatePickerOption={selectedDateOption}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <Controller
                  control={control}
                  name="vendor"
                  render={({ field }): JSX.Element => (
                    <Autocomplete
                      id="select-vendor"
                      value={selectedVendor}
                      inputValue={inputValue}
                      options={vendorsList}
                      getOptionLabel={(option): string => option.longName}
                      renderInput={(params): JSX.Element => (
                        <TextField {...params} label="Select Vendor" />
                      )}
                      isOptionEqualToValue={(option, value): boolean =>
                        option.id === value.id
                      }
                      onChange={(
                        event: any,
                        newValue: vendorNameListType | null
                      ): void => {
                        field.onChange(newValue?.name)
                        handleVendorChange(newValue)
                      }}
                      onInputChange={(event, newInputValue): void => {
                        setInputValue(newInputValue)
                      }}
                      size="small"
                      disabled={loadingVendors}
                      sx={{ minWidth: '15.625rem' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                  fullWidth
                  label="File Name"
                  size="small"
                  {...register('fileName')}
                  placeholder="File Name"
                  error={errors?.fileName ? true : false}
                  helperText={
                    errors?.fileName ? String(errors.fileName.message) : ''
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                  fullWidth
                  label="Invoice Number"
                  size="small"
                  {...register('invoiceNumber')}
                  placeholder="Invoice Number"
                  error={errors?.invoiceNumber ? true : false}
                  helperText={
                    errors?.invoiceNumber
                      ? String(errors.invoiceNumber.message)
                      : ''
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  loading={loadingInvoiceList}
                  variant="contained"
                  onClick={onSubmit}
                  disabled={loadingInvoiceList || !isDirty}
                  loadingIndicator="Updating..."
                  size="small"
                  sx={{
                    width: loadingInvoiceList ? '5.625rem' : '4.375rem',
                    mr: 2,
                    mt: 0.5,
                  }}
                >
                  Apply
                </LoadingButton>
                <Button
                  variant="contained"
                  disabled={loadingInvoiceList || !isDirty}
                  onClick={(): void => {
                    reset()
                    handleDatePickerChange(new Date(), new Date(), null)
                    //TODO: call getinvoices if params is different
                    getInvoiceList(getInvoiceListFilters())
                    setVendor(null)
                  }}
                  size="small"
                  sx={{ mt: 0.5 }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={false} square id="visual-wrapper">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="visual-content"
            id="visual-header"
          >
            <AutoGraphIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography sx={{ mr: 4, flexShrink: 0 }}>Analytics</Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              {`${qualityScore || 0}% Quality Score`}
              <LinearProgress
                variant="determinate"
                value={qualityScore}
                sx={{ height: '8' }}
                color={
                  qualityScore <= 30
                    ? 'error'
                    : qualityScore > 30 && qualityScore <= 75
                    ? 'warning'
                    : 'success'
                }
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <BarChart
                  data={chartData}
                  loading={loadingInvoiceList}
                  maxWidth="100%"
                  keys={['passed', 'failed']}
                  xLabel="Events"
                  yLabel="Invoice Count"
                  colorSchemeId="dark2"
                  boxShadow={0}
                  showLegends={true}
                  margin={{ top: 20, right: 0, bottom: 40, left: 50 }}
                  fill={[
                    {
                      match: {
                        id: 'passed',
                      },
                      id: 'dots',
                    },
                    {
                      match: {
                        id: 'failed',
                      },
                      id: 'lines',
                    },
                  ]}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'rgba(0, 128, 0, 0.2)',
                      color: '#00644a',
                      size: 0,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: 'lines',
                      type: 'patternDots',
                      background: 'rgba(255, 0, 0, 0.2)',
                      color: 'red',
                      size: 0,
                      padding: 1,
                      stagger: true,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded square id="grid-wrapper">
          {loadingInvoiceList ? (
            <LinearProgress sx={{ height: '2px' }} />
          ) : null}
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="grid-content"
            id="grid-header"
          >
            <TimelineIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography>Invoice List</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ position: 'relative' }}>
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: 1,
                position: 'absolute',
                backgroundColor: alpha(theme.palette.background.paper, 0.3),
                cursor: 'not-allowed',
              }}
              open={loadingRowDetails}
            >
              <CircularProgress color="primary" />
            </Backdrop>
            <Box sx={{ display: 'flex', justifyContent: 'end', mb: 1 }}>
              <Button
                variant="outlined"
                onClick={onBtnExport}
                startIcon={<FileDownloadIcon />}
              >
                Export as CSV
              </Button>
              {hasGridFilter ? (
                <Button
                  variant="outlined"
                  onClick={clearFilters}
                  endIcon={<FilterAltOffIcon />}
                  sx={{ ml: 1 }}
                >
                  Reset
                </Button>
              ) : null}
            </Box>
            <Box className="ag-theme-alpine" width="100%" sx={{ height: 530 }}>
              <AgGridReact
                ref={gridRef}
                rowData={invoiceListData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onRowClicked={handleRowClick}
                enableCellChangeFlash={true}
                animateRows={true}
                pagination={true}
                suppressCellFocus={true}
                paginationPageSize={10}
                paginationPageSizeSelector={paginationPageSizeSelector}
                onFilterChanged={filterChanged}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                domLayout="autoHeight"
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </AdminPageWrapper>
  ) : (
    <Loading />
  )
}

export default Process
