import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from 'components/molecules/Loading'
import React, { ComponentType } from 'react'

interface AuthenticationGuardProps {
  component: ComponentType
}

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  component,
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="invoice-automation-page-wrapper">
        <Loading />
      </div>
    ),
  })

  return <Component />
}
