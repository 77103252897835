import { AxiosResponse } from 'axios'
import { InvoiceDetailsType, InvoiceType } from 'types/InvoiceDetails'
import {
  ActiveViewType,
  InvoiceParametersType,
  PostInvoiceParametersType,
} from 'types/InvoiceParamters'

import { axiosInstance } from '.'
import AppService from './appService'

const values = {}

const getters = {
  invoiceList(): Promise<AxiosResponse<InvoiceType[], any>> {
    return axiosInstance.get(`/invoices`)
  },

  invoiceListByVendorName(params: {
    vendorName: string
  }): Promise<AxiosResponse<InvoiceType[], any>> {
    return axiosInstance.get(`/invoices/vendor/${params.vendorName}`)
  },

  invoiceDetails(params: {
    id: string
  }): Promise<AxiosResponse<InvoiceDetailsType, any>> {
    return axiosInstance.get(`/invoices/${params.id}`)
  },

  defaultConverterParameters(params: {
    vendorName: string
  }): Promise<AxiosResponse<InvoiceParametersType, any>> {
    return axiosInstance.get(`/defaultparameters/${params.vendorName}`)
  },

  conversionParametersByVendor(params: {
    vendorName: string
  }): Promise<AxiosResponse<InvoiceParametersType | null, any>> {
    return axiosInstance.get(`/conversionparameters/${params.vendorName}`)
  },

  invoiceConverterActiveView(params: {
    vendorName: string
    invoiceFileName: string
  }): Promise<AxiosResponse<ActiveViewType, any>> {
    return axiosInstance.get(
      `/converter/${params.vendorName}/${params.invoiceFileName}`
    )
  },
}

const saves = {
  invoiceDetails(
    invoiceDetails: InvoiceDetailsType
  ): Promise<AxiosResponse<InvoiceDetailsType, any>> {
    return axiosInstance.put('/invoices', invoiceDetails)
  },

  saveConversionParameters(
    parameters: PostInvoiceParametersType
  ): Promise<AxiosResponse<string, any>> {
    if (parameters.id && parameters.id !== '') {
      return axiosInstance.put('/conversionparameters', parameters)
    } else {
      //defaultParameters
      return axiosInstance.post('/conversionparameters', parameters)
    }
  },
}

const deletes = {}

export default class InvoiceService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
