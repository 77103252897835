import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { Box, Card, CardContent, Typography } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionActions from '@mui/material/AccordionActions'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import AlertStack from 'components/molecules/AlertStack'
import InvoiceDetailsHeader from 'components/molecules/InvoiceDetailsHeader'
import InvoiceDetailsInvoiceLines from 'components/molecules/InvoiceDetailsInvoiceLines'
import InvoiceDetailsRawInvoiceLines from 'components/molecules/InvoiceDetailsRawInvoiceLines'
import Loading from 'components/molecules/Loading'
import { useAtom } from 'jotai'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { alertAtom } from 'stores'
import { accessTokenAtom } from 'stores/auth'
import {
  getInvoiceDetails,
  groupedInvoiceLinesAtom,
  groupedInvoiceLinesAtomsAtom,
  invoiceDetailsAtom,
  updateInvoiceDetails,
} from 'stores/invoice'
import { InvoiceLineType, RawInvoiceRow } from 'types/InvoiceDetails'
import { currencyFormatter } from 'utils'

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: '2.1875rem',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.dark
      : theme.palette.secondary.main,
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'white',
    '&.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    justifyContent: 'space-between',
  },
  color: theme.palette.common.white,
  textTransform: 'uppercase',
  fontSize: '0.75rem',
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export const AccordionActions = styled(MuiAccordionActions)(() => ({
  fontWeight: 'bold',
  fontSize: '0.8rem',
  display: 'flex',
  justifyContent: 'space-between',
}))

const Details = (): JSX.Element => {
  const [accessToken] = useAtom(accessTokenAtom)
  const [, setAlert] = useAtom(alertAtom)
  const { id } = useParams()
  const [invoiceDetails, setInvoiceDetails] = useAtom(invoiceDetailsAtom)

  const [groupedInvoiceLinesAtoms] = useAtom(groupedInvoiceLinesAtomsAtom)
  const [groupedInvoiceLines] = useAtom(groupedInvoiceLinesAtom)

  const [rawInvoiceLines, setRawInvoiceLines] = useState<RawInvoiceRow[]>([])

  const [invoiceDetailsLoading, setInvoiceDetailsLoading] = useState(false)
  const [invoiceDetailsSaving, setInvoiceDetailsSaving] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    if (accessToken) {
      if (id) {
        setInvoiceDetailsLoading(true)
        getInvoiceDetails(id)
          .then(response => {
            if (response && response.data) {
              // InvoiceRows
              const invoiceResponse = {
                ...response.data,
                groupedInvoiceLines:
                  response.data && response.data.invoiceLines.length
                    ? _(response.data.invoiceLines)
                        .filter(x => x.linePriceD > 0 || x.subtotalSumD > 0)
                        .groupBy(x => x.accountNumber)
                        .map((values: InvoiceLineType[], key) => {
                          const headerLine: InvoiceLineType = _.first(
                            values.filter(
                              value =>
                                value.header !== null || value.header !== ''
                            )
                          ) || {
                            tableNum: 0,
                            lineNum: 0,
                            cellNum: 0,
                            accountNumber: key,
                            header: null,
                            date: null,
                            refNumber: null,
                            po: null,
                            description: '',
                            units: null,
                            unitPrice: null,
                            linePrice: null,
                            subtotalSum: null,
                            unitsD: 0,
                            unitPriceD: 0,
                            linePriceD: 0,
                            subtotalSumD: 0,
                            accountLookupString: '',
                            serviceAccount: null,
                          }

                          const filteredInvoiceLines = values.filter(value => {
                            // exclude the subtotal lines as line itesm wont have subtotals
                            if (
                              value.subtotalSumD !== 0 ||
                              value.subtotalSumD !== 0.0
                            ) {
                              return false
                            }
                            if (value.header !== null || value.header !== '') {
                              return true
                            }

                            return true
                          })

                          const subTotalLine: InvoiceLineType = _.last(
                            values.filter(
                              value =>
                                value.subtotalSumD !== 0 ||
                                value.subtotalSumD !== 0.0
                            )
                          ) || {
                            tableNum: 0,
                            lineNum: 0,
                            cellNum: 0,
                            accountNumber: key,
                            header: null,
                            date: null,
                            refNumber: null,
                            po: null,
                            description: '',
                            units: null,
                            unitPrice: null,
                            linePrice: null,
                            subtotalSum: null,
                            unitsD: 0,
                            unitPriceD: 0,
                            linePriceD: 0,
                            subtotalSumD: 0,
                            accountLookupString: '',
                            serviceAccount: null,
                          }

                          let subtotalSum = 0
                          filteredInvoiceLines.map(invoiceLine => {
                            subtotalSum =
                              subtotalSum +
                              invoiceLine.unitsD * invoiceLine.unitPriceD
                          })

                          const subTotal = {
                            ...subTotalLine,
                            subtotalSum: currencyFormatter(subtotalSum) + '',
                            subtotalSumD: subtotalSum,
                          }

                          return {
                            accountNumber: key,
                            headerLine: headerLine,
                            subTotalLine: subTotal,
                            invoiceLines: filteredInvoiceLines,
                          }
                        })
                        .value()
                    : [],
              }
              setInvoiceDetails(invoiceResponse)

              // RawInvoiceRows
              setRawInvoiceLines(response.data.rawInvoiceRows)
              setInvoiceDetailsLoading(false)
            }
          })
          .catch(() => {
            setAlert({
              show: true,
              type: 'error',
              message: 'Failed to load invoice details',
              autoHideDuration: 2000,
            })
            setInvoiceDetailsLoading(false)
          })
      }
    }
  }, [accessToken])

  useEffect(() => {
    let total = 0
    groupedInvoiceLines.map(groupedInvoiceLine => {
      total = total + groupedInvoiceLine.subTotalLine.subtotalSumD
    })

    setInvoiceDetails(previousObj => ({
      ...previousObj,
      invoiceTotalD: parseFloat(total.toFixed(2)),
      invoiceTotal: total.toFixed(2),
    }))
  }, [groupedInvoiceLines])

  const handleSave = (): void => {
    const updatedInvoiceLines: InvoiceLineType[] = []

    invoiceDetails.groupedInvoiceLines.map(groupedInvoiceLine =>
      updatedInvoiceLines.push(
        groupedInvoiceLine.headerLine,
        ...groupedInvoiceLine.invoiceLines,
        groupedInvoiceLine.subTotalLine
      )
    )
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { groupedInvoiceLines, invoiceLines, ...rest } = invoiceDetails

    setInvoiceDetailsSaving(true)
    updateInvoiceDetails({
      ...rest,
      invoiceLines: updatedInvoiceLines,
    })
      .then(() => {
        setAlert({
          show: true,
          type: 'success',
          message: 'Invoice details updated successfully.',
          autoHideDuration: 2000,
        })
        setIsDirty(false)
        setInvoiceDetailsSaving(false)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to update invoice details',
          autoHideDuration: 2000,
        })
        setInvoiceDetailsSaving(false)
      })
  }

  return (
    <AdminPageWrapper
      pageTitle={'Invoice Details'}
      sx={{ '& .admin-page-wrapper-container': { position: 'relative' } }}
    >
      <>
        <LoadingButton
          loading={invoiceDetailsSaving}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          disableElevation
          size="small"
          sx={{ position: 'absolute', right: 0, top: 0 }}
          onClick={handleSave}
          disabled={!isDirty}
        >
          Save
        </LoadingButton>
        <Card data-testid="invoice-container">
          {!invoiceDetailsLoading && invoiceDetails ? (
            <CardContent>
              <InvoiceDetailsHeader invoiceDetails={invoiceDetails} />
              <Box sx={{ pt: 2 }}>
                <InvoiceDetailsInvoiceLines
                  groupedInvoiceLinesAtoms={groupedInvoiceLinesAtoms}
                  invoiceDetails={invoiceDetails}
                  setIsDirty={setIsDirty}
                />
                <InvoiceDetailsRawInvoiceLines
                  rawInvoiceLines={rawInvoiceLines}
                />
              </Box>
              {invoiceDetails.notes ? (
                <Box sx={{ my: 2 }}>
                  <AlertStack data={[invoiceDetails.notes]} severity="info" />
                </Box>
              ) : null}
              <Box sx={{ my: 2 }}>
                <AlertStack
                  data={invoiceDetails.debugInfoList}
                  severity="warning"
                />
              </Box>
            </CardContent>
          ) : invoiceDetailsLoading ? (
            <Loading message="Loading invoice details..." height="86vh" />
          ) : (
            <Box sx={{ minHeight: '5rem', p: 2 }}>
              <Typography>No invoice details found.</Typography>
            </Box>
          )}
        </Card>
      </>
    </AdminPageWrapper>
  )
}

export default Details
