import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  ColDef,
  ICellRendererParams,
  IFilterOptionDef,
} from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import theta_logo from 'assets/img/Theta_logo.png'
import ConfirmationDialog from 'components/atoms/ConfirmationDialog'
import MiniSwitch from 'components/atoms/MiniSwitch'
import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import Loading from 'components/molecules/Loading'
import { useAtom } from 'jotai'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { alertAtom } from 'stores'
import { getDecodedAccessToken } from 'stores/auth'
import {
  deleteHauler,
  getHaulerSettingList,
  updateHaulerSettings,
} from 'stores/hauler'
import { getVendorList } from 'stores/vendor'
import { HaulerSetting } from 'types/Hauler'

const Hauler = (): JSX.Element => {
  const gridRef = useRef<AgGridReact>(null)
  const [, setAlert] = useAtom(alertAtom)
  const [loading, setLoading] = useState(false)
  const [rowData, setRowData] = useState<HaulerSetting[]>([])
  const [selectedHauler, setHauler] = useState<HaulerSetting | null>(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [fnoenabledprogress, setFnoEnabledProgress] = useState<number>(0)
  const [aiModelCount, setAiModelCount] = useState<number>(0)
  const [haulerSettingsSaving, setHaulerSettingsSaving] =
    useState<boolean>(false)
  const permissions = getDecodedAccessToken()?.permissions

  useEffect(() => {
    getHaulers()
  }, [])

  const getHaulers = (): void => {
    setLoading(true)
    getVendorList()
      .then(vendorResponse => {
        // Load haulers
        getHaulerSettingList()
          .then(response => {
            if (response.data && response.data.HaulerIds) {
              if (response.data.HaulerIds.length) {
                // F&O
                const totalFnOEnabled = response.data.HaulerIds.filter(
                  hauler => hauler.fnOEnabled
                )
                setFnoEnabledProgress(
                  (totalFnOEnabled.length / response.data.HaulerIds.length) *
                    100
                )

                // Ai Enabled
                const totalAiEnabled = response.data.HaulerIds.filter(
                  hauler => hauler.aiModelId && hauler.aiModelId.length
                )
                setAiModelCount(
                  (totalAiEnabled.length / response.data.HaulerIds.length) * 100
                )
              }
              const haulers: HaulerSetting[] = response.data.HaulerIds.map(
                hauler => {
                  const found = vendorResponse.data.find(
                    vendor => vendor.name === hauler.vendorname
                  )

                  if (found) {
                    return {
                      ...hauler,
                      timestamp: `${response.data._ts}`,
                      vendorLongName: found.longName,
                    }
                  } else {
                    return {
                      ...hauler,
                      vendorLongName: '',
                    }
                  }
                }
              )
              setRowData(haulers)
            }
            setLoading(false)
            setHaulerSettingsSaving(false)
          })
          .catch(() => {
            setAlert({
              show: true,
              type: 'error',
              message: 'Failed to load haulers list',
              autoHideDuration: 2000,
            })
            setLoading(false)
            setHaulerSettingsSaving(false)
          })
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to load vendors list',
          autoHideDuration: 2000,
        })
        setLoading(false)
      })
  }

  const handleHaulerDelete = (hauler: HaulerSetting): void => {
    setConfirmDialogOpen(true)
    setHauler(hauler)
  }

  const handleDeleteClose = (): void => {
    setConfirmDialogOpen(false)
    setHauler(null)
  }

  const handleDeleteConfirm = (): void => {
    if (selectedHauler && selectedHauler.vendorname) {
      deleteHauler(selectedHauler.vendorname)
        .then(() => {
          // get haulers again to update the grid
          getHaulers()
          setAlert({
            show: true,
            type: 'success',
            message: 'Hauler deleted',
            autoHideDuration: 2000,
          })
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to delete hauler',
            autoHideDuration: 2000,
          })
        })
    }
    setConfirmDialogOpen(false)
  }

  const handleFnOEnabled = (
    event: ChangeEvent<HTMLInputElement>,
    hauler: HaulerSetting
  ): void => {
    setHaulerSettingsSaving(true)
    updateHaulerSettings({
      ...hauler,
      fnOEnabled: !hauler.fnOEnabled,
    })
      .then(() => {
        getHaulers()
        setAlert({
          show: true,
          type: 'success',
          message: 'Updated F&O settings',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to update F&O settings',
          autoHideDuration: 2000,
        })
        setHaulerSettingsSaving(false)
      })
  }

  const handleDuplicateCheckOverride = (
    event: ChangeEvent<HTMLInputElement>,
    hauler: HaulerSetting
  ): void => {
    setHaulerSettingsSaving(true)
    updateHaulerSettings({
      ...hauler,
      duplicateCheckOverride: !hauler.duplicateCheckOverride,
    })
      .then(() => {
        getHaulers()
        setAlert({
          show: true,
          type: 'success',
          message: 'Updated Duplicate Check Override settings',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to update Duplicate Check Override settings',
          autoHideDuration: 2000,
        })
        setHaulerSettingsSaving(false)
      })
  }

  useEffect(() => {
    if (haulerSettingsSaving) {
      gridRef.current?.api.showLoadingOverlay()
    } else {
      gridRef.current?.api?.hideOverlay()
    }
  }, [haulerSettingsSaving])

  const booleanFilter = {
    buttons: ['reset'],
    filterOptions: [
      {
        displayKey: 'all',
        displayName: 'All',
        predicate: () => true,
        numberOfInputs: 0,
      },
      {
        displayKey: 'true',
        displayName: 'Yes',
        predicate: (_, cellValue) => +cellValue === 1,
        numberOfInputs: 0,
      },
      {
        displayKey: 'false',
        displayName: 'No',
        predicate: (_, cellValue) => +cellValue === 0,
        numberOfInputs: 0,
      },
    ] as IFilterOptionDef[],
    suppressAndOrCondition: true,
    defaultOption: 'all',
  }

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: 'Vendor Name',
      field: 'vendorLongName',
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <Tooltip
            title="Click to view/edit vendor and hauler details"
            placement="right"
            arrow
          >
            <NavLink
              aria-label="vendor Number"
              to={`/vendors/${params.data.vendorname}`}
              style={{
                textDecoration: 'none',
              }}
            >
              {params.value}
            </NavLink>
          </Tooltip>
        )
      },
      width: 230,
    },
    {
      field: 'HaulerId',
      headerName: 'USA 1 Hauler Id',
    },
    {
      field: 'HaulerIdUsa2',
      headerName: 'USA 2 Hauler Id',
    },
    {
      field: 'fnOEnabled',
      headerName: 'F&O Enabled',
      filter: 'agNumberColumnFilter',
      filterParams: booleanFilter,
      cellStyle: {
        justifyContent: 'center',
      },
      hide: !permissions?.includes('update:hauler'),
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <MiniSwitch
            checked={params.value}
            size="small"
            onChange={(event): void => {
              handleFnOEnabled(event, params.data)
            }}
          />
        )
      },
    },
    {
      field: 'sharePointFolder',
      width: 220,
    },
    {
      field: 'duplicateCheckOverride',
      filter: 'agNumberColumnFilter',
      filterParams: booleanFilter,
      cellStyle: {
        justifyContent: 'center',
      },
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <MiniSwitch
            checked={params.value}
            size="small"
            onChange={(event): void => {
              handleDuplicateCheckOverride(event, params.data)
            }}
          />
        )
      },
    },
    {
      field: 'aiModelId',
      width: 200,
    },
    {
      field: 'invoiceIdentifierKeywordsCsv',
      headerName: 'Identifier Keywords',
      width: 200,
    },
    {
      field: 'incomingEmail',
      width: 100,
      flex: 1,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
      },
    },
    {
      field: '',
      width: 70,
      filter: false,
      hide: !permissions?.includes('delete:hauler'),
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <IconButton
            color="error"
            onClick={(): void => handleHaulerDelete(params.data)}
            disableRipple
            size="small"
          >
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        )
      },
    },
  ])

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      width: 150,
      cellStyle: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      },
    }
  }, [])

  return (
    <AdminPageWrapper pageTitle="Haulers">
      <ConfirmationDialog
        open={confirmDialogOpen}
        icon="highlight_off"
        description="Are you sure you want to delete this hauler ?"
        action={
          <>
            <Button
              autoFocus
              color="primary"
              variant="contained"
              onClick={handleDeleteClose}
              disableElevation
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              color="warning"
              autoFocus
              variant="contained"
              onClick={handleDeleteConfirm}
              disableElevation
            >
              Delete
            </Button>
          </>
        }
      />
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Box>
            {loading && !haulerSettingsSaving ? (
              <Loading message="Loading haulers..." height="550px" />
            ) : (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ minWidth: 178, textAlign: 'right' }}>
                    <Typography variant="small" color="text.primary">
                      {`${
                        Math.round(
                          (fnoenabledprogress + Number.EPSILON) * 100
                        ) / 100
                      }% `}
                    </Typography>
                    <Typography variant="small" color="text.secondary">
                      Haulers are F&O Enabled
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100%', ml: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={fnoenabledprogress}
                      sx={{ height: 8 }}
                      color={
                        fnoenabledprogress <= 30
                          ? 'error'
                          : fnoenabledprogress > 30 && fnoenabledprogress <= 75
                          ? 'warning'
                          : 'success'
                      }
                    />
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Box sx={{ minWidth: 178, textAlign: 'right' }}>
                    <Typography variant="small" color="text.primary">
                      {`${
                        Math.round((aiModelCount + Number.EPSILON) * 100) / 100
                      }% `}
                    </Typography>
                    <Typography variant="small" color="text.secondary">
                      Haulers are Ai Driven
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100%', ml: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={aiModelCount}
                      sx={{ height: 8 }}
                      color={
                        aiModelCount <= 30
                          ? 'error'
                          : aiModelCount > 30 && aiModelCount <= 75
                          ? 'warning'
                          : 'success'
                      }
                    />
                  </Box>
                </Box>
                <Box
                  data-testid="haulers-grid-container"
                  className="ag-theme-alpine"
                  width={{ md: '100%', lg: loading ? '100%' : '100%' }}
                  sx={{ height: 519, mt: 2 }}
                >
                  <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    pagination={true}
                    paginationPageSize={10}
                    gridOptions={{
                      suppressCellFocus: true,
                    }}
                    overlayLoadingTemplate={`<img style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%) scale(2); width:4.25rem"  src="${theta_logo}" aria-label="loading" alt='loading' />`}
                  />
                </Box>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </AdminPageWrapper>
  )
}

export default Hauler
