import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { DynamicFieldData } from 'types/DynamicFormFieldcontrol'

const DynamicControl = ({
  inputType,
  label,
  fieldName,
  placeHolder,
  defaultValue,
  options = [],
  config = {},
}: DynamicFieldData): JSX.Element => {
  const { register } = useFormContext()

  switch (inputType) {
    case 'text':
    case 'number':
      return (
        <TextField
          id={fieldName}
          fullWidth
          required={config.required ? true : false}
          label={label}
          size="small"
          type={inputType}
          {...register(fieldName, config)}
          defaultValue={defaultValue}
          placeholder={placeHolder}
        />
      )
    case 'select': {
      return (
        <FormControl fullWidth>
          <InputLabel id={fieldName}>{label}</InputLabel>
          <Select
            size="small"
            {...register(fieldName, config)}
            defaultValue={defaultValue}
            name={fieldName}
            id={fieldName}
            label={label}
            placeholder={placeHolder}
          >
            {options.map((o, index) => (
              <MenuItem key={index} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    default:
      return (
        <TextField
          id={fieldName}
          fullWidth
          required
          label={label}
          size="small"
          type={inputType}
          {...register(fieldName, config)}
          defaultValue={defaultValue}
          placeholder={placeHolder}
        />
      )
  }
}

export default DynamicControl
