import { AxiosResponse } from 'axios'
import {
  MissingServiceAccLUType,
  VendorType,
  vendorNameListType,
} from 'types/Vendor'

import { axiosInstance } from '.'
import AppService from './appService'

const values = {}

const getters = {
  vendorsList(): Promise<AxiosResponse<VendorType[], any>> {
    return axiosInstance.get(`/vendors`)
  },
  vendorNameList(): Promise<AxiosResponse<vendorNameListType[], any>> {
    return axiosInstance.get(`/vendor-names`)
  },
  missingServiceAccountLookupsByVendor(params: {
    vendorName: string
  }): Promise<AxiosResponse<MissingServiceAccLUType[], any>> {
    return axiosInstance.get(
      `/missingServiceAccountLookups/vendor/${params.vendorName}`
    )
  },

  vendorDetails(params: {
    name: string
  }): Promise<AxiosResponse<VendorType, any>> {
    return axiosInstance.get(`/vendors/${params.name}`)
  },

  vendorsInvoiceList(params: {
    vendorName: string
  }): Promise<AxiosResponse<string[], any>> {
    return axiosInstance.get(`/files/output/${params.vendorName}`)
  },
}

const saves = {
  uploadInvoice(params: {
    vendorName: string
    fileName: string
    formData: FormData
  }): Promise<AxiosResponse<VendorType[], any>> {
    return axiosInstance.post(
      `/files/input/${params.vendorName}/${params.fileName}`,
      params.formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  },
  vendor(vendor: VendorType): Promise<AxiosResponse<VendorType | string, any>> {
    if (vendor.id) {
      return axiosInstance.put('/vendors', vendor)
    } else {
      return axiosInstance.post('/vendors', vendor)
    }
  },
}

const deletes = {
  vendor(params: {
    vendorName: string
    vendorId: string
  }): Promise<AxiosResponse<any, any>> {
    return axiosInstance.delete(
      `/vendors/${params.vendorName}/${params.vendorId}`
    )
  },
  deleteMissingServiceAccount(id: string): Promise<AxiosResponse<any, any>> {
    return axiosInstance.delete(`/missingServiceAccountLookups/${id}`)
  },

  deleteMissingServiceAccountLookupById(
    id: string
  ): Promise<AxiosResponse<any, any>> {
    return axiosInstance.delete(`/missingServiceAccountLookups/${id}`)
  },
}

export default class VendorService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
