import { atom, useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import jwt_decode from 'jwt-decode'
import { accessTokenType } from 'types/Auth'

// const storage = createJSONStorage(() => sessionStorage)

export const idTokenAtom = atomWithStorage<string | any>('idToken', null)

export const accessTokenAtom = atomWithStorage<string | any>(
  'accessToken',
  null
)

// export const getDecodedIdToken = (): idTokenType | any => {
//   const [idToken] = useAtom(idTokenAtom)
//   if (idToken) {
//     return jwt_decode(idToken)
//   } else {
//     console.error('Invalid id token!')
//     return undefined
//   }
// }

export const getDecodedAccessToken = (): accessTokenType | any => {
  const [accessToken] = useAtom(accessTokenAtom)
  if (accessToken) {
    return jwt_decode(accessToken)
  } else {
    console.error('Invalid access token!')
    return undefined
  }
}

export const isDrawerOpen = atom(false)

export const logout = (): void => {
  window.localStorage.clear()
  window.location.href = window.location.origin
}
