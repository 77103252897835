import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { forwardRef } from 'react'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SlideDialog = ({
  open,
  title = undefined,
  children,
  actions,
  maxWidth = false,
  onClose,
}: {
  open: boolean
  title?: string | undefined
  actions?: React.ReactNode
  children: React.ReactNode
  maxWidth?: false | Breakpoint | undefined
  onClose?: () => void
}): JSX.Element => {
  const handleClose = (): void => {
    if (onClose) onClose()
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth={maxWidth}
    >
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  )
}

export default SlideDialog
