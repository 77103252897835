import { Box, Switch, TableBody, TableCell, TableRow } from '@mui/material'
import { useAtom } from 'jotai'
import { recalCulateSubTotalsAtom } from 'stores/invoice'

const ReCalculateSubTotals = (): JSX.Element => {
  const [recalculateSubTotals, setReCalculateSubTotals] = useAtom(
    recalCulateSubTotalsAtom
  )

  return (
    <>
      <TableBody>
        <TableRow
          sx={{ backgroundColor: '#b8bfc5', '&>th': { color: 'white' } }}
        >
          <TableCell align="left" colSpan={7}>
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}>
              Re-Calculate SubTotals
              <Switch
                checked={recalculateSubTotals || false}
                size="small"
                onChange={(event): void =>
                  setReCalculateSubTotals(event.target.checked)
                }
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{ ml: 2 }}
              />
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  )
}

export default ReCalculateSubTotals
