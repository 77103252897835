import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import DateRangeIcon from '@mui/icons-material/DateRange'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  MenuProps,
  OutlinedInput,
  Typography,
  alpha,
  styled,
} from '@mui/material'
import {
  add,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns'
import { MouseEvent, forwardRef, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(0),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

type RefType = any

type CustomInputProps = {
  onClick?: () => void
  onChange?: () => void
  value?: string
  disabled?: boolean
  readonly?: boolean
  label?: string
}

const DatePresetPicker = ({
  selectedDatePickerOption = null,
  onChange,
}: {
  selectedDatePickerOption: {
    value: string
    label: string
  } | null
  onChange: (
    startDate: Date | null,
    endDate: Date | null,
    selectedDateOption: {
      value: string
      label: string
    } | null
  ) => void
}): JSX.Element => {
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(new Date())
  const maxAllowedDate = add(new Date(), { days: 1 })
  const minAllowedDate = new Date(2024, 0, 1)
  const [readonlyDatePicker, setReadonlyDatePicker] = useState(false)

  // Main menu
  const [anchorMainEl, setAnchorMainEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorMainEl)

  // Sub menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openSub = Boolean(anchorEl)

  const [mainPickerPresets] = useState([
    {
      value: 'today',
      label: 'Today',
    },
    {
      value: 'yesterday',
      label: 'Yesterday',
    },
    {
      value: 'week',
      label: 'Week',
    },
    {
      value: 'month',
      label: 'Month',
    },
    {
      value: 'quarter',
      label: 'Quarter',
    },
    {
      value: 'year',
      label: 'Year',
    },
  ])

  const [rangePickerPresets] = useState([
    {
      value: '-7',
      label: 'Last 7 Days',
    },
    {
      value: '-30',
      label: 'Last 30 Days',
    },
    {
      value: '-60',
      label: 'Last 60 Days',
    },
    {
      value: '-90',
      label: 'Last 90 Days',
    },
    {
      value: 'custom_date',
      label: 'Custom Date',
    },
    {
      value: 'custom_range',
      label: 'Custom Range',
    },
  ])

  const [selectedDateOption, setSelectedDateOption] = useState<{
    value: string
    label: string
  } | null>(null)

  const [selectedRangeOption, setSelectedRangeOption] = useState<{
    value: string
    label: string
  } | null>(null)

  const handleMainMenuOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorMainEl(event.currentTarget)
  }

  const handleMainMenuClose = (
    option: { value: string; label: string } | null,
    subOption: { value: string; label: string } | null
  ): void => {
    const today = new Date()
    if (option) {
      setSelectedDateOption(option)
      setSelectedRangeOption(subOption)

      switch (option.value) {
        case 'yesterday':
          {
            setStartDate(add(today, { days: -1 }))
            setEndDate(add(today, { days: -1 }))
          }
          break
        case 'week':
          {
            setStartDate(startOfWeek(today))
            setEndDate(endOfWeek(today))
          }
          break
        case 'month':
          {
            setStartDate(startOfMonth(today))
            setEndDate(endOfMonth(today))
          }
          break
        case 'quarter':
          {
            setStartDate(startOfQuarter(today))
            setEndDate(endOfQuarter(today))
          }
          break
        case 'year':
          {
            setStartDate(startOfYear(today))
            setEndDate(endOfYear(today))
          }
          break
        case 'range':
          {
            setReadonlyDatePicker(true)
            switch (subOption?.value) {
              case '-7':
                {
                  setStartDate(add(today, { days: -7 }))
                  setEndDate(today)
                }
                break
              case '-30':
                {
                  setStartDate(add(today, { days: -30 }))
                  setEndDate(today)
                }
                break
              case '-60':
                {
                  setStartDate(add(today, { days: -60 }))
                  setEndDate(today)
                }
                break
              case '-90':
                {
                  setStartDate(add(today, { days: -90 }))
                  setEndDate(today)
                }
                break
              case 'custom_range':
                {
                  setStartDate(null)
                  setEndDate(null)
                  setReadonlyDatePicker(false)
                }
                break
              case 'custom_date':
              default:
                {
                  setStartDate(null)
                  setEndDate(null)
                  setReadonlyDatePicker(false)
                }
                break
            }
          }
          break
        case 'today':
        default:
          {
            setStartDate(today)
            setEndDate(today)
          }
          break
      }
    }
    setAnchorMainEl(null)
  }

  const handleSubMenuOpen = (
    event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>
  ): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleSubMenuClose = (): void => {
    setAnchorEl(null)
  }

  const DatePickerInput = forwardRef<RefType, CustomInputProps>(
    (
      {
        value,
        disabled = false,
        readonly = false,
        label = 'label',
        onClick,
        onChange,
      },
      ref
    ) => (
      <FormControl size="small" fullWidth sx={{ backgroundColor: '#fff' }}>
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          placeholder="MM/DD/YYYY"
          value={value}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="Toggle Datepicker" edge="end">
                {<DateRangeIcon sx={{ fontSize: '0.85rem' }} color="primary" />}
              </IconButton>
            </InputAdornment>
          }
          onClick={onClick}
          label={label}
          ref={ref}
          disabled={disabled}
          inputProps={{ readOnly: readonly }}
          onChange={onChange}
          sx={{ fontSize: '0.8rem', width: '100%' }}
        />
      </FormControl>
    )
  )

  DatePickerInput.displayName = 'DatePickerInput'

  useEffect(() => {
    onChange(startDate, endDate, selectedDateOption)
  }, [startDate, endDate])

  useEffect(() => {
    if (selectedDatePickerOption) {
      setSelectedDateOption(selectedDateOption)
    } else {
      setSelectedDateOption(mainPickerPresets[0])
    }
  }, [selectedDatePickerOption])

  return (
    <Grid container spacing={2}>
      {/* {selectedDateOption} */}
      <Grid item sx={{ alignItems: 'center' }}>
        <Button
          aria-controls={open ? 'main-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="outlined"
          disableElevation
          onClick={handleMainMenuOpen}
          startIcon={<DateRangeIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            height: '2.1875rem',
            minWidth: '8.5rem',
            justifyContent: 'space-between',
          }}
          size="small"
        >
          <Typography variant="caption" sx={{ lineHeight: 1, mt: 0.2 }}>
            {selectedDateOption
              ? selectedDateOption?.value === 'range'
                ? selectedRangeOption?.label
                : selectedDateOption.label
              : 'Select Date'}
          </Typography>
        </Button>
        <StyledMenu
          id="main-menu"
          MenuListProps={{
            'aria-labelledby': 'main-button',
          }}
          anchorEl={anchorMainEl}
          open={open}
          onClose={(): void => handleMainMenuClose(null, null)}
        >
          <MenuList>
            {mainPickerPresets.map(preset => {
              return (
                <MenuItem
                  onClick={(): void => handleMainMenuClose(preset, null)}
                  selected={selectedDateOption?.value === preset.value}
                  key={preset.value}
                >
                  <ListItemIcon>
                    <DateRangeIcon />
                  </ListItemIcon>
                  <ListItemText>{preset.label}</ListItemText>
                </MenuItem>
              )
            })}
            <Divider sx={{ my: 0.5 }} />
            <MenuItem
              id="sub-menu-button"
              aria-controls={openSub ? 'sub-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openSub ? 'true' : undefined}
              onClick={(event): void => handleSubMenuOpen(event)}
              selected={selectedDateOption?.value === 'range'}
            >
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText>Range</ListItemText>
              <KeyboardArrowRightOutlinedIcon />
            </MenuItem>
          </MenuList>
        </StyledMenu>
        <StyledMenu
          id="sub-menu"
          anchorEl={anchorEl}
          open={openSub}
          onClose={handleSubMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {rangePickerPresets.map(preset => {
            return (
              <MenuItem
                key={preset.value}
                onClick={(): void => {
                  handleSubMenuClose()
                  handleMainMenuClose(
                    { value: 'range', label: 'Range' },
                    preset
                  )
                }}
                selected={selectedRangeOption?.value === preset.value}
              >
                {preset.label}
              </MenuItem>
            )
          })}
        </StyledMenu>
      </Grid>
      {/* week */}
      {selectedDateOption?.value === 'week' ? (
        <Grid item>
          <DatePicker
            selected={startDate}
            onChange={(date): void => {
              if (date) {
                setStartDate(date)
                setEndDate(add(date, { days: 6 }))
              }
            }}
            selectsStart
            startDate={startDate}
            closeOnScroll={true}
            customInput={<DatePickerInput label="Choose Week" />}
            maxDate={maxAllowedDate}
            minDate={minAllowedDate}
            dateFormat="I/R"
            showWeekNumbers
            showWeekPicker
          />
        </Grid>
      ) : null}
      {/* Month */}
      {selectedDateOption?.value === 'month' ? (
        <Grid item>
          <DatePicker
            selected={startDate}
            onChange={(date): void => {
              if (date) {
                setStartDate(date)
                setEndDate(endOfMonth(date))
              }
            }}
            startDate={startDate}
            closeOnScroll={true}
            customInput={<DatePickerInput label="Choose Month" />}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            maxDate={maxAllowedDate}
            minDate={minAllowedDate}
          />
        </Grid>
      ) : null}
      {/* Quarter */}
      {selectedDateOption?.value === 'quarter' ? (
        <Grid item>
          <DatePicker
            selected={startDate}
            onChange={(date): void => {
              if (date) {
                setStartDate(date)
                setEndDate(endOfQuarter(date))
              }
            }}
            closeOnScroll={true}
            customInput={
              <DatePickerInput disabled={false} label="Choose Quarter" />
            }
            maxDate={maxAllowedDate}
            minDate={minAllowedDate}
            showQuarterYearPicker
            dateFormat="yyyy, QQQ"
          />
        </Grid>
      ) : null}
      {/* Year */}
      {selectedDateOption?.value === 'year' ? (
        <Grid item>
          <DatePicker
            selected={startDate}
            onChange={(date): void => {
              if (date) {
                setStartDate(date)
                setEndDate(endOfYear(date))
              }
            }}
            startDate={startDate}
            closeOnScroll={true}
            customInput={
              <DatePickerInput disabled={false} label="Choose Year" />
            }
            maxDate={maxAllowedDate}
            minDate={minAllowedDate}
            showYearPicker
            dateFormat="yyyy"
          />
        </Grid>
      ) : null}
      {/* Custom Date */}
      {selectedDateOption?.value === 'range' &&
      selectedRangeOption?.value === 'custom_date' ? (
        <Grid item>
          <DatePicker
            selected={startDate}
            onChange={(date): void => {
              if (date) {
                setStartDate(date)
                setEndDate(date)
              }
            }}
            closeOnScroll={true}
            customInput={
              <DatePickerInput disabled={false} label="Choose Date" />
            }
            maxDate={maxAllowedDate}
            minDate={minAllowedDate}
            dateFormat="MM/dd/yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </Grid>
      ) : null}
      {/* Custom Range */}
      {selectedDateOption?.value === 'range' &&
      selectedRangeOption?.value !== 'custom_date' ? (
        <>
          <Grid item>
            <DatePicker
              selected={startDate}
              onChange={(date): void => {
                setStartDate(date)
              }}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              closeOnScroll={true}
              customInput={
                <DatePickerInput
                  readonly={readonlyDatePicker}
                  label="From Date"
                />
              }
              dateFormat="MM/dd/yyyy"
              maxDate={maxAllowedDate}
              minDate={minAllowedDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              readOnly={readonlyDatePicker}
            />
          </Grid>
          {selectedRangeOption &&
          selectedRangeOption.value !== '0' &&
          selectedRangeOption.value !== '-1' ? (
            <>
              <Grid
                item
                xs="auto"
                md="auto"
                xl="auto"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <ArrowRightAltIcon color="primary" />
              </Grid>
              <Grid item>
                <DatePicker
                  selected={endDate}
                  onChange={(date): void => {
                    setEndDate(date)
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  closeOnScroll={true}
                  dateFormat="MM/dd/yyyy"
                  customInput={
                    <DatePickerInput
                      readonly={startDate === null || readonlyDatePicker}
                      label="To Date"
                    />
                  }
                  minDate={startDate}
                  maxDate={maxAllowedDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  readOnly={startDate === null || readonlyDatePicker}
                />
              </Grid>
            </>
          ) : null}
        </>
      ) : null}
    </Grid>
  )
}

export default DatePresetPicker
