import AddIcon from '@mui/icons-material/Add'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClearIcon from '@mui/icons-material/Clear'
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  ButtonGroup,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { useRef, useState } from 'react'

const SplitSelect = ({
  options,
  placeHolder = 'Select',
  loading = false,
  onSelect,
  onMoreinfo,
  onDelete,
}: {
  options: any[]
  placeHolder?: string
  loading?: boolean
  onSelect: (option: any | null) => void
  onMoreinfo?: (event: React.MouseEvent, option: any | null) => void
  onDelete?: (event: React.MouseEvent, option: any | null) => void
}): JSX.Element => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [selectedOption, setselectedOption] = useState<null | {
    text: string
    value: number | string
  }>(null)

  const handleClick = (): void => {
    if (selectedOption === null) {
      onSelect(null)
    } else {
      onSelect(selectedOption)
      setselectedOption(null)
    }
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: any
  ): void => {
    setselectedOption(option)
    setOpen(false)
  }

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <Box>
      <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button">
        <Button
          onClick={handleClick}
          sx={{
            textTransform: 'unset',
            width: '17.5rem',
            display: 'flex',
          }}
          startIcon={
            selectedOption !== null && selectedOption ? <AddIcon /> : null
          }
          size="small"
        >
          <Typography
            variant="button"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              lineHeight: '1.125rem',
            }}
          >
            {selectedOption !== null && selectedOption
              ? `ADD ${selectedOption.text}`
              : placeHolder.toUpperCase()}
          </Typography>
        </Button>
        <LoadingButton
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select missing service accounts"
          aria-haspopup="menu"
          onClick={handleToggle}
          variant="contained"
          loading={loading}
        >
          <ArrowDropDownIcon />
        </LoadingButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 2,
          boxShadow: '0.125rem .25rem -0.125rem rgb(0 0 0 / 50%)',
          width: '17.5rem',
          background: '#fff',
        }}
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }): JSX.Element => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <Paper square>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem
                  sx={{
                    maxHeight: '17.1875rem',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    p: 0,
                  }}
                >
                  {selectedOption !== null && selectedOption ? (
                    <MenuItem
                      onClick={(event): void =>
                        handleMenuItemClick(event, null)
                      }
                    >
                      <Box display="flex">
                        {onMoreinfo ? (
                          <IconButton
                            color="error"
                            size="small"
                            sx={{ mr: 1, p: 0 }}
                          >
                            <ClearIcon fontSize="inherit" />
                          </IconButton>
                        ) : null}
                        <Typography
                          variant="small"
                          sx={{
                            fontWeight: '600',
                          }}
                        >
                          CANCEL
                        </Typography>
                      </Box>
                    </MenuItem>
                  ) : null}
                  {options.map(
                    (option: any): JSX.Element => (
                      <MenuItem
                        key={option.value}
                        selected={
                          selectedOption
                            ? option.value === selectedOption.value
                            : false
                        }
                        onClick={(event): void =>
                          handleMenuItemClick(event, option)
                        }
                        sx={{
                          px: 1,
                          pl: !option.fileUrl ? '2.125rem' : 1,
                          cursor: 'unset',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.02)',
                          },
                        }}
                      >
                        <Box display="flex">
                          {option.fileUrl && onMoreinfo ? (
                            <Tooltip
                              title="View lookup reference"
                              arrow
                              placement="left"
                            >
                              <IconButton
                                color="info"
                                size="small"
                                sx={{
                                  mr: 1,
                                  p: 0,
                                  '&:hover': {
                                    backgroundColor: 'rgb(2 136 209 / 32%)',
                                    borderRadius: 0,
                                  },
                                }}
                                onClick={(event): void =>
                                  onMoreinfo(event, option)
                                }
                              >
                                <DatasetLinkedIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          <Typography
                            noWrap={true}
                            variant="small"
                            sx={{
                              cursor: 'pointer',
                              fontWeight: '500',
                              width: onMoreinfo
                                ? 'calc(15.625rem - 1.125rem - .5rem - 1.125rem)'
                                : 'calc(15.625rem)',
                              '&:hover': {
                                fontWeight: '600',
                              },
                            }}
                          >
                            {option.text}
                          </Typography>
                          {onDelete ? (
                            <Tooltip title="Delete" arrow placement="right">
                              <IconButton
                                color="error"
                                size="small"
                                sx={{
                                  ml: 1,
                                  p: 0,
                                  '&:hover': {
                                    backgroundColor: 'rgb(251 163 0 / 32%)',
                                    borderRadius: 0,
                                  },
                                }}
                                onClick={(event): void =>
                                  onDelete(event, option)
                                }
                              >
                                <DeleteForeverIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Box>
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export default SplitSelect
