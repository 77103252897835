import { useAuth0 } from '@auth0/auth0-react'
import { Avatar, Box, Card, CardContent, Chip, Typography } from '@mui/material'
import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import Loading from 'components/molecules/Loading'
import { useEffect, useState } from 'react'
import { getDecodedAccessToken } from 'stores/auth'

const Profile = (): JSX.Element => {
  const { user } = useAuth0()
  const access = getDecodedAccessToken()
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE
  const [roles, setRoles] = useState<string[]>([])
  const [permissions, setPermissions] = useState<string[]>([])

  useEffect(() => {
    if (access) {
      const filteredRoles = access[`${audience}//roles`]?.filter(
        (role: string) => role?.toLowerCase()?.includes('theta')
      )
      setRoles(filteredRoles || [])

      setPermissions(access.permissions || [])
    }
  }, [])

  return user ? (
    <AdminPageWrapper pageTitle="Profile">
      <Box>
        <Card
          sx={{
            minWidth: 275,
            position: 'relative',
            overflow: 'visible',
            msOverflowX: 'hidden',
            mt: '6.25rem',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {user ? (
              <>
                <Avatar
                  alt={user.name}
                  src={user.picture}
                  sx={{
                    width: 100,
                    height: 100,
                    position: 'absolute',
                    top: '-3.125rem',
                  }}
                />

                <Typography
                  variant="h3"
                  sx={{ mt: 8 }}
                  color="text.primary"
                  data-testid="user-name"
                >
                  {user.name}
                </Typography>
                <Typography
                  variant="small"
                  sx={{ fontSize: '1.2rem', fontWeight: '100', mt: '0.3rem' }}
                  color="GrayText"
                  data-testid="user-email"
                >
                  {user.email}
                </Typography>
                <Typography
                  variant="small"
                  sx={{ fontSize: '1rem', fontWeight: '100', mt: '0.3rem' }}
                  color="GrayText"
                  data-testid="user-id"
                >
                  User Id: {user.sub?.split('|')[1]}
                </Typography>
              </>
            ) : null}
            {access ? (
              <Box
                sx={{
                  fontSize: '1rem',
                  fontWeight: '100',
                  mt: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
                color="GrayText"
                component="div"
                data-testid="user-roles-permissions"
              >
                {roles ? (
                  <Box
                    sx={{
                      display: 'flex',
                      mb: 2,
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                    data-testid="user-roles"
                  >
                    <Typography>Roles:</Typography>
                    <Box>
                      {roles.map((item: string) => {
                        return (
                          <Chip
                            size="small"
                            variant="outlined"
                            key={item}
                            color="primary"
                            label={item}
                            sx={{ mx: 0.5 }}
                            data-testid="user-permission"
                          />
                        )
                      })}
                    </Box>
                  </Box>
                ) : null}
                {permissions ? (
                  <Box
                    sx={{
                      display: 'flex',
                      mb: 2,
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                    data-testid="user-permissions"
                  >
                    <Typography>Permissions:</Typography>
                    <Box>
                      {permissions.map((item: string) => {
                        return (
                          <Chip
                            size="small"
                            key={item}
                            variant="outlined"
                            color="secondary"
                            label={item}
                            sx={{ mx: 0.5 }}
                            data-testid="user-permission"
                          />
                        )
                      })}
                    </Box>
                  </Box>
                ) : null}
              </Box>
            ) : null}
          </CardContent>
        </Card>
      </Box>
    </AdminPageWrapper>
  ) : (
    <Loading />
  )
}

export default Profile
