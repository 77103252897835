export const drawerMenu = [
  {
    text: 'Home',
    icon: 'homeOutlined',
    path: '/dashboard',
    end: true,
  },
  {
    text: 'Invoices',
    icon: 'receiptOutlined',
    path: '/invoices',
    end: false,
  },
  {
    text: 'Invoice Converter',
    icon: 'published_with_changes',
    path: '/converter',
    end: true,
  },
  {
    text: 'Vendors',
    icon: 'store',
    path: '/vendors',
    end: false,
  },
  {
    text: 'Product Mappings',
    icon: 'route',
    path: '/productMappings',
    end: true,
  },
  {
    text: 'Haulers',
    icon: 'local_shipping',
    path: '/haulers',
    end: true,
  },
  // {
  //   text: 'AP Dashboard',
  //   icon: 'insights',
  //   path: '/apDashboard',
  //   end: true,
  // },
]

export const settingsMenu = [
  {
    text: 'Profile',
    icon: 'homeOutlined',
    path: '/profile',
    end: true,
  },
]
