import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Tooltip,
} from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import ConfirmationDialog from 'components/atoms/ConfirmationDialog'
import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import Loading from 'components/molecules/Loading'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { alertAtom } from 'stores'
import { getDecodedAccessToken } from 'stores/auth'
import { deleteVendor, getVendorList } from 'stores/vendor'
import { VendorType } from 'types/Vendor'

const Vendor = (): JSX.Element => {
  const navigate = useNavigate()
  const [, setAlert] = useAtom(alertAtom)
  const [loading, setLoading] = useState(false)
  const [rowData, setRowData] = useState<any[]>([])
  const [selectedVendor, setVendor] = useState<VendorType | null>(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const permissions = getDecodedAccessToken()?.permissions

  useEffect(() => {
    getVendors()
  }, [])

  const getVendors = (): void => {
    setLoading(true)
    getVendorList()
      .then(response => {
        setRowData(response.data)
        setLoading(false)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to load vendors list',
          autoHideDuration: 2000,
        })
        setLoading(false)
      })
  }

  const handleVendorDelete = (vendor: VendorType): void => {
    setConfirmDialogOpen(true)
    setVendor(vendor)
  }

  const handleDeleteClose = (): void => {
    setConfirmDialogOpen(false)
    setVendor(null)
  }

  const handleDeleteConfirm = (): void => {
    if (selectedVendor && selectedVendor.name && selectedVendor.id) {
      deleteVendor(selectedVendor.name, selectedVendor.id)
        .then(() => {
          getVendors()
          setAlert({
            show: true,
            type: 'success',
            message: 'Vendor deleted',
            autoHideDuration: 2000,
          })
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to delete vendor',
            autoHideDuration: 2000,
          })
        })
    }
    setConfirmDialogOpen(false)
  }

  const handleNewVendor = (): void => {
    navigate(`/vendors/add`)
  }

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: 'Name',
      field: 'longName',
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <Tooltip title="Click to view vendor details" placement="right" arrow>
            <NavLink
              aria-label="vendor Number"
              to={`/vendors/${params.data.name}`}
              style={{
                textDecoration: 'none',
              }}
            >
              {params.value}
            </NavLink>
          </Tooltip>
        )
      },
      width: 230,
    },
    {
      field: 'paymentAddress',
      minWidth: 220,
      flex: 2,
      cellRenderer: (params: ICellRendererParams<any, any>): string =>
        Object.values(params.value).filter(Boolean).join(', '),
    },

    {
      field: 'physicalAddress',
      minWidth: 220,
      flex: 2,
      cellRenderer: (params: ICellRendererParams<any, any>): string =>
        Object.values(params.value).filter(Boolean).join(', '),
    },
    { field: 'email', width: 220 },
    { field: 'phone', width: 130 },
    {
      headerName: 'Status',
      field: 'converterStatus',
      width: 120,
      cellRenderer: (params: ICellRendererParams): string | undefined =>
        ['NotStarted', 'InProgress', 'Done'].at(params.value),
    },
    {
      headerName: 'Type',
      field: 'converterType',
      width: 100,
      cellRenderer: (params: ICellRendererParams): string | undefined =>
        ['Specific', 'Default'].at(params.value),
    },
    {
      field: '',
      width: 70,
      filter: false,
      hide: !permissions?.includes('delete:vendor'),
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <IconButton
            color="error"
            onClick={(): void => handleVendorDelete(params.data)}
            disableRipple
            size="small"
          >
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        )
      },
    },
  ])

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
    }
  }, [])

  return (
    <AdminPageWrapper pageTitle="Vendors">
      <ConfirmationDialog
        open={confirmDialogOpen}
        icon="highlight_off"
        description="Are you sure you want to delete this vendor ?"
        action={
          <>
            <Button
              autoFocus
              color="primary"
              variant="contained"
              onClick={handleDeleteClose}
              disableElevation
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              color="warning"
              autoFocus
              variant="contained"
              onClick={handleDeleteConfirm}
              disableElevation
            >
              Delete
            </Button>
          </>
        }
      />
      {permissions?.includes('insert:vendor') ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            mb: 2,
          }}
        >
          <Button
            size="small"
            variant="contained"
            disableElevation
            endIcon={<AddBusinessIcon />}
            onClick={handleNewVendor}
          >
            Add New
          </Button>
        </Box>
      ) : null}
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Box
            data-testid="vendors-grid-container"
            className="ag-theme-alpine"
            width={{ md: '100%', lg: loading ? '100%' : '1350px' }}
            sx={{ height: 519, mt: 2 }}
          >
            {loading ? (
              <Loading message="Loading vendors..." height="100%" />
            ) : (
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                pagination={true}
                paginationPageSize={10}
                gridOptions={{
                  suppressCellFocus: true,
                }}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </AdminPageWrapper>
  )
}

export default Vendor
