import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import {
  ICellRendererParams,
  IDateFilterParams,
  ValueFormatterParams,
} from 'ag-grid-community'
import { parseISO } from 'date-fns'
import { format, toDate } from 'date-fns-tz'
import { RefObject } from 'react'

// AG-filter helpers
export const booleanCell = (params: ICellRendererParams): JSX.Element => {
  return params.value ? (
    <CheckIcon color="primary" fontSize="small" />
  ) : (
    <CloseIcon color="error" fontSize="small" />
  )
}

export const ternaryCell = (params: ICellRendererParams): JSX.Element => {
  return params.value ? (
    params.data.isWithNotes ? (
      <ReportProblemOutlinedIcon color="warning" fontSize="small" />
    ) : (
      <CheckIcon color="primary" fontSize="small" />
    )
  ) : (
    <CloseIcon color="error" fontSize="small" />
  )
}

export const dateFormatter = (params: ValueFormatterParams): string => {
  return params.value
    ? format(toDate(params.value, { timeZone: 'Etc/UTC' }), 'Pp')
    : ''
}

export const dateFilterParams: IDateFilterParams | any = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    // Date is coming as a date object string from API
    const dateAsString = format(parseISO(cellValue), 'MM/dd/yyyy')

    if (dateAsString === null) {
      return 0
    }
    const dateParts = dateAsString.split('/')
    const month = Number(dateParts[0]) - 1
    const day = Number(dateParts[1])
    const year = Number(dateParts[2])
    const cellDate = new Date(year, month, day)

    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
    return 0
  },
}

export const clearFilters = (gridRef: RefObject<any>): void => {
  gridRef.current?.api.setFilterModel(null)
}

export const saveFilterModel = (gridRef: RefObject<any>): any => {
  return gridRef.current?.api.getFilterModel()
}

export const restoreFilterModel = (
  gridRef: RefObject<any>,
  savedFilterModel: any
): void => {
  gridRef.current?.api.setFilterModel(savedFilterModel)
}

export const destroyFilter = (gridRef: RefObject<any>): void => {
  gridRef.current?.api.destroyFilter('athlete')
}
