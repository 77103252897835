import { AxiosResponse } from 'axios'
import { focusAtom } from 'jotai/optics'
import { atomWithDefault, splitAtom } from 'jotai/utils'
import { OpticFor } from 'optics-ts'
import AppService from 'services'
import {
  ProductItemsType,
  ProductLookupStringsType,
  ProductMappingsType,
  ServiceBillingAccount,
} from 'types/ProductMappings'

// --------------------- Product Mappings --------------
export const productMappingsAtom = atomWithDefault<ProductMappingsType>(() => ({
  id: '',
  vendorName: '',
  sites: [],
  groupedSites: [],
}))

export const groupedSitesAtom = focusAtom(productMappingsAtom, optic =>
  optic.prop('groupedSites')
)

export const groupedSitesAtomsAtom = splitAtom(groupedSitesAtom)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const focusGroupedSites = (
  optic: OpticFor<ProductMappingsType['groupedSites'][number]>
) => optic.prop('sites')

export const sitesAtom = focusAtom(productMappingsAtom, optic =>
  optic.prop('sites')
)

export const sitesAtomsAtom = splitAtom(sitesAtom)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const focusLookUpData = (
  optic: OpticFor<ProductMappingsType['sites'][number]>
) => optic.prop('lookUpData')

//-----------------------------------------------------------------------------
export const getServiceBillingAccountsByVendor = async (
  vendorName: string
): Promise<AxiosResponse<ServiceBillingAccount[], any>> => {
  try {
    return await AppService.ProductMappings.getData(
      'serviceBillingAccountsByVendor',
      { vendorName },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getProductMappingsByVendor = async (
  vendorName: string
): Promise<AxiosResponse<ProductMappingsType, any>> => {
  try {
    return await AppService.ProductMappings.getData(
      'productMappingsByVendor',
      { vendorName },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateProductMappings = async (
  productMappings: Omit<ProductMappingsType, 'groupedSites'>
): Promise<AxiosResponse<ProductMappingsType, any>> => {
  try {
    return await AppService.ProductMappings.saveData(
      'productMappings',
      productMappings
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getProductLookupStringsByVendor = async (
  vendorName: string
): Promise<AxiosResponse<ProductLookupStringsType[], any>> => {
  try {
    return await AppService.ProductMappings.getData(
      'productLookupStringsByVendor',
      { vendorName },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getProductItems = async (): Promise<
  AxiosResponse<ProductItemsType[], any>
> => {
  try {
    return await AppService.ProductMappings.getData('productItems', {}, true)
  } catch (error) {
    return Promise.reject(error)
  }
}
