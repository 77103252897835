import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import ReorderSharpIcon from '@mui/icons-material/ReorderSharp'
import {
  Box,
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { PrimitiveAtom, useAtom } from 'jotai'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'
import { LookupKeywordType } from 'types/InvoiceParamters'

const LookUpObject = ({
  lookUpAtom,
  deleteDisabled = false,
  index,
  onRowDelete,
}: {
  lookUpAtom: PrimitiveAtom<LookupKeywordType>
  deleteDisabled: boolean
  index: number
  onRowDelete: (lookUpAtom: PrimitiveAtom<LookupKeywordType>) => void
}): JSX.Element => {
  const [lookUp, setLookUp] = useAtom(lookUpAtom)
  const handleRowDelete = (): void => {
    onRowDelete(lookUpAtom)
  }

  return (
    <Draggable key={lookUp.id} draggableId={lookUp.id} index={index}>
      {(
        draggableProvided: DraggableProvided,
        snapshot: DraggableStateSnapshot
      ): JSX.Element => {
        return (
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            style={{
              ...draggableProvided.draggableProps.style,
              background: snapshot.isDragging ? 'rgba(0,164,118,0.25)' : 'none',
            }}
          >
            <TableCell
              align="left"
              width="20px"
              sx={{
                px: 0,
                verticalAlign: 'middle',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip title="Drag Row" placement="right">
                  <Box {...draggableProvided.dragHandleProps}>
                    <ReorderSharpIcon
                      sx={{ fontSize: '1.25rem', color: '#798996' }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ px: 0 }} width="20px">
              {deleteDisabled ? (
                <Tooltip title="Click To Delete" placement="right">
                  <DeleteOutlineIcon
                    color="error"
                    sx={{ fontSize: '1.1rem', ml: 1, cursor: 'pointer' }}
                    onClick={handleRowDelete}
                  />
                </Tooltip>
              ) : null}
            </TableCell>
            <TableCell
              contentEditable
              suppressContentEditableWarning
              onBlur={(event): void => {
                if (event.currentTarget.textContent !== lookUp.regExpression)
                  setLookUp(previousObj => ({
                    ...previousObj,
                    regExpression: event.currentTarget.textContent || '',
                  }))
              }}
              onPaste={(event): void => {
                const data = event.clipboardData.getData('text/plain')
                document.execCommand('insertHTML', false, data)
                event.preventDefault()
              }}
              sx={{
                '&:focus': {
                  outlineColor: '#008F6B',
                },
              }}
            >
              {lookUp.regExpression}
            </TableCell>
            <TableCell
              sx={{
                '&:focus': {
                  outlineColor: '#008F6B',
                },
              }}
              contentEditable
              suppressContentEditableWarning
              onBlur={(event): void => {
                if (
                  event.currentTarget.textContent !==
                  lookUp.followedByRegExpression
                )
                  setLookUp(previousObj => ({
                    ...previousObj,
                    followedByRegExpression:
                      event.currentTarget.textContent || '',
                  }))
              }}
              onPaste={(event): void => {
                const data = event.clipboardData.getData('text/plain')
                document.execCommand('insertHTML', false, data)
                event.preventDefault()
              }}
            >
              {lookUp.followedByRegExpression}
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  aria-label="minus"
                  color="primary"
                  size="small"
                  disabled={lookUp.startPosition <= 0}
                  onClick={(): void =>
                    setLookUp(previousObj => ({
                      ...previousObj,
                      startPosition: previousObj.startPosition - 1,
                    }))
                  }
                >
                  <RemoveCircleOutlineIcon fontSize="inherit" />
                </IconButton>
                <Typography sx={{ mx: 1 }}>{lookUp.startPosition}</Typography>
                <IconButton
                  aria-label="add"
                  color="primary"
                  size="small"
                  onClick={(): void =>
                    setLookUp(previousObj => ({
                      ...previousObj,
                      startPosition: previousObj.startPosition + 1,
                    }))
                  }
                >
                  <AddCircleOutlineIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  aria-label="minus"
                  color="primary"
                  size="small"
                  // disabled={lookUp.offset <= 0}
                  onClick={(): void =>
                    setLookUp(previousObj => ({
                      ...previousObj,
                      offset: previousObj.offset - 1,
                    }))
                  }
                >
                  <RemoveCircleOutlineIcon fontSize="inherit" />
                </IconButton>
                <Typography sx={{ mx: 1 }}>{lookUp.offset}</Typography>
                <IconButton
                  aria-label="add"
                  color="primary"
                  size="small"
                  onClick={(): void =>
                    setLookUp(previousObj => ({
                      ...previousObj,
                      offset: previousObj.offset + 1,
                    }))
                  }
                >
                  <AddCircleOutlineIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        )
      }}
    </Draggable>
  )
}

const LookupKeywords = ({
  title,
  lookUpAtoms,
  onDragEnd,
  onAddLookup,
  onRowDelete,
}: {
  title: string
  lookUpAtoms: PrimitiveAtom<LookupKeywordType>[]
  onDragEnd: (result: DropResult) => void
  onAddLookup: () => void
  onRowDelete: (lookUpAtom: PrimitiveAtom<LookupKeywordType>) => void
}): JSX.Element => {
  return (
    <>
      <TableHead>
        <TableRow
          sx={{ backgroundColor: '#21409A', '&>th': { color: 'white' } }}
        >
          <TableCell align="left" colSpan={7}>
            {title}
          </TableCell>
        </TableRow>
        <TableRow
          sx={{ backgroundColor: '#798996', '&>th': { color: 'white' } }}
        >
          <TableCell sx={{ px: 0 }} width="20px"></TableCell>
          <TableCell sx={{ px: 0 }} width="20px"></TableCell>
          <TableCell width={200}>
            <Tooltip
              title="Regular expression/exact text match"
              placement="right"
              arrow
            >
              <Typography component="span">RegEx</Typography>
            </Tooltip>
          </TableCell>
          <TableCell>Followed By RegEx</TableCell>
          <TableCell>Start Position</TableCell>
          <TableCell>Offset</TableCell>
          <TableCell colSpan={1}></TableCell>
        </TableRow>
      </TableHead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(droppableProvided: DroppableProvided): JSX.Element => (
            <TableBody
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {lookUpAtoms.map((lookUpAtom, index) => (
                <LookUpObject
                  lookUpAtom={lookUpAtom}
                  key={`${lookUpAtom}`}
                  index={index}
                  deleteDisabled={lookUpAtoms.length > 1}
                  onRowDelete={onRowDelete}
                />
              ))}
              {droppableProvided.placeholder}
              <TableRow>
                <TableCell align="left" colSpan={7}>
                  <Button
                    variant="outlined"
                    onClick={(): void => onAddLookup()}
                    size="small"
                    endIcon={<PlaylistAddIcon />}
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

export default LookupKeywords
