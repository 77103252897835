import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import Loading from 'components/molecules/Loading'
import { useAtom } from 'jotai'
import { useResetAtom } from 'jotai/utils'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { alertAtom } from 'stores'
import { accessTokenAtom } from 'stores/auth'
import {
  getProductItems,
  getProductLookupStringsByVendor,
  getProductMappingsByVendor,
  getServiceBillingAccountsByVendor,
  groupedSitesAtomsAtom,
  productMappingsAtom,
  updateProductMappings,
} from 'stores/productMappings'
import { getVendorList } from 'stores/vendor'
import {
  LookUpData,
  ProductItemsType,
  ProductLookupStringsType,
  ProductMappingsType,
  SiteType,
} from 'types/ProductMappings'
import { VendorType } from 'types/Vendor'

import Mappings from './Mappings'

const ProductMappings = (): JSX.Element => {
  const [accessToken] = useAtom(accessTokenAtom)
  const [, setAlert] = useAtom(alertAtom)
  const [productMappings, setProductMappings] = useAtom(productMappingsAtom)
  const resetProductMappings = useResetAtom(productMappingsAtom)
  const [isDirty, setIsDirty] = useState(false)
  const [loadingMappings, setLoadingMappings] = useState(false)
  const [loadingVendors, setLoadingVendors] = useState(false)
  const [selectedVendor, setVendor] = useState<VendorType | null>(null)
  const [viewHiddenLookups, setViewHiddenLookups] = useState(false)
  const [vendorsList, setVendorsList] = useState<VendorType[]>([])
  const [productMappingsSaving, setProductMappingsSaving] = useState(false)
  const [groupedSitesAtoms] = useAtom(groupedSitesAtomsAtom)
  const [lookupMappingList, setLookupMappingList] = useState<
    ProductLookupStringsType[]
  >([])
  const [productItemsList, setProductItems] = useState<ProductItemsType[]>([])
  const [loadingMessage, setLoadingMessage] = useState<string>('Loading...')
  const [replacing, setReplacing] = useState(false)

  useEffect(() => {
    if (accessToken) {
      setLoadingVendors(true)
      getVendorList()
        .then(response => {
          setVendorsList(response.data)
          setLoadingVendors(false)
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to load vendors',
            autoHideDuration: 2000,
          })
          setLoadingVendors(false)
        })
    }
  }, [accessToken])

  const handleVendorChange = (selectedValue: VendorType | null): void => {
    resetProductMappings()
    setVendor(selectedValue)
    setIsDirty(false)
    if (selectedValue) {
      getProductMappings(selectedValue)
    }
  }

  const getLookupMappingList = (
    lookups: ProductLookupStringsType[],
    site: SiteType
  ): LookUpData[] | [] => {
    if (site.serviceAccountNumber) {
      const currentLookupMappingList = lookups.find(
        lu =>
          // lu.billingAccountNumber === site.billingAccountNumber &&
          lu.serviceAccountNumber === site.serviceAccountNumber
      )
      if (
        currentLookupMappingList &&
        currentLookupMappingList.serviceAccountProduct.productLookupStrings
          .length
      ) {
        return currentLookupMappingList.serviceAccountProduct.productLookupStrings.map(
          productLookupString => ({
            itemId: '',
            fnOItemId: '',
            lookUpKeyWord: productLookupString,
            pattern: '',
            productName: '',
            dataAreaId: '',
            isDeleted: null,
            isModified: false,
          })
        )
      }
      return []
    }
    return []
  }

  const createGroupedSites = async (
    productMappings: ProductMappingsType
  ): Promise<void> => {
    productMappings.groupedSites = _(productMappings.sites)
      .groupBy('billingAccount')
      .map((items, billingAccount) => ({
        billingAccount: billingAccount,
        sites: _.sortBy(items, 'siteName'),
      }))
      .value()

    productMappings.groupedSites = _.sortBy(
      productMappings.groupedSites,
      'billingAccount'
    )

    setProductMappings(productMappings)
    setLoadingMappings(false)
    setLoadingMessage('Loading...')
  }

  const getProductMappings = async (vendor: VendorType): Promise<void> => {
    setLoadingMappings(true)

    //--------------------- BillingServiceAccounts // Sync // New Product mapppings -----------------------------------
    const newProductMappings: ProductMappingsType = {
      vendorName: vendor.name,
      sites: [],
      groupedSites: [],
    }

    setLoadingMessage('Loading billing & service accounts...')

    await getServiceBillingAccountsByVendor(vendor.name)
      .then(async serviceBillingAccountsResp => {
        if (
          serviceBillingAccountsResp.status === 200 &&
          serviceBillingAccountsResp.data &&
          serviceBillingAccountsResp.data.length
        ) {
          serviceBillingAccountsResp.data.map(serviceBillingAccount => {
            if (serviceBillingAccount) {
              const sites: any[] = serviceBillingAccount.serviceAccounts
                .map(serviceAccount => {
                  if (serviceAccount) {
                    return {
                      serviceAccountNumber: serviceAccount.serviceAccountNumber,
                      billingAccountNumber: serviceAccount.billingAccountNumber,
                      billingAccount: serviceAccount.billingAccount || '',
                      siteName: serviceAccount.serviceAccountName,
                      isActive: serviceAccount.isActive,
                      lookUpData: [],
                    }
                  }
                })
                .filter(site => site)

              if (sites && sites.length) {
                newProductMappings.sites = [
                  ...newProductMappings.sites,
                  ...sites,
                ]
              }
            }
          })
          ///

          // map lookups with productmapping lookups
          if (newProductMappings.sites.length) {
            setLoadingMessage('Loading lookup strings...')

            await getProductLookupStringsByVendor(vendor.name)
              .then(lookupStringsResponse => {
                if (lookupStringsResponse.data) {
                  setLookupMappingList(lookupStringsResponse.data)

                  newProductMappings.sites.map(site => {
                    if (!site.lookUpData.length) {
                      site.lookUpData = getLookupMappingList(
                        lookupStringsResponse.data,
                        site
                      )
                    }
                  })
                }
              })
              .catch(() => {
                setAlert({
                  show: true,
                  type: 'error',
                  message: 'Failed to load product lookup strings',
                  autoHideDuration: 2000,
                })
              })

            // Get Product FNO items mappings ----------------------------
            setLoadingMessage('Loading product items mappings...')

            await getProductItems()
              .then(productItemsResponse => {
                const productItems: any[] = []
                if (productItemsResponse.data) {
                  productItemsResponse.data.map(productItem => {
                    productItems.push({
                      ...productItem,
                      text: `${productItem.productSearchName} (${productItem.itemNumber})`,
                    })
                  })
                }

                setProductItems(productItems)
              })
              .catch(() => {
                setAlert({
                  show: true,
                  type: 'error',
                  message: 'Failed to load product items',
                  autoHideDuration: 2000,
                })
              })
          }
        }
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to fetch billing and service accounts',
          autoHideDuration: 2000,
        })
      })

    // -------------------------Saved product mappings-----------------------------
    let savedProductMappings: ProductMappingsType = {
      vendorName: vendor.name,
      sites: [],
      groupedSites: [],
    }

    setLoadingMessage('Loading saved product mappings...')

    await getProductMappingsByVendor(vendor.name)
      .then(async response => {
        // Existing Mappings
        if (response.data) {
          savedProductMappings = {
            ...response.data,
          }
        }
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to load product mappings',
          autoHideDuration: 2000,
        })
        setLoadingMappings(false)
      })

    // Check if there is any saved product mappings and merge if available--------------------
    if (
      savedProductMappings.sites.length &&
      savedProductMappings.id &&
      newProductMappings.sites.length
    ) {
      // --------------------- Merge servic Billing Accounts and existing mappings ---------------------------------
      setLoadingMessage('Syncing product mappings...')

      const mergedMappings: ProductMappingsType = {
        id: savedProductMappings.id,
        vendorName: vendor.name,
        sites: [],
        groupedSites: [],
      }

      newProductMappings.sites.forEach(function (incomingMapping) {
        const foundSavedMapping = savedProductMappings.sites.find(
          site =>
            site.serviceAccountNumber === incomingMapping.serviceAccountNumber
        )

        if (foundSavedMapping) {
          // if found insaved then update lookups
          let updatedLookUpData: LookUpData[] = []
          if (
            foundSavedMapping.lookUpData.length &&
            incomingMapping.lookUpData.length
          ) {
            incomingMapping.lookUpData.map(ilu => {
              const foundSavedMappingLookup = foundSavedMapping.lookUpData.find(
                slu => slu.lookUpKeyWord === ilu.lookUpKeyWord
              )

              if (foundSavedMappingLookup) {
                updatedLookUpData.push(foundSavedMappingLookup)
              } else {
                updatedLookUpData.push(ilu)
              }
            })
          } else {
            // If only mapping lookups exist and no lookups in lookup DB
            if (
              foundSavedMapping.lookUpData.length &&
              !incomingMapping.lookUpData.length
            ) {
              updatedLookUpData = foundSavedMapping.lookUpData.map(lu => {
                return {
                  ...lu,
                  deleted: true, // add deleted attr for not found lookup
                }
              })
            }

            if (
              !foundSavedMapping.lookUpData.length &&
              incomingMapping.lookUpData.length
            ) {
              updatedLookUpData = incomingMapping.lookUpData
            }
          }

          const updatedExistingMapping = {
            ...foundSavedMapping,
            billingAccountNumber: incomingMapping.billingAccountNumber,
            isActive: incomingMapping.isActive,
            lookUpData: updatedLookUpData,
          }
          mergedMappings.sites.push(updatedExistingMapping)
        } else {
          mergedMappings.sites.push(incomingMapping)
        }
      })

      createGroupedSites(mergedMappings)
      // ------------------------------------------------------
    } else {
      if (
        newProductMappings.sites.length &&
        !savedProductMappings.sites.length
      ) {
        // No previously saved product mappins so save newProductMappings
        createGroupedSites(newProductMappings)
      } else if (
        !newProductMappings.sites.length &&
        savedProductMappings.sites.length
      ) {
        // No previously saved product mappins so save newProductMappings
        createGroupedSites(savedProductMappings)
      } else {
        // No servicebillingaccounts
        setLoadingMessage('No data to display')
        setLoadingMappings(false)
      }
    }
  }

  const handleAllLookupsFNOItemsReplace = (
    lookup: LookUpData,
    selectedItem: ProductItemsType | null
  ): void => {
    if (lookup && lookup.lookUpKeyWord.length) {
      setReplacing(true)
      let replaceCount = 0
      const updatedGroupedSites = productMappings.groupedSites.map(
        groupedSite => {
          return {
            ...groupedSite,
            sites: groupedSite.sites.map(site => {
              return {
                ...site,
                lookUpData: site.lookUpData.map(lu => {
                  if (lu.lookUpKeyWord === lookup.lookUpKeyWord) {
                    replaceCount++
                    if (selectedItem) {
                      return {
                        ...lu,
                        itemId: selectedItem.id,
                        fnOItemId: selectedItem.itemNumber,
                        productName: selectedItem.productSearchName,
                        dataAreaId: selectedItem.dataAreaId,
                        isModified: true,
                      }
                    } else {
                      return {
                        ...lu,
                        itemId: '',
                        fnOItemId: '',
                        productName: '',
                        dataAreaId: '',
                        isModified: true,
                      }
                    }
                  } else {
                    return lu
                  }
                }),
              }
            }),
          }
        }
      )

      setProductMappings(prevMappings => ({
        ...prevMappings,
        groupedSites: updatedGroupedSites,
      }))

      setReplacing(false)

      setAlert({
        show: true,
        type: 'info',
        message: `Updated ${replaceCount} lookups`,
        autoHideDuration: 2000,
      })

      setIsDirty(true)
    }
  }

  const handleAllLookUpsPatternReplace = (
    lookup: LookUpData,
    pattern: string | null
  ): void => {
    if (lookup && lookup.lookUpKeyWord.length) {
      let replaceCount = 0
      const updatedGroupedSites = productMappings.groupedSites.map(
        groupedSite => {
          return {
            ...groupedSite,
            sites: groupedSite.sites.map(site => {
              return {
                ...site,
                lookUpData: site.lookUpData.map(lu => {
                  if (lu.lookUpKeyWord === lookup.lookUpKeyWord) {
                    replaceCount++
                    if (pattern) {
                      return {
                        ...lu,
                        pattern: pattern,
                      }
                    } else {
                      return {
                        ...lu,
                        pattern: '',
                      }
                    }
                  } else {
                    return lu
                  }
                }),
              }
            }),
          }
        }
      )

      setProductMappings(prevMappings => ({
        ...prevMappings,
        groupedSites: updatedGroupedSites,
      }))

      setAlert({
        show: true,
        type: 'info',
        message: `Updated ${replaceCount} lookups`,
        autoHideDuration: 2000,
      })

      setIsDirty(true)
    }
  }

  const handleSave = (): void => {
    if (selectedVendor) {
      setProductMappingsSaving(true)
      const sites: ProductMappingsType['sites'] = []
      const { id, vendorName } = productMappings

      productMappings.groupedSites.map(groupedSite => {
        groupedSite.sites.map(site => {
          sites.push({ ...site })
        })
      })

      updateProductMappings({ id, vendorName, sites })
        .then(() => {
          setAlert({
            show: true,
            type: 'success',
            message: 'Product mappings updated successfully',
            autoHideDuration: 2000,
          })
          setProductMappingsSaving(false)
          getProductMappings(selectedVendor)
          setIsDirty(false)
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to save product mappings',
            autoHideDuration: 2000,
          })
          setProductMappingsSaving(false)
        })
    }
  }

  const handleResetProductMappings = (): void => {
    if (selectedVendor) {
      resetProductMappings()
      getProductMappings(selectedVendor)
      setIsDirty(false)
    }
  }

  return (
    <AdminPageWrapper
      pageTitle="Product Mappings"
      sx={{ '& .admin-page-wrapper-container': { position: 'relative' } }}
    >
      {loadingVendors ? <LinearProgress sx={{ height: '2px' }} /> : null}
      <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
        <FormControlLabel
          control={
            <Switch
              checked={viewHiddenLookups}
              onChange={(): void => setViewHiddenLookups(prev => !prev)}
              size="small"
              disabled={selectedVendor ? false : true}
            />
          }
          label="Show deleted lookups"
        />
        <LoadingButton
          loading={productMappingsSaving}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          disableElevation
          size="small"
          onClick={handleSave}
          disabled={!isDirty}
          sx={{ mr: 1 }}
        >
          Save
        </LoadingButton>
        <Button
          variant="contained"
          disableElevation
          size="small"
          onClick={handleResetProductMappings}
          disabled={!isDirty}
          color="warning"
        >
          Reset
        </Button>
      </Box>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Autocomplete
            id="select-vendor"
            value={selectedVendor}
            options={vendorsList}
            getOptionLabel={(option): string => option.longName}
            renderOption={(props, option): JSX.Element => {
              return (
                <li {...props} key={option.id + option.name}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ width: '100%', wordWrap: 'break-word' }}>
                      <Box component="span" sx={{ fontWeight: 'bold' }}>
                        {option.longName}
                      </Box>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ display: 'block' }}
                      >
                        {option.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              )
            }}
            renderInput={(params): JSX.Element => (
              <TextField {...params} required label="Select Vendor" />
            )}
            onChange={(event: any, newValue: VendorType | null): void => {
              handleVendorChange(newValue)
            }}
            isOptionEqualToValue={(option, value): boolean =>
              option.id === value.id
            }
            size="small"
            disabled={loadingVendors || loadingMappings}
            sx={{ width: { sm: '100%', md: '33.333333%', lg: '25%' } }}
          />
          {selectedVendor ? (
            <Box
              className="product-mappings-wrapper"
              sx={{ mt: 3, minHeight: 519 }}
            >
              {loadingMappings ? (
                <Loading message={loadingMessage} height="32.4375rem" />
              ) : (
                <Mappings
                  setIsDirty={setIsDirty}
                  groupedSitesAtoms={groupedSitesAtoms}
                  lookupMappingList={lookupMappingList}
                  productItemsList={productItemsList}
                  onAllLookUpsFNOItemsReplace={handleAllLookupsFNOItemsReplace}
                  replacingMappings={replacing}
                  onAllLookUpsPatternReplace={handleAllLookUpsPatternReplace}
                  viewHiddenLookups={viewHiddenLookups}
                />
              )}
            </Box>
          ) : null}
        </CardContent>
      </Card>
    </AdminPageWrapper>
  )
}

export default ProductMappings
