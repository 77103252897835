import { AxiosResponse } from 'axios'
import { sortBy } from 'lodash'
import AppService from 'services'
import { HaulerSetting, Haulers } from 'types/Hauler'

export const getHaulerSettingList = async (): Promise<
  AxiosResponse<Haulers, any>
> => {
  try {
    return await AppService.Hauler.getData('haulersList', {}, true).then(
      (resp: AxiosResponse<HaulerSetting[], any>) => {
        // Sort haulers by long name
        if (resp.data.length) {
          resp.data = sortBy(resp.data, 'longName')
        }
        return resp
      }
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteHauler = async (
  vendorName: string
): Promise<AxiosResponse<any, any>> => {
  try {
    return await AppService.Hauler.deleteData('hauler', { vendorName })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getHaulerSettingByvendor = async (
  vendorName: string
): Promise<AxiosResponse<HaulerSetting, any>> => {
  try {
    return await AppService.Hauler.getData(
      'haulerSettingByvendor',
      { vendorName },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addHaulerSettings = async (
  hauler: HaulerSetting
): Promise<AxiosResponse<HaulerSetting | string, any>> => {
  try {
    return await AppService.Hauler.saveData('addHaulerSetting', hauler)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateHaulerSettings = async (
  hauler: HaulerSetting
): Promise<AxiosResponse<Haulers>> => {
  try {
    return await AppService.Hauler.saveData('updateHaulerSetting', {
      ...hauler,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}
