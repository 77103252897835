// import { ErrorMessage } from '@hookform/error-message'
import { Box, Button } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { FormProps } from 'types/DynamicFormFieldcontrol'

import DynamicControl from './DynamicFormFieldControl'

const DynamicForm = ({
  fields,
  submitButtonText = 'Submit',
  onSubmit,
}: FormProps): JSX.Element => {
  const formMethods = useForm()
  const {
    handleSubmit,
    formState: {
      isSubmitting,
      // errors
    },
  } = formMethods

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .field': {
          marginTop: '1rem',
        },
      }}
    >
      <FormProvider {...formMethods}>
        {fields.map((d, i) => (
          <Box key={i} className="field">
            <DynamicControl {...d} />
            {/* <ErrorMessage errors={errors} name={d.fieldName} /> */}
          </Box>
        ))}
      </FormProvider>
      <Button
        type="submit"
        variant="outlined"
        disabled={isSubmitting}
        sx={{ mt: 2 }}
      >
        {isSubmitting ? 'Submitting' : submitButtonText}
      </Button>
    </Box>
  )
}

export default DynamicForm
