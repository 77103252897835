import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import InfoIcon from '@mui/icons-material/Info'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import ReorderSharpIcon from '@mui/icons-material/ReorderSharp'
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material'
import { PrimitiveAtom, useAtom } from 'jotai'
import { uniqueId } from 'lodash'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'
import {
  filterAddCellStartStopAtom,
  filterAddCellStartStopAtomsAtom,
} from 'stores/invoice'
import { FilterStartStopKeywordType } from 'types/InvoiceParamters'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(11),
    maxWidth: 550,
    maxHeight: 400,
    overflowY: 'scroll',
  },
}))

const StartStopFilter = ({
  startStopFilterAtom,
  deleteDisabled = false,
  index,
  onRowDelete,
}: {
  startStopFilterAtom: PrimitiveAtom<FilterStartStopKeywordType>
  deleteDisabled: boolean
  index: number
  onRowDelete: (
    startStopFilterAtom: PrimitiveAtom<FilterStartStopKeywordType>
  ) => void
}): JSX.Element => {
  const [startStopFilter, setStartStopFilter] = useAtom(startStopFilterAtom)

  const handleRowDelete = (): void => {
    onRowDelete(startStopFilterAtom)
  }

  return (
    <Draggable
      key={startStopFilter.id}
      draggableId={startStopFilter.id}
      index={index}
    >
      {(
        draggableProvided: DraggableProvided,
        snapshot: DraggableStateSnapshot
      ): JSX.Element => {
        return (
          <TableRow
            hover
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            style={{
              ...draggableProvided.draggableProps.style,
              background: snapshot.isDragging ? 'rgba(0,164,118,0.25)' : 'none',
            }}
          >
            <TableCell
              align="left"
              width="20px"
              sx={{
                px: 0,
                verticalAlign: 'middle',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip title="Drag Row" placement="right">
                  <Box {...draggableProvided.dragHandleProps}>
                    <ReorderSharpIcon
                      sx={{ fontSize: '1.25rem', color: '#798996' }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ px: 0 }} width="20px">
              {deleteDisabled ? (
                <Tooltip title="Click To Delete" placement="right">
                  <DeleteOutlineIcon
                    color="error"
                    sx={{ fontSize: '1.1rem', ml: 1, cursor: 'pointer' }}
                    onClick={handleRowDelete}
                  />
                </Tooltip>
              ) : null}
            </TableCell>
            <TableCell
              contentEditable
              suppressContentEditableWarning
              onBlur={(event): void => {
                if (
                  event.currentTarget.textContent !== startStopFilter.keyword
                ) {
                  setStartStopFilter(previousObj => ({
                    ...previousObj,
                    keyword: event.currentTarget.textContent || '',
                  }))
                }
                event.preventDefault()
                event.stopPropagation()
              }}
              onPaste={(event): void => {
                const data = event.clipboardData.getData('text/plain')
                document.execCommand('insertHTML', false, data)
                event.preventDefault()

                event.stopPropagation()
              }}
              sx={{
                '&:focus': {
                  outlineColor: '#008F6B',
                },
              }}
            >
              {startStopFilter.keyword}
            </TableCell>
            <TableCell
              sx={{
                '&:focus': {
                  outlineColor: '#008F6B',
                },
              }}
              contentEditable
              suppressContentEditableWarning
              onBlur={(event): void => {
                if (
                  event.currentTarget.textContent !== startStopFilter.followedBy
                )
                  setStartStopFilter(previousObj => ({
                    ...previousObj,
                    followedBy: event.currentTarget.textContent || '',
                  }))
              }}
              onPaste={(event): void => {
                const data = event.clipboardData.getData('text/plain')
                document.execCommand('insertHTML', false, data)
                event.preventDefault()
              }}
            >
              {startStopFilter.followedBy}
            </TableCell>
            <TableCell>
              <Stack direction="row" spacing={0} alignItems="center">
                <Typography variant="small">Stop</Typography>
                <Switch
                  checked={startStopFilter.startAddingCell}
                  size="small"
                  onChange={(event): void =>
                    setStartStopFilter(previousObj => ({
                      ...previousObj,
                      startAddingCell: event.target.checked,
                    }))
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography variant="small">Start</Typography>
              </Stack>
            </TableCell>
          </TableRow>
        )
      }}
    </Draggable>
  )
}

const StartStopFilters = (): JSX.Element => {
  const [startStopFilterAtoms, setStartStopFilterAtoms] = useAtom(
    filterAddCellStartStopAtomsAtom
  )

  const [startStopFilter, setStartStopFilter] = useAtom(
    filterAddCellStartStopAtom
  )

  const handleDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const updatesFilters: any = Array.from(startStopFilter)
    const [removed] = updatesFilters.splice(result.source.index, 1)
    updatesFilters.splice(result.destination.index, 0, removed)
    setStartStopFilter(updatesFilters)
  }

  const onAddStartStopFilter = (): void => {
    setStartStopFilterAtoms({
      type: 'insert',
      value: {
        id: uniqueId('FSS_'),
        keyword: '',
        followedBy: '',
        startAddingCell: false,
      },
    })
  }

  const handleFilterDelete = (
    startStopFilterAtom: PrimitiveAtom<FilterStartStopKeywordType>
  ): void => {
    setStartStopFilterAtoms({
      type: 'remove',
      atom: startStopFilterAtom,
    })
  }

  return (
    <>
      <TableHead>
        <TableRow
          sx={{ backgroundColor: '#21409A', '&>th': { color: 'white' } }}
        >
          <TableCell align="left" colSpan={7}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Stop/Start Filters</span>
              <HtmlTooltip
                title={
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: '100%',
                      bgcolor: 'inherit',
                    }}
                  >
                    <Typography color="inherit" sx={{ px: 2 }} variant="button">
                      Filter Helper Functions
                    </Typography>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="SPLIT(keyword,splitter)"
                        secondary={
                          <Typography
                            sx={{
                              display: 'inline',
                              color: '#fff',
                              fontWeight: 400,
                            }}
                            component="span"
                            variant="small"
                          >
                            Where the keyword is text that is contained in a
                            cell and the splitter is the text used to separate
                            the cell into new cells. THERE IS NO SPACE AFTER THE
                            COMMA this is very important because if you put a
                            space it will be treated as part of the splitter so
                            if you have SPLIT(Date:, :) it won&apos;t work
                            because there isn&apos;t a space before the colon in
                            the keyword.
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="REMOVE(keyword)"
                        secondary={
                          <Typography
                            sx={{
                              display: 'inline',
                              color: '#fff',
                              fontWeight: 400,
                            }}
                            component="span"
                            variant="small"
                          >
                            Where the cells are searched and any that contain
                            the keyword are removed entirely. Good for when the
                            lines are mixed up between two invoices and an
                            offset that matches on one doesn&apos;t match for
                            another. Here on these wall recycling invoices some
                            times the RTS address is jumped up between the
                            header lookup keyword and the actual address.
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="REGEXREMOVE(Regular Expression)"
                        secondary={
                          <Typography
                            sx={{
                              display: 'inline',
                              color: '#fff',
                              fontWeight: 400,
                            }}
                            component="span"
                            variant="small"
                          >
                            Where the cells are searched and any that match the
                            regex expression are removed. This can be used to
                            remove cells that might have the same keyword as a
                            cell you want to keep without removing both. This is
                            an invoice from a casella invoice that had a
                            description line broken up into multiple cells.
                            Rather than create a line with 2 description fields
                            and risk it mapping poorly I wanted to just remove
                            the # P/U: since it is not useful for the
                            description. However there are other lines where the
                            # P/U is in a single line with the description so a
                            regular REMOVE(# P/U:) would remove those lines
                            entirely and I&apos;d lose the description. example:
                            It can also be used to simplify a lot of REMOVE()s
                            by allowing for \d to match digits such as
                            REGEXREMOVE(\dxWeekly) to remove text like
                            2xWeekly,3xWeekly,4xWeekly
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                }
              >
                <IconButton
                  aria-label="helper functions"
                  color="primary"
                  size="small"
                  sx={{ p: 0 }}
                >
                  <InfoIcon fontSize="inherit" />
                </IconButton>
              </HtmlTooltip>
            </Box>
          </TableCell>
        </TableRow>
        <TableRow
          sx={{ backgroundColor: '#798996', '&>th': { color: 'white' } }}
        >
          <TableCell sx={{ px: 0 }} width="20px"></TableCell>
          <TableCell sx={{ px: 0 }} width="20px"></TableCell>
          <TableCell width="190px">Keyword</TableCell>
          <TableCell width="190px">Followed By</TableCell>
          <TableCell width="100px">Start/Stop</TableCell>
        </TableRow>
      </TableHead>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(droppableProvided: DroppableProvided): JSX.Element => (
            <TableBody
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {startStopFilterAtoms.map((startStopFilterAtom, index) => (
                <StartStopFilter
                  startStopFilterAtom={startStopFilterAtom}
                  key={`${startStopFilterAtom}`}
                  deleteDisabled={startStopFilterAtoms.length > 1}
                  index={index}
                  onRowDelete={handleFilterDelete}
                />
              ))}

              {droppableProvided.placeholder}
              <TableRow>
                <TableCell align="left" colSpan={7} sx={{ px: 0 }}>
                  <Tooltip
                    title="Click to add new Stop/Start filter"
                    placement="right"
                    arrow
                  >
                    <Button
                      variant="outlined"
                      onClick={onAddStartStopFilter}
                      size="small"
                      endIcon={<PlaylistAddIcon />}
                    >
                      Add
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

export default StartStopFilters
