export const hiddenCols: any = {
  companyInfo: [],
  invoiceAccountSummaryHeader: [
    'invoiceTotalD',
    'chargesCreditsD',
    'acctBalanceD',
  ],
  unfilteredInvoicePageCells: [],
  filteredInvoicePageCells: [],
  headerIndexesList: [],
  footerIndexesList: [],
  siteHeaders: [
    'tableNum',
    'lineNum',
    'cellNum',
    'date',
    'refNumber',
    'po',
    'description',
    'units',
    'unitPrice',
    'linePrice',
    'subtotalSum',
    'unitsD',
    'unitPriceD',
    'linePriceD',
    'subtotalSumD',
    'serviceAccount',
  ],
  siteFooters: [
    'tableNum',
    'lineNum',
    'cellNum',
    'header',
    'date',
    'refNumber',
    'po',
    'units',
    'unitPrice',
    'linePrice',
    'unitsD',
    'unitPriceD',
    'linePriceD',
    'accountLookupString',
    'serviceAccount',
  ],
  lineItems: [
    'tableNum',
    'lineNum',
    'cellNum',
    'subtotalSum',
    'unitsD',
    'unitPriceD',
    'linePriceD',
    'subtotalSumD',
    'accountLookupString',
    'serviceAccount',
  ],
  errorList: [],
}

export const parametersInit: any = {
  vendorName: '',
  company: {
    companyName: '',
    companyEmail: '',
    companyPhone: '',
  },
  accountLookupKeywords: [
    {
      regExpression: '',
      startPosition: 0,
      offset: 0,
      followedByRegExpression: null,
    },
  ],
  invoiceNumberLookupKeywords: [
    {
      regExpression: '',
      startPosition: 0,
      offset: 0,
      followedByRegExpression: null,
    },
  ],
  invoiceDateLookupKeywords: [
    {
      regExpression: '',
      startPosition: 0,
      offset: 0,
      followedByRegExpression: null,
    },
  ],
  invoiceTotalLookupKeywords: [
    {
      regExpression: '',
      startPosition: 0,
      offset: 0,
      followedByRegExpression: null,
    },
  ],
  headerLookupKeywords: [
    {
      regExpression: '',
      startPosition: 0,
      offset: 0,
      followedByRegExpression: null,
    },
  ],
  footerLookupKeywords: [
    {
      regExpression: '',
      startPosition: 0,
      offset: 0,
      followedByRegExpression: null,
    },
  ],
  filterAddCellStartStopKeywords: [
    {
      keyword: '',
      followedBy: '',
      startAddingCell: false,
    },
  ],
  customInvoiceLines: [
    {
      regExpression: '',
      description: '',
      cellColumnMappings: [],
      matchCellOffset: 0,
      continueToNextLineIfNull: false,
    },
  ],
  recalculateSubTotals: false,
}
