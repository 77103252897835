import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import HighligtedTabelCell from 'components/atoms/HighligtedTabelCell'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/pages/Invoice/Details'
import { useState } from 'react'
import { RawInvoiceRow } from 'types/InvoiceDetails'

const RawInvoiceLines = ({
  rawInvoiceLines,
}: {
  rawInvoiceLines: RawInvoiceRow[]
}): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <Accordion
      expanded={expanded === 'rawInvoiceLines'}
      onChange={handleChange('rawInvoiceLines')}
    >
      <AccordionSummary
        aria-controls="rawInvoiceLines"
        id="rawInvoiceLines-header"
      >
        <Typography>Raw Invoice lines</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {rawInvoiceLines && rawInvoiceLines.length ? (
          <TableContainer>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <HighligtedTabelCell width="350" dark>
                    Account Number
                  </HighligtedTabelCell>
                  <HighligtedTabelCell dark>Line Content</HighligtedTabelCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rawInvoiceLines.map((rawInvoiceLine, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:nth-of-type(odd)': {
                          backgroundColor: '#F5F7FA',
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 1,
                        }}
                      >
                        {rawInvoiceLine.accountNumber}
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 1,
                        }}
                      >
                        {rawInvoiceLine.lineContent}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography sx={{ p: 2 }}>No Data Found</Typography>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default RawInvoiceLines
