import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DescriptionIcon from '@mui/icons-material/Description'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import {
  Box,
  Icon,
  IconButton,
  Link,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { format, toDate } from 'date-fns-tz'
import { useState } from 'react'
import { InvoiceStatusDetailsType } from 'types/apDashboard'

const TimeLineItemComponent = ({
  title,
  subTitle,
  value,
  valueIsDate = false,
  valueIsLink = false,
  success = true,
  alert = false,
  info = false,
  icon,
  align = undefined,
  clickAndCopy = false,
  valueSx,
}: {
  title: string
  subTitle?: string
  value: string | boolean | number
  valueIsDate?: boolean
  valueIsLink?: boolean
  success?: boolean
  alert?: boolean
  info?: boolean
  icon?: string
  align?: 'start' | 'end'
  clickAndCopy?: boolean
  valueSx?: SxProps<Theme>
}): JSX.Element => {
  return (
    <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: 'auto 0' }}
        variant="body2"
        color="highlight"
      >
        {valueIsLink && typeof value === 'string' ? (
          <>
            <Link
              component="a"
              variant="body2"
              sx={[
                {
                  wordBreak: 'break-all',
                  cursor: 'pointer',
                },
                ...(Array.isArray(valueSx) ? valueSx : [valueSx]),
              ]}
              onClick={(): void => {
                if (value) {
                  const newWindow = window.open(
                    value,
                    '_blank',
                    'noopener,noreferrer'
                  )
                  if (newWindow) {
                    newWindow.opener = null
                  }
                }
              }}
            >
              {subTitle ? subTitle : 'Link'}
              <Icon sx={{ fontSize: '0.8rem', color: 'GrayText' }}>
                open_in_new_outlined
              </Icon>
            </Link>
            {clickAndCopy && typeof subTitle === 'string' && subTitle.length ? (
              <Tooltip title="Click to copy text" placement="right" arrow>
                <IconButton
                  aria-label="copy"
                  disableRipple
                  sx={{
                    fontSize: '0.85rem',
                    p: 0,
                    ml: 0.5,
                    position: 'relative',
                    top: '-1px',
                    color: 'GrayText',
                  }}
                  onClick={(): Promise<void> =>
                    navigator.clipboard.writeText(subTitle)
                  }
                >
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            ) : null}
          </>
        ) : null}

        {valueIsDate && typeof value === 'string' ? (
          <Typography
            textTransform="uppercase"
            sx={[
              {
                wordBreak: 'break-all',
              },
              ...(Array.isArray(valueSx) ? valueSx : [valueSx]),
            ]}
          >
            {format(toDate(value, { timeZone: 'Etc/UTC' }), 'Pp') ||
              'Not Available'}
          </Typography>
        ) : null}

        {!valueIsDate && !valueIsLink && value ? (
          <Typography
            textTransform="uppercase"
            sx={[
              {
                wordBreak: 'break-all',
              },
              ...(Array.isArray(valueSx) ? valueSx : [valueSx]),
            ]}
          >
            {value}
            {clickAndCopy &&
            typeof value === 'string' &&
            value !== 'NA' &&
            value.length ? (
              <Tooltip title="Click to copy text" placement="right" arrow>
                <IconButton
                  aria-label="copy"
                  disableRipple
                  sx={{
                    fontSize: '0.85rem',
                    p: 0,
                    ml: 0.5,
                    position: 'relative',
                    top: '-1px',
                    color: 'GrayText',
                  }}
                  onClick={(): Promise<void> =>
                    navigator.clipboard.writeText(value)
                  }
                >
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Typography>
        ) : null}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot
          color={
            alert ? 'warning' : info ? 'info' : success ? 'primary' : 'error'
          }
          variant="outlined"
        >
          {icon ? (
            <Icon
              color={
                alert
                  ? 'warning'
                  : info
                  ? 'info'
                  : success
                  ? 'primary'
                  : 'error'
              }
            >
              {icon}
            </Icon>
          ) : (
            <DescriptionIcon />
          )}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent
        sx={{
          py: '12px',
          px: 2,
          alignItems: align,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          textTransform="uppercase"
          fontWeight="bold"
          color={
            alert
              ? 'warning.main'
              : info
              ? 'info.main'
              : success
              ? 'primary.main'
              : 'error.main'
          }
        >
          {title}
        </Typography>
        {subTitle && !valueIsLink ? (
          <Typography sx={{ wordBreak: 'break-all' }}>{subTitle}</Typography>
        ) : null}
      </TimelineContent>
    </TimelineItem>
  )
}

const TimeLine = ({
  data,
}: {
  data: InvoiceStatusDetailsType
}): JSX.Element => {
  const [alignment, setAlignment] = useState<'left' | 'alternate' | 'right'>(
    'left'
  )
  const handleChange = (
    event: any,
    newAlignment: 'left' | 'alternate' | 'right'
  ): void => {
    setAlignment(newAlignment)
  }
  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  }
  return data ? (
    <Box>
      <ToggleButtonGroup
        size="small"
        {...control}
        aria-label="position"
        color="primary"
      >
        <ToggleButton value="left" key="left">
          <Icon>format_align_left_outlined</Icon>
        </ToggleButton>
        <ToggleButton value="alternate" key="alternate">
          <Icon>alt_route_outlined</Icon>
        </ToggleButton>
        <ToggleButton value="right" key="right">
          <Icon>format_align_right_outlined</Icon>
        </ToggleButton>
      </ToggleButtonGroup>

      <Timeline position={alignment}>
        <TimeLineItemComponent
          title="Invoice source"
          value={data.sourceValue}
          subTitle={data.sourceType}
          icon={
            data.sourceType === 'NA'
              ? 'folder_off_outlined'
              : data.sourceType === 'mail'
              ? 'mail_outlined'
              : 'source_outlined'
          }
          success={data.sourceType?.length > 0 && data.sourceType !== 'NA'}
          clickAndCopy
        />
        <TimeLineItemComponent
          title="Invoice File"
          subTitle={data.originalFileName}
          value={data.fileName}
          icon="description_outlined"
          success={data.originalFileName?.length > 0}
          clickAndCopy
        />
        <TimeLineItemComponent
          title="Invoice Url"
          valueIsLink={true}
          value={data.blobUrl}
          subTitle={data.originalFileName}
          icon={
            data.blobUrl?.length > 0 ? 'link_outlined' : 'link_off_outlined'
          }
          success={data.blobUrl?.length > 0}
        />
        <TimeLineItemComponent
          title="created"
          valueIsDate={true}
          value={data.createdDateTime}
          icon="event_outlined"
          success={data.createdDateTime?.length > 0}
        />
        <TimeLineItemComponent
          title="duplicate check"
          valueIsDate={true}
          value={data.duplicateCheckedDateTime}
          success={!data.duplicateFound}
          icon="content_copy"
          subTitle={data.duplicateFound ? 'Is Duplicate' : 'Not a Duplicate'}
        />
        <TimeLineItemComponent
          title="AI Model Convertor"
          value={data.aiModelConvertor}
          icon="model_training_outlined"
          success={data.aiModelConvertor?.length > 0}
          clickAndCopy
        />
        <TimeLineItemComponent
          title="invoice Converted"
          valueIsDate={true}
          value={data.invoiceConvertedDateTime}
          success={data.invoiceConverted}
          icon={
            data.invoiceConverted
              ? 'published_with_changes_outlined'
              : 'unpublished_outlined'
          }
        />
        <TimeLineItemComponent
          title="vendor"
          valueIsLink={true}
          value={`/vendors/${data.vendorId}`}
          subTitle={data.vendorId}
          icon="store_outlined"
          success={data.vendorId?.length > 0}
          clickAndCopy
        />
        <TimeLineItemComponent
          title="invoice Number"
          valueIsLink={true}
          value={`/invoices/${data.invoiceDbId}`}
          subTitle={data.invoiceNumber}
          icon="tagOutlined"
          success={data.invoiceNumber?.length > 0}
          clickAndCopy
        />
        <TimeLineItemComponent
          title="invoice Date"
          value={data.invoiceDate}
          icon="eventOutlined"
          success={data.invoiceDate?.length > 0}
        />
        <TimeLineItemComponent
          title="invoice Total"
          value={`$${data.invoiceTotal}`}
          success={data.invoiceTotal > 0}
          icon={
            data.invoiceTotal > 0
              ? 'attach_money_outlined'
              : 'money_off_csred_outlined'
          }
        />

        <TimeLineItemComponent
          title="variance Check"
          valueIsDate={true}
          value={data.varianceCheckedDateTime}
          success={data.varianceChecked}
          icon={data.varianceChecked ? 'troubleshoot' : 'search_off_outlined'}
        />
        {/* <TimeLineItemComponent title="haulerId" value={data.haulerId} /> */}
        <TimeLineItemComponent
          title="FNO Header Created"
          valueIsDate={true}
          value={data.fnOHeaderCreatedDateTime}
          success={data.fnOHeaderCreated}
          icon="view_headline_outlined"
        />
        <TimeLineItemComponent
          title="FNO Line Items Sent"
          valueIsDate={true}
          value={data.fnOLineItemsSentDateTime}
          success={data.fnOLineItemsSent}
          icon={
            data.fnOLineItemsSent ? 'playlist_add_check' : 'playlist_remove'
          }
        />
        <TimeLineItemComponent
          title="FNO Attachment Sent"
          valueIsDate={true}
          value={data.fnOAttachmentSentDateTime}
          success={data.fnOAttachmentSent}
          icon="attach_file_outlined"
        />
        <TimeLineItemComponent
          title="sent to Kofax"
          valueIsDate={data.sentKofaxDateTime ? true : false}
          value={data.sentKofaxDateTime ? data.sentKofaxDateTime : 'No'}
          success={!data.sentToKofax}
          icon="send_time_extension_outlined"
        />
        {data.result?.length && data.result !== 'Success' ? (
          <TimeLineItemComponent
            title="result Details"
            value={data.result}
            success={data.result?.length > 0}
            icon="comment_outlined"
            valueSx={{
              fontSize: '0.8rem',
              textTransform: 'unset',
              color: 'ButtonText',
            }}
            clickAndCopy
          />
        ) : null}
        <TimeLineItemComponent
          title="Theta Result"
          valueIsDate={true}
          value={data.failedDateTime}
          success={!data.failed}
          alert={data.isWithNotes}
          icon={data.failed ? 'remove_done' : 'done_all'}
        />

        <TimeLineItemComponent
          title="FNO Post Status"
          value={
            data.fnoPostStatus === ''
              ? data.invoiceFinalSuccess
                ? 'In Progress'
                : 'Failed'
              : data.fnoPostStatus === 'InvoicePending'
              ? 'Pending'
              : data.fnoPostStatus === 'InvoicePaid'
              ? 'Paid'
              : data.fnoPostStatus === 'InvoicePosted'
              ? 'Posted'
              : 'Unknown'
          }
          success={
            !data.invoiceFinalSuccess && data.fnoPostStatus === ''
              ? false
              : data.fnoPostStatus === 'InvoicePaid'
              ? true
              : false
          }
          alert={data.fnoPostStatus === 'InvoicePending'}
          info={data.fnoPostStatus === 'InvoicePosted'}
          icon="verified_outlined"
        />

        {/* <TimeLineItemComponent title="modifiedBy" value={data.modifiedBy} /> */}
      </Timeline>
    </Box>
  ) : (
    <p>No Time line data found</p>
  )
}

export default TimeLine
