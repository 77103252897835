//import AddIcon from '@mui/icons-material/Add'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled'
import ErrorIcon from '@mui/icons-material/Error'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LinkIcon from '@mui/icons-material/Link'
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import {
  Autocomplete,
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import HighligtedTabelCell from 'components/atoms/HighligtedTabelCell'
import { PrimitiveAtom, useAtom } from 'jotai'
import { focusAtom } from 'jotai/optics'
import { splitAtom } from 'jotai/utils'
import { focusLookUpData, productMappingsAtom } from 'stores/productMappings'
import {
  LookUpData,
  ProductItemsType,
  ProductLookupStringsType,
  ProductMappingsType,
} from 'types/ProductMappings'

import MappingLookup from './MappingLookup'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    elevation={0}
    square
    {...props}
    TransitionProps={{ unmountOnExit: true }}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}))

const AccordionHeader = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '&.Mui-expanded': {
    minHeight: '3rem',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0!important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const Site = ({
  siteAtom,
  index,
  isMissingBillingAccount,
  lookupMappingList,
  productItemsList,
  viewHiddenLookups,
  replacingMappings,
  onSiteDelete,
  onSiteDuplicate,
  setIsDirty,
  onAllLookUpsFNOItemsReplace,
  onAllLookUpsPatternReplace,
}: {
  siteAtom: PrimitiveAtom<ProductMappingsType['sites'][number]>
  index: number
  isMissingBillingAccount: boolean
  lookupMappingList: ProductLookupStringsType[]
  productItemsList: ProductItemsType[]
  viewHiddenLookups: boolean
  replacingMappings: boolean
  onSiteDelete: (
    siteAtom: PrimitiveAtom<ProductMappingsType['sites'][number]>
  ) => void
  onAllLookUpsFNOItemsReplace: (
    lookup: LookUpData,
    productItem: ProductItemsType | null
  ) => void
  onAllLookUpsPatternReplace: (
    lookup: LookUpData,
    pattern: string | null
  ) => void
  onSiteDuplicate: (
    index: number,
    site: ProductMappingsType['sites'][number]
  ) => void
  setIsDirty: (isDirty: boolean) => void
}): JSX.Element => {
  const [site, setSite] = useAtom(siteAtom)
  const lookUpsAtom = focusAtom(siteAtom, focusLookUpData)
  const lookUpAtomsAtom = splitAtom(lookUpsAtom)
  const [lookUpAtoms, setLookUpAtoms] = useAtom(lookUpAtomsAtom)
  const [allProductMappings] = useAtom(productMappingsAtom)

  const getAllBillingAccounts = (): string[] => {
    return allProductMappings.groupedSites
      .map(groupedSite => groupedSite.billingAccount)
      .filter(groupedSite => groupedSite)
  }

  const handleSiteDelete = (event: React.MouseEvent): void => {
    event.stopPropagation()
    onSiteDelete(siteAtom)
  }

  // const handleLookUpAdd = (): void => {
  //   setLookUpAtoms({
  //     type: 'insert',
  //     value: {
  //       itemId: '',
  //       dataAreaId: '',
  //       fnOItemId: '',
  //       lookUpKeyWord: '',
  //       pattern: null,
  //       productName: '',
  //       isDeleted: null,
  //       isModified: true,
  //     },
  //   })
  // }

  const handleLookUpDelete = (
    lookUpAtom: PrimitiveAtom<
      ProductMappingsType['sites'][number]['lookUpData'][number]
    >,
    lookUp: LookUpData
  ): void => {
    if (lookUpAtom) {
      if (
        lookUp &&
        lookUp.lookUpKeyWord &&
        lookUp.lookUpKeyWord.trim().length === 0
      ) {
        setLookUpAtoms({
          type: 'remove',
          atom: lookUpAtom,
        })
      }
      setIsDirty(true)
    }
  }

  const handleSiteDuplicate = (event: React.MouseEvent): void => {
    event.stopPropagation()
    onSiteDuplicate(index, site)
  }

  const handleInvoiceFileClick = (event: React.MouseEvent): void => {
    event.stopPropagation()
    const newWindow = window.open(
      getLookupMappingFileURL(),
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) {
      newWindow.opener = null
    }
  }

  const getServiceAccountProduct = (): ProductLookupStringsType | null => {
    if (site.serviceAccountNumber) {
      const currentLookupMappingList = lookupMappingList.find(
        lookupMapping =>
          // lookupMapping.billingAccount === site.billingAccount &&
          lookupMapping.serviceAccountNumber === site.serviceAccountNumber
      )

      if (currentLookupMappingList) {
        return currentLookupMappingList
      }

      return null
    }
    return null
  }

  const getLookupMappingList = (): { text: string }[] => {
    const productLookupStrings = getServiceAccountProduct()
    if (productLookupStrings) {
      const lookups =
        productLookupStrings.serviceAccountProduct.productLookupStrings

      if (lookups.length) {
        return lookups.map(lookup => ({
          text: lookup,
        }))
      }
    }
    return []
  }

  const getLookupMappingFileURL = (): string | undefined => {
    const productLookupStrings = getServiceAccountProduct()

    return productLookupStrings &&
      productLookupStrings.serviceAccountProduct &&
      productLookupStrings.serviceAccountProduct.fileUrl
      ? productLookupStrings.serviceAccountProduct.fileUrl
      : undefined
  }

  const hasMissingFNOItemIds = (): boolean => {
    const MissingFNOItemIds = site.lookUpData.filter(
      lookup =>
        !lookup.fnOItemId && !lookup.fnOItemId.length && !lookup.isDeleted
    )

    return !!MissingFNOItemIds.length
  }

  return (
    <Accordion className="site-container">
      <AccordionHeader expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Click to edit site name" arrow placement="top">
            <Typography
              contentEditable
              suppressContentEditableWarning
              onClick={(event): void => {
                event.stopPropagation()
              }}
              onBlur={(event): void => {
                if (event.currentTarget.textContent !== site.siteName) {
                  setIsDirty(true)
                  setSite(prevSite => ({
                    ...prevSite,
                    siteName: event.currentTarget.textContent || '',
                  }))
                }
              }}
              onPaste={(event): void => {
                const data = event.clipboardData.getData('text/plain')
                document.execCommand('insertHTML', false, data)
                event.preventDefault()
              }}
              component="span"
              sx={{
                height: '1.5rem',
                padding: '.1875rem .3125rem 0 .3125rem',
                fontWeight: 300,
                fontFamily: 'Circular Std Book',
                outline: site.siteName.length ? 'none' : '1px solid #EF314E',
                '&:focus': {
                  minWidth: '9.375rem',
                  fontWeight: 'normal',
                  outline: '1px solid #008F6B',
                  marginRight: '0.5rem',
                },
              }}
            >
              {site.siteName}
            </Typography>
          </Tooltip>

          {site.serviceAccountNumber ? (
            <Tooltip title="Service Account Number" arrow placement="top">
              <Typography variant="small">
                ( {site.serviceAccountNumber} )
              </Typography>
            </Tooltip>
          ) : null}

          {!site.isActive ? (
            <Tooltip title="Inactive service account" arrow placement="right">
              <DomainDisabledIcon
                sx={{ fontSize: '1rem', ml: 0.5 }}
                color="error"
              />
            </Tooltip>
          ) : null}

          {getLookupMappingList().length === 0 ? (
            <Tooltip
              title="Missing lookups strings or lookup string got deleted from collection."
              arrow
              placement="right"
            >
              <ReportProblemIcon
                sx={{ fontSize: '1rem', ml: 0.5 }}
                color="error"
              />
            </Tooltip>
          ) : null}

          {hasMissingFNOItemIds() ? (
            <Tooltip title="Missing FNO items" arrow placement="right">
              <ErrorIcon sx={{ fontSize: '1rem', ml: 0.5 }} color="warning" />
            </Tooltip>
          ) : null}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {getLookupMappingFileURL() ? (
            <Tooltip
              title="Lookup reference Invoice link"
              arrow
              placement="top"
            >
              <IconButton
                color="info"
                size="small"
                sx={{ mr: 1, p: 0 }}
                onClick={handleInvoiceFileClick}
              >
                <LinkIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : null}

          {!isMissingBillingAccount && site.isActive ? (
            <Tooltip
              title="Duplicate this site with lookups"
              arrow
              placement="top"
            >
              <IconButton
                color="primary"
                size="small"
                sx={{ mr: 1, p: 0 }}
                onClick={handleSiteDuplicate}
                disabled={site.siteName ? false : true}
              >
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : null}

          <Tooltip title="Delete Site" arrow placement="top">
            <IconButton
              color="error"
              size="small"
              sx={{ p: 0 }}
              onClick={(event): void => handleSiteDelete(event)}
            >
              <PlaylistRemoveIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </AccordionHeader>
      <AccordionDetails>
        {isMissingBillingAccount ? (
          <Box
            sx={{
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              options={getAllBillingAccounts()}
              onChange={(event: any, newValue: string | null): void => {
                const value = newValue ? newValue : ''
                if (value !== site.billingAccount) {
                  setIsDirty(true)
                  setSite(prevSite => ({
                    ...prevSite,
                    billingAccount: value,
                  }))
                }
              }}
              sx={{
                '& .MuiInputBase-root': { borderRadius: 0 },
                minWidth: '250px',
                mr: 2,
              }}
              renderInput={(params): JSX.Element => (
                <TextField
                  required
                  {...params}
                  error={!site.billingAccount.length}
                  label="Billing Account"
                />
              )}
            />
          </Box>
        ) : null}

        {!site.serviceAccountNumber || site.siteName.includes('(COPY)') ? (
          <Box
            sx={{
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            <TextField
              required
              label="Service Account Number"
              size="small"
              value={site.serviceAccountNumber}
              placeholder="Service Account Number"
              error={!site.serviceAccountNumber}
              onChange={(event): void => {
                if (event.target.value !== site.serviceAccountNumber) {
                  setIsDirty(true)
                  setSite(prevSite => ({
                    ...prevSite,
                    serviceAccountNumber: event.target.value,
                  }))
                }
              }}
              sx={{
                '& .MuiInputBase-root': { borderRadius: 0 },
                minWidth: '250px',
                mr: 2,
              }}
            />
          </Box>
        ) : null}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ mr: 1 }} variant="button">
            LOOKUPS
          </Typography>
          {/* <Button
            variant="outlined"
            disableElevation
            size="small"
            startIcon={<AddIcon />}
            onClick={handleLookUpAdd}
            sx={{ mb: 0.5 }}
            color="primary"
            disabled={isMissingBillingAccount || !site.isActive}
          >
            Lookup
          </Button> */}
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="lookup table">
            <TableHead>
              <TableRow sx={{ fontWeight: 800 }}>
                <HighligtedTabelCell
                  sx={{ fontWeight: 'inherit' }}
                  dark
                  width={320}
                >
                  LookUp Key Word
                </HighligtedTabelCell>
                <HighligtedTabelCell
                  sx={{ fontWeight: 'inherit' }}
                  dark
                  width={320}
                >
                  FNO Item Id
                </HighligtedTabelCell>
                <HighligtedTabelCell
                  sx={{ fontWeight: 'inherit' }}
                  dark
                  width={320}
                >
                  Pattern
                </HighligtedTabelCell>
                <HighligtedTabelCell align="right" dark>
                  &nbsp;
                </HighligtedTabelCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lookUpAtoms.map(lookUpAtom => (
                <MappingLookup
                  key={`${lookUpAtom}`}
                  lookUpAtom={lookUpAtom}
                  productItemsList={productItemsList}
                  viewHiddenLookups={viewHiddenLookups}
                  replacingMappings={replacingMappings}
                  setIsDirty={setIsDirty}
                  lookupMappingList={getLookupMappingList()}
                  onLookUpDelete={handleLookUpDelete}
                  onAllLookUpsFNOItemsReplace={onAllLookUpsFNOItemsReplace}
                  onAllLookUpsPatternReplace={onAllLookUpsPatternReplace}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  )
}

export default Site
