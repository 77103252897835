import { TableCell, styled, tableCellClasses } from '@mui/material'

const HighligtedTabelCell = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'dark',
})<{
  dark?: boolean
}>(({ theme, dark = false }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: dark
      ? theme.palette.secondary.dark
      : theme.palette.secondary.main,
    color: dark ? theme.palette.common.white : theme.palette.common.white,
    padding: '.375rem .5rem',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

export default HighligtedTabelCell
