import { AxiosResponse } from 'axios'
import {
  InvoiceListFilterParamsType,
  InvoiceStatusDetailsType,
  InvoiceStatusListType,
} from 'types/apDashboard'

import { axiosInstance } from '.'
import AppService from './appService'

const values = {}

const getters = {
  invoiceStatusList(
    params?: InvoiceListFilterParamsType
  ): Promise<AxiosResponse<InvoiceStatusListType[], any>> {
    return axiosInstance.get('/invoice-status-list', {
      params: params,
    })
  },

  invoiceStatusDetailsById(params: {
    id: string
  }): Promise<AxiosResponse<InvoiceStatusDetailsType, any>> {
    return axiosInstance.get(`/invoice-status/${params.id}`)
  },
}

const saves = {}

const deletes = {}

export default class ApDashboardService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
