import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  createFilterOptions,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { DynamicFieldData } from 'types/DynamicFormFieldcontrol'

import DynamicForm from './DynamicForm'

// export interface any {
//   inputValue?: string
//   selected: string
//   value?: number | string
//   [key: string]: string | undefined | number
// }

const filter = createFilterOptions<any>()

const AutocompleteCreateOptionDialog = ({
  label = undefined,
  dialogTitle = 'Add a new',
  options,
  dialogDescription = undefined,
  createNewFormFields,
  selected = undefined,
  width = 300,
  allowCreate = false,
  autoHighlight = false,
  autoSelect = false,
  disabled = false,
  error = false,
  errorMessage = undefined,
  onSelect,
  onCreateNewOptionSubmit,
}: {
  label?: string | undefined
  dialogTitle?: string
  options: any[]
  dialogDescription?: string | undefined
  createNewFormFields: DynamicFieldData[]
  selected?: any
  width?: number
  allowCreate?: boolean
  autoHighlight?: boolean
  autoSelect?: boolean
  disabled?: boolean
  error?: boolean
  errorMessage?: string
  onSelect: (option: any) => void
  onCreateNewOptionSubmit?: (data: any) => void
}): JSX.Element => {
  const [open, toggleOpen] = useState(false)

  const handleClose = (): void => {
    setDialogValue(null)
    if (onCreateNewOptionSubmit) toggleOpen(false)
  }

  const [dialogValue, setDialogValue] = useState<any>(null)

  const handleSubmit = (data: any): void => {
    if (onCreateNewOptionSubmit) {
      onCreateNewOptionSubmit(data)
    }

    onSelect({
      selected: dialogValue.text,
    })
    handleClose()
  }

  return (
    <Fragment>
      <Autocomplete
        id="createOptionDialog"
        value={selected}
        onChange={(event, newValue): void => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              if (onCreateNewOptionSubmit) toggleOpen(true)
              setDialogValue(newValue)
            })
          } else if (newValue && newValue.inputValue) {
            if (onCreateNewOptionSubmit) toggleOpen(true)
            setDialogValue(newValue.inputValue)
          } else {
            onSelect(newValue)
          }
        }}
        filterOptions={(options, params): any[] => {
          const filtered = filter(options, params)

          if (
            params.inputValue !== '' &&
            allowCreate &&
            createNewFormFields.length
          ) {
            filtered.push({
              inputValue: params.inputValue,
              text: `Add "${params.inputValue}"`,
            })
          }

          return filtered
        }}
        options={options}
        getOptionLabel={(option): string => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return option.text
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option): JSX.Element => (
          <li {...props} key={`${option.text}${option.id ? option.id : ''}`}>
            {option.text}
          </li>
        )}
        sx={{
          width: width,
        }}
        freeSolo
        renderInput={(params): JSX.Element => (
          <TextField
            {...params}
            label={label}
            size="small"
            error={error}
            helperText={error ? errorMessage : undefined}
          />
        )}
        autoHighlight={autoHighlight}
        autoSelect={autoSelect}
        disabled={disabled}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {dialogDescription ? (
            <DialogContentText>{dialogDescription}</DialogContentText>
          ) : null}
          <DynamicForm fields={createNewFormFields} onSubmit={handleSubmit} />
          {/* <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.text}
              onChange={(event): void =>
                setDialogValue({
                  ...dialogValue,
                  selected: event.target.value,
                })
              }
              label="text"
              type="text"
              variant="standard"
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.value}
              onChange={(event): void =>
                setDialogValue({
                  ...dialogValue,
                  value: event.target.value,
                })
              }
              label="value"
              type="number"
              variant="standard"
            /> */}
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default AutocompleteCreateOptionDialog
