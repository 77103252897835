import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import RuleIcon from '@mui/icons-material/Rule'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

// eslint-disable-next-line react/display-name
export default forwardRef((props: IFilterParams, ref) => {
  const [value, setValue] = useState<string | undefined>(undefined)

  const isFilterActive = (): boolean => {
    return value != undefined
  }

  useEffect(() => {
    props.filterChangedCallback()
  }, [value])

  useImperativeHandle(ref, () => ({
    doesFilterPass: (params: IDoesFilterPassParams): boolean => {
      if (props.colDef.field) {
        if (value === 'yes') {
          return params.data[props.colDef.field] === true
        }
        if (value === 'no') {
          return params.data[props.colDef.field] === false
        }
      }
      return true
    },

    isFilterActive,

    getModel: (): any => {
      if (isFilterActive()) {
        return null
      }

      return { value: value }
    },

    setModel: (model: any): void => {
      setValue(model == null ? undefined : model.value)
    },
  }))

  const handleOnChange = (event: any): void => {
    setValue(event.target.value)
  }

  return (
    <RadioGroup
      row
      defaultValue=""
      aria-labelledby="filter-group-label"
      name="row-radio-buttons-group"
      onChange={handleOnChange}
      sx={{ m: 1 }}
    >
      <FormControlLabel
        value=""
        control={<Radio size="small" />}
        label={<RuleIcon color="primary" fontSize="small" />}
      />
      <FormControlLabel
        value="yes"
        control={<Radio size="small" />}
        label={<CheckIcon color="primary" fontSize="small" />}
      />
      <FormControlLabel
        value="no"
        control={<Radio size="small" />}
        label={<CloseIcon color="error" fontSize="small" />}
      />
    </RadioGroup>
  )
})
