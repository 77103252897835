import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import Loading from 'components/molecules/Loading'
import { format, toDate } from 'date-fns-tz'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { alertAtom } from 'stores'
import { accessTokenAtom } from 'stores/auth'
import {
  getInvoiceListByVendorName,
  savedInvoiceGridFilterModelAtom,
  selectedVendorAtom,
} from 'stores/invoice'
import { getVendorList } from 'stores/vendor'
import { VendorType } from 'types/Vendor'
import {
  clearFilters,
  dateFilterParams,
  restoreFilterModel,
  saveFilterModel,
} from 'utils/ag-grid'

const dateFormatter = (params: ValueFormatterParams): string => {
  return params.value
    ? format(toDate(params.value, { timeZone: 'Etc/UTC' }), 'Pp')
    : ''
}

const Invoice = (): JSX.Element => {
  const [accessToken] = useAtom(accessTokenAtom)
  const [, setAlert] = useAtom(alertAtom)
  const [loadingInvoices, setLoadingInvoices] = useState(false)
  const [loadingVendors, setLoadingVendors] = useState(false)
  const [invoiceList, setInvoiceList] = useState<any[]>([])
  const [selectedVendor, setVendor] = useAtom(selectedVendorAtom)
  const [vendorsList, setVendorsList] = useState<VendorType[]>([])
  const [inputValue, setInputValue] = useState('')

  const [filterModel, setFilterModel] = useAtom(savedInvoiceGridFilterModelAtom)
  const gridRef = useRef<AgGridReact<VendorType>>(null)

  useEffect(() => {
    if (accessToken) {
      setLoadingVendors(true)
      getVendorList()
        .then(response => {
          setVendorsList(response.data)
          setLoadingVendors(false)
          if (selectedVendor) {
            handleVendorChange(selectedVendor)
          }
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to load vendors',
            autoHideDuration: 2000,
          })
          setLoadingVendors(false)
        })
    }
  }, [accessToken])

  const handleVendorChange = (
    selectedValue: VendorType | null,
    resetFilter = false
  ): void => {
    if (resetFilter) {
      setFilterModel(null)
      clearFilters(gridRef)
    }
    setVendor(selectedValue)
    if (selectedValue) {
      setLoadingInvoices(true)
      setInvoiceList([])
      getInvoiceListByVendorName(selectedValue.name)
        .then(response => {
          if (response.data) {
            setInvoiceList(response.data.filter(data => data.invoiceNumber))
            setLoadingInvoices(false)
          }
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to load invoice list',
            autoHideDuration: 2000,
          })
          setLoadingInvoices(false)
        })
    }
  }

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'invoiceNumber',
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tooltip
              title="Click to view invoice details"
              placement="right"
              arrow
            >
              <NavLink
                onClick={(): void => {
                  setFilterModel(saveFilterModel(gridRef))
                }}
                aria-label="invoice Number"
                to={`/invoices/${params.data.id}`}
                style={{
                  textDecoration: 'none',
                }}
              >
                {params.value}
              </NavLink>
            </Tooltip>
          </Box>
        )
      },
      minWidth: 190,
      flex: 1,
    },
    {
      headerName: 'Processed',
      field: 'isProcessed',
      cellStyle: { textAlign: 'center' },
      width: 115,
      cellRenderer: (params: ICellRendererParams): JSX.Element => {
        return params.value ? (
          <CheckIcon color="primary" fontSize="small" />
        ) : (
          <CloseIcon color="error" fontSize="small" />
        )
      },
    },

    { field: 'accountNumber', width: 165 },
    { field: 'invoiceDate', width: 130 },
    { field: 'invoiceTotal', width: 130 },
    { field: 'chargeCredits', width: 150 },
    {
      field: 'createdAt',
      width: 180,
      valueFormatter: dateFormatter,
      sort: 'desc',
      filter: 'date',
      filterParams: dateFilterParams,
    },
  ])

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
    }
  }, [])

  const onGridReady = (): void => {
    if (filterModel) {
      restoreFilterModel(gridRef, filterModel)
      // clearFilters(gridRef)
    }
  }

  return (
    <AdminPageWrapper pageTitle="Invoices">
      {loadingVendors ? <LinearProgress sx={{ height: '2px' }} /> : null}
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Autocomplete
            id="select-vendor"
            value={selectedVendor}
            inputValue={inputValue}
            options={vendorsList}
            getOptionLabel={(option): string => option.longName}
            renderOption={(props, option): JSX.Element => {
              return (
                <li {...props} key={option.id + option.name}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ width: '100%', wordWrap: 'break-word' }}>
                      <Box component="span" sx={{ fontWeight: 'bold' }}>
                        {option.longName}
                      </Box>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ display: 'block' }}
                      >
                        {option.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              )
            }}
            renderInput={(params): JSX.Element => (
              <TextField {...params} required label="Select Vendor" />
            )}
            isOptionEqualToValue={(option, value): boolean =>
              option.id === value.id
            }
            onChange={(event: any, newValue: VendorType | null): void => {
              handleVendorChange(newValue, true)
            }}
            onInputChange={(event, newInputValue): void => {
              setInputValue(newInputValue)
            }}
            size="small"
            disabled={loadingVendors || loadingInvoices}
            sx={{ width: { sm: '100%', md: '33.333333%', lg: '25%' } }}
          />
          {selectedVendor ? (
            <Box
              data-testid="invoices-grid-container"
              className="ag-theme-alpine"
              width={{ md: '100%', lg: loadingInvoices ? '100%' : '67.5rem' }}
              sx={{ height: 519, mt: 2 }}
            >
              {loadingInvoices ? (
                <Loading message="Loading invoices..." height="100%" />
              ) : (
                <AgGridReact
                  ref={gridRef}
                  rowData={invoiceList}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={10}
                  gridOptions={{
                    suppressCellFocus: true,
                  }}
                  onGridReady={onGridReady}
                />
              )}
            </Box>
          ) : null}
        </CardContent>
      </Card>
    </AdminPageWrapper>
  )
}

export default Invoice
