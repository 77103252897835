import { Card, CardContent, CardHeader, CircularProgress } from '@mui/material'
import { ComputedBarDatum, ResponsiveBar } from '@nivo/bar'
import { Margin, SvgFillMatcher } from '@nivo/core'
// import { BasicTooltip } from '@nivo/tooltip'
import { useState } from 'react'

const BarChart = ({
  title,
  subTitle,
  data,
  loading,
  height = 350,
  maxWidth = 400,
  showLegends = true,
  xLabel,
  yLabel,
  keys,
  defs = undefined,
  fill = undefined,
  boxShadow = undefined,
  colorSchemeId = 'green_blue',
  onHoverChangeColorSchemeId = false,
  margin = { top: 0, right: 120, bottom: 50, left: 50 },
}: {
  title?: string
  subTitle?: string
  data: {
    label: string
    completed: number
    inProgress: number
    onHold: number
    cancelled: number
  }[]
  loading: boolean
  height?: number
  maxWidth?: number | string
  showLegends?: boolean
  xLabel: string
  yLabel: string
  colorSchemeId?: any
  onHoverChangeColorSchemeId?: boolean
  boxShadow?: 0 | 1 | 2 | 3 | undefined
  keys: string[]
  defs?:
    | {
        [key: string]: any
        id: string
      }[]
    | undefined
  fill?:
    | {
        id: string
        match:
          | Record<string, unknown>
          | '*'
          | SvgFillMatcher<
              ComputedBarDatum<{
                label: string
                completed: number
                inProgress: number
                onHold: number
                cancelled: number
              }>
            >
      }[]
    | undefined
  margin?: Partial<Margin> | undefined
}): JSX.Element => {
  const [hover, setHover] = useState<boolean>(false)

  const toTitleCase = (value: string | number): string | number => {
    if (typeof value === 'string') {
      return value.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    } else {
      return value
    }
  }

  return (
    <Card sx={{ maxWidth: maxWidth, boxShadow: boxShadow }} elevation={0}>
      {title || subTitle ? (
        <CardHeader title={title} subheader={subTitle} />
      ) : null}
      <CardContent
        style={{
          height: height,
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
        onMouseEnter={(): void => setHover(true)}
        onMouseLeave={(): void => setHover(false)}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <ResponsiveBar
            colors={{
              scheme: onHoverChangeColorSchemeId
                ? hover
                  ? colorSchemeId
                  : 'blues'
                : colorSchemeId,
            }}
            data={data}
            keys={keys}
            indexBy="label"
            margin={margin}
            padding={0.4}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            defs={defs}
            fill={fill}
            isInteractive={true}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: xLabel,
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: yLabel,
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            legends={
              showLegends
                ? [
                    {
                      toggleSerie: true,
                      dataFrom: 'keys',
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]
                : undefined
            }
            role="chart"
            ariaLabel={title ? title : 'Barchart'}
            // tooltip={(props: any) => {
            //   return (
            //     <BasicTooltip
            //       id={toTitleCase(props.id)}
            //       value={JSON.stringify(props)}
            //       enableChip
            //     />
            //   )
            // }}
            barAriaLabel={(e): string =>
              `${toTitleCase(e.id)} : ${e.formattedValue} in ${xLabel}: ${
                e.indexValue
              }`
            }
          />
        )}
      </CardContent>
    </Card>
  )
}

export default BarChart
