import { AxiosResponse } from 'axios'
import {
  ProductItemsType,
  ProductLookupStringsType,
  ProductMappingsType,
} from 'types/ProductMappings'

import { axiosInstance } from '.'
import AppService from './appService'

const values = {}

const getters = {
  serviceBillingAccountsByVendor(params: {
    vendorName: string
  }): Promise<AxiosResponse<ProductMappingsType, any>> {
    return axiosInstance.get(`/servicebillingaccounts/${params.vendorName}`)
  },

  productMappingsByVendor(params: {
    vendorName: string
  }): Promise<AxiosResponse<ProductMappingsType, any>> {
    return axiosInstance.get(`/productmappings/${params.vendorName}`)
  },

  productLookupStringsByVendor(params: {
    vendorName: string
  }): Promise<AxiosResponse<ProductLookupStringsType[], any>> {
    return axiosInstance.get(`/productLookupStrings/${params.vendorName}`)
  },

  productItems(): Promise<AxiosResponse<ProductItemsType[], any>> {
    return axiosInstance.get('/productItems')
  },
}

const saves = {
  productMappings(
    productMappings: ProductMappingsType
  ): Promise<AxiosResponse<ProductMappingsType | string, any>> {
    if (productMappings.id) {
      return axiosInstance.put('/productmappings', productMappings)
    } else {
      return axiosInstance.post('/productmappings', productMappings)
    }
  },
}

const deletes = {}

export default class ProductMappingsService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
