import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import GroupedInvoiceLine from 'components/atoms/GroupedInvoiceLine'
import HighligtedTabelCell from 'components/atoms/HighligtedTabelCell'
import { PrimitiveAtom, useAtom } from 'jotai'
import { focusAtom } from 'jotai/optics'
import { splitAtom } from 'jotai/utils'
import { useEffect, useState } from 'react'
import { focusInvoiceLines, focusInvoiceSubTotalLines } from 'stores/invoice'
import { InvoiceDetailsType } from 'types/InvoiceDetails'
import { currencyFormatter, thousandsFormatter } from 'utils'

const GroupedInvoiceLines = ({
  collapseAll,
  groupedInvoiceLinesAtom,
  isDirty,
}: {
  collapseAll: boolean
  groupedInvoiceLinesAtom: PrimitiveAtom<
    InvoiceDetailsType['groupedInvoiceLines'][number]
  >
  isDirty: (dirty: boolean) => void
}): JSX.Element => {
  const [open, setOpen] = useState(collapseAll)

  const [groupedInvoiceLines, setGroupedInvoiceLines] = useAtom(
    groupedInvoiceLinesAtom
  )
  const invoiceLinesAtom = focusAtom(groupedInvoiceLinesAtom, focusInvoiceLines)
  const invoiceLineAtomsAtom = splitAtom(invoiceLinesAtom)
  const [invoiceLineAtoms] = useAtom(invoiceLineAtomsAtom)

  // Sub total
  const invoiceSubTotalLineAtom = focusAtom(
    groupedInvoiceLinesAtom,
    focusInvoiceSubTotalLines
  )

  const [, updateSubTotal] = useAtom(invoiceSubTotalLineAtom)

  useEffect(() => {
    let subTotal = 0
    groupedInvoiceLines.invoiceLines.map(invoiceLine => {
      subTotal = subTotal + invoiceLine.linePriceD
    })

    updateSubTotal(prevObject => ({
      ...prevObject,
      subtotalSumD: parseFloat(subTotal.toFixed(2)),
      subtotalSum: thousandsFormatter(subTotal),
    }))
  }, [groupedInvoiceLines.invoiceLines])

  useEffect(() => {
    setOpen(collapseAll)
  }, [collapseAll])

  return (
    <>
      <TableRow
        hover
        onClick={(): void => setOpen(!open)}
        sx={{ '& > *': { borderBottom: 'unset', p: '.375rem .5rem' } }}
      >
        <TableCell size="small" sx={{ p: '.375rem 0.5rem' }} colSpan={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              aria-label="expand row"
              size="small"
              title={open ? 'Collapse' : 'Expand'}
            >
              {open ? (
                <KeyboardArrowUpIcon color="secondary" />
              ) : (
                <KeyboardArrowDownIcon color="disabled" />
              )}
            </IconButton>
            <Typography
              variant="body2"
              sx={{ padding: '.375rem .5rem' }}
              contentEditable={false}
              suppressContentEditableWarning
              onBlur={(event): void =>
                setGroupedInvoiceLines(prevObject => ({
                  ...prevObject,
                  name: event.currentTarget.textContent || '',
                }))
              }
            >
              {groupedInvoiceLines.accountNumber}
            </Typography>
          </Box>
        </TableCell>
        <TableCell
          scope="row"
          size="small"
          align="right"
          sx={{ padding: '.375rem .5rem' }}
        >
          {!open
            ? currencyFormatter(groupedInvoiceLines.subTotalLine.subtotalSumD)
            : ''}
        </TableCell>
      </TableRow>
      <TableRow sx={{ display: open ? 'table-row' : 'none' }}>
        <TableCell size="small" colSpan={3} sx={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <HighligtedTabelCell width="10%" dark>
                    Date
                  </HighligtedTabelCell>
                  <HighligtedTabelCell width="25%" dark>
                    Reference#
                  </HighligtedTabelCell>
                  <HighligtedTabelCell width="10%" dark>
                    PO#
                  </HighligtedTabelCell>
                  <HighligtedTabelCell width="25%" dark>
                    Description
                  </HighligtedTabelCell>
                  <HighligtedTabelCell width="10%" dark align="right">
                    Units
                  </HighligtedTabelCell>
                  <HighligtedTabelCell width="10%" dark align="right">
                    $/Unit
                  </HighligtedTabelCell>
                  <HighligtedTabelCell width="10%" dark align="right">
                    Line Price
                  </HighligtedTabelCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {invoiceLineAtoms.map(invoiceLineAtom => (
                    <GroupedInvoiceLine
                      invoiceLineAtom={invoiceLineAtom}
                      key={`${invoiceLineAtom}`}
                      isDirty={isDirty}
                    />
                  ))}
                  <GroupedInvoiceLine
                    invoiceLineAtom={invoiceSubTotalLineAtom}
                    isDirty={isDirty}
                  />
                </>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default GroupedInvoiceLines
