import { FindReplace } from '@mui/icons-material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ErrorIcon from '@mui/icons-material/Error'
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  IconButton,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import AutocompleteCreateOptionDialog from 'components/atoms/AutocompleteCreateOptionDialog'
import ConfirmationDialog from 'components/atoms/ConfirmationDialog'
import HighligtedTabelCell from 'components/atoms/HighligtedTabelCell'
import { PrimitiveAtom, useAtom } from 'jotai'
import { useRef, useState } from 'react'
import theme from 'theme'
import { DynamicFieldData } from 'types/DynamicFormFieldcontrol'
import {
  LookUpData,
  ProductItemsType,
  ProductMappingsType,
} from 'types/ProductMappings'

export const newProductLookupfields: DynamicFieldData[] = [
  {
    fieldName: 'LokupKeyWord',
    inputType: 'text',
    label: 'Lokup Key Word',
    placeHolder: 'Lokup Key Word',
    defaultValue: '',
    config: {
      required: 'Required',
    },
  },
  {
    fieldName: 'productName',
    inputType: 'text',
    label: 'Product Name',
    placeHolder: 'Product Name',
    defaultValue: '',
    config: {
      required: 'Required',
    },
  },
  {
    fieldName: 'dataAreaId',
    inputType: 'select',
    label: 'Data Area Id',
    options: [
      { value: 'usa1', label: 'USA 1' },
      { value: 'uda2', label: 'USA 2' },
    ],
    defaultValue: 'usa1',
  },
  {
    fieldName: 'FNOItemId',
    inputType: 'number',
    label: 'FNO Item Id',
    defaultValue: null,
    config: {
      required: 'Required',
      minLength: 9,
    },
  },

  {
    fieldName: 'pattern',
    inputType: 'text',
    label: 'Pattern',
    defaultValue: '',
  },
]

const MappingLookup = ({
  lookUpAtom,
  viewHiddenLookups,
  lookupMappingList,
  productItemsList,
  replacingMappings,
  onLookUpDelete,
  setIsDirty,
  onAllLookUpsFNOItemsReplace,
  onAllLookUpsPatternReplace,
}: {
  lookUpAtom: PrimitiveAtom<
    ProductMappingsType['sites'][number]['lookUpData'][number]
  >
  viewHiddenLookups: boolean
  replacingMappings: boolean
  lookupMappingList: any[] | []
  productItemsList: ProductItemsType[]
  onLookUpDelete: (
    lookUpAtom: PrimitiveAtom<
      ProductMappingsType['sites'][number]['lookUpData'][number]
    >,
    lookUp: LookUpData
  ) => void
  setIsDirty: (isDirty: boolean) => void
  onAllLookUpsPatternReplace: (
    lookup: LookUpData,
    pattern: string | null
  ) => void
  onAllLookUpsFNOItemsReplace: (
    lookup: LookUpData,
    productItem: ProductItemsType | null
  ) => void
}): JSX.Element | null => {
  const patternRef = useRef<any>(null)
  const [lookUp, setLookUp] = useAtom(lookUpAtom)
  const [selectedItem, setSelectedItem] = useState<ProductItemsType | null>(
    null
  )

  const [pattern, setPattern] = useState<string | null>(null)
  const [confirmFNOItemDialogOpen, setConfirmFNOItemDialogOpen] =
    useState(false)
  const [confirmPatternDialogOpen, setConfirmPatternDialogOpen] =
    useState(false)

  const handleLookUpDelete = (isDeleted: boolean): void => {
    setLookUp(previousLookup => ({
      ...previousLookup,
      isDeleted: isDeleted,
    }))
    onLookUpDelete(lookUpAtom, lookUp)
  }

  const handleLookupSelection = (selected: any): void => {
    if (selected && selected.text) {
      setLookUp(previousLookup => ({
        ...previousLookup,
        lookUpKeyWord: selected.text,
      }))

      setIsDirty(true)
    }
  }

  const handleProductItemSelection = (selected: any): void => {
    setSelectedItem(selected)
    setConfirmFNOItemDialogOpen(true)
  }

  const handlePatternChange = (pattern: string): void => {
    setPattern(pattern)
    setConfirmPatternDialogOpen(true)
  }

  const handleFNOItemsReplaceCurrentLookup = (): void => {
    if (selectedItem) {
      setLookUp(previousLookup => ({
        ...previousLookup,
        itemId: selectedItem.id,
        fnOItemId: selectedItem.itemNumber,
        productName: selectedItem.productSearchName,
        dataAreaId: selectedItem.dataAreaId,
        isModified: true,
      }))
    } else {
      setLookUp(previousLookup => ({
        ...previousLookup,
        itemId: '',
        fnOItemId: '',
        productName: '',
        dataAreaId: '',
        isModified: true,
      }))
    }

    setConfirmFNOItemDialogOpen(false)
    setSelectedItem(null)
    setIsDirty(true)
  }

  const handleCancelFNOItemsReplace = (): void => {
    setConfirmFNOItemDialogOpen(false)
    setSelectedItem(null)
  }

  const handleFNOItemsReplaceAllLookups = (): void => {
    onAllLookUpsFNOItemsReplace(lookUp, selectedItem)
    setSelectedItem(null)
    setConfirmFNOItemDialogOpen(false)
  }

  //Pattern
  const handleReplacePatternCurrentLookup = (): void => {
    setConfirmPatternDialogOpen(false)
    if (pattern) {
      setLookUp(previousLookup => ({
        ...previousLookup,
        pattern: pattern,
      }))
    } else {
      setLookUp(previousLookup => ({
        ...previousLookup,
        pattern: '',
      }))
    }

    setPattern(null)
    setIsDirty(true)
  }

  const handleCancelPatternReplace = (): void => {
    if (patternRef && patternRef.current && patternRef.current.innerText) {
      patternRef.current.innerText = ''
    }
    setPattern(null)
    setConfirmPatternDialogOpen(false)
  }

  const handleReplaceAllPatternsLookups = (): void => {
    onAllLookUpsPatternReplace(lookUp, pattern)
    setPattern(null)
    setConfirmPatternDialogOpen(false)
  }

  const getSelectedLookUpKeyWord = (): any => {
    return (
      lookupMappingList.find(lu => lu.text === lookUp.lookUpKeyWord) || null
    )
  }

  const getSelectedProductItem = (): any =>
    productItemsList.find(item => item.itemNumber === lookUp.fnOItemId) || null

  return viewHiddenLookups || !lookUp.isDeleted ? (
    <TableRow
      key={`${lookUpAtom}`}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        '&:nth-of-type(odd)': {
          backgroundColor: '#F5F7FA',
        },
      }}
    >
      <ConfirmationDialog
        open={confirmFNOItemDialogOpen}
        description={`Replace/Replace all FNO items which matches "${lookUp.lookUpKeyWord}" keyword ?`}
        icon="published_with_changes"
        iconColor={theme.palette.warning.main}
        action={
          <>
            <Button
              autoFocus
              color="primary"
              variant="contained"
              onClick={handleCancelFNOItemsReplace}
              disableElevation
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              color="warning"
              variant="contained"
              onClick={handleFNOItemsReplaceCurrentLookup}
              disableElevation
              sx={{ mr: 1 }}
            >
              replace
            </Button>
            <LoadingButton
              loading={replacingMappings}
              loadingPosition="start"
              startIcon={<FindReplace />}
              color="warning"
              autoFocus
              variant="contained"
              onClick={handleFNOItemsReplaceAllLookups}
              disableElevation
            >
              replace all
            </LoadingButton>
          </>
        }
      />

      <ConfirmationDialog
        open={confirmPatternDialogOpen}
        description={`Replace/Replace all patterns which matches "${lookUp.lookUpKeyWord}" keyword ?`}
        icon="published_with_changes"
        iconColor={theme.palette.warning.main}
        action={
          <>
            <Button
              autoFocus
              color="primary"
              variant="contained"
              onClick={handleCancelPatternReplace}
              disableElevation
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              color="warning"
              variant="contained"
              onClick={handleReplacePatternCurrentLookup}
              disableElevation
              sx={{ mr: 1 }}
            >
              replace
            </Button>
            <Button
              color="warning"
              autoFocus
              variant="contained"
              onClick={handleReplaceAllPatternsLookups}
              disableElevation
            >
              replace all
            </Button>
          </>
        }
      />
      <HighligtedTabelCell sx={{ px: 0 }}>
        {lookUp.deleted ? (
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                width: '90%',
                borderRadius: '.25rem',
                p: '.375rem',
                border: '1px solid rgba(0, 0, 0, 0.23)',
              }}
            >
              {lookUp.lookUpKeyWord}
            </Typography>
            <Tooltip
              title="Lookup string got deleted from collection."
              arrow
              placement="right"
            >
              <ErrorIcon sx={{ fontSize: '1rem', ml: 0.5 }} color="warning" />
            </Tooltip>
          </Box>
        ) : (
          <AutocompleteCreateOptionDialog
            options={lookupMappingList}
            createNewFormFields={newProductLookupfields}
            dialogTitle="Add New Lookup"
            dialogDescription="Add New lookup by filling the below details!"
            selected={getSelectedLookUpKeyWord()}
            onSelect={handleLookupSelection}
            error={lookUp.deleted}
          />
        )}
      </HighligtedTabelCell>

      <HighligtedTabelCell sx={{ px: 0 }}>
        <AutocompleteCreateOptionDialog
          options={productItemsList}
          createNewFormFields={newProductLookupfields}
          selected={getSelectedProductItem()}
          onSelect={handleProductItemSelection}
          autoHighlight={false}
          autoSelect={false}
        />
      </HighligtedTabelCell>

      <HighligtedTabelCell sx={{ px: 0 }}>
        <Typography
          contentEditable
          suppressContentEditableWarning
          key={lookUp.pattern}
          ref={patternRef}
          onBlur={(event): void => {
            if (event.currentTarget.textContent !== lookUp.pattern)
              handlePatternChange(event.currentTarget.textContent || '')
          }}
          onPaste={(event): void => {
            const data = event.clipboardData.getData('text/plain')
            document.execCommand('insertHTML', false, data)
            event.preventDefault()
          }}
          sx={{
            width: '320px',
            borderRadius: '.25rem',
            p: '.375rem',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            '&:focus': {
              outlineColor: '#008F6B',
            },
          }}
        >
          {lookUp.pattern}
        </Typography>
      </HighligtedTabelCell>

      <HighligtedTabelCell align="left">
        <Tooltip
          title={lookUp.isDeleted ? 'Restore Deleted Lookup' : 'Delete Lookup'}
          placement="right"
        >
          <IconButton
            onClick={(): void => {
              handleLookUpDelete(!lookUp.isDeleted)
            }}
            size="small"
            color="error"
          >
            {lookUp.isDeleted ? (
              <RestoreFromTrashOutlinedIcon />
            ) : (
              <DeleteOutlineIcon />
            )}
          </IconButton>
        </Tooltip>
      </HighligtedTabelCell>
    </TableRow>
  ) : null
}

export default MappingLookup
