import { AxiosResponse } from 'axios'
import { HaulerSetting, Haulers } from 'types/Hauler'

import { axiosInstance } from '.'
import AppService from './appService'

const values = {}

const getters = {
  haulersList(): Promise<AxiosResponse<Haulers, any>> {
    return axiosInstance.get('/HaulerSettings')
  },
  haulerSettingByvendor(params: {
    vendorName: string
  }): Promise<AxiosResponse<HaulerSetting, any>> {
    return axiosInstance.get(`/HaulerSettingsGet/${params.vendorName}`)
  },
}

const saves = {
  addHaulerSetting(
    haulerSetting: HaulerSetting
  ): Promise<AxiosResponse<HaulerSetting | string, any>> {
    return axiosInstance.post('/HaulerSettings', haulerSetting)
  },
  updateHaulerSetting(
    haulerSetting: HaulerSetting
  ): Promise<AxiosResponse<HaulerSetting | string, any>> {
    return axiosInstance.put('/HaulerSetting', haulerSetting)
  },
}

const deletes = {
  hauler(params: { vendorName: string }): Promise<AxiosResponse<any, any>> {
    return axiosInstance.delete(`/HaulerSettings/${params.vendorName}`)
  },
}

export default class HaulerService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
